import { useMediaQuery } from "react-responsive";
import { InputNumber } from "primereact/inputnumber";
import { Panel } from "primereact/panel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Fragment, useEffect, useState } from "react";
import "../Investment.scss";
import {
  PURCHASE_PRICE_INITIAL_STATE,
  ASSUMPTION_CONSTANTS,
} from "../investment_constants";
import { getCalculatedPercentage } from "app/utils/utility.service";
import { PMT } from "app/utils/utility.service";
import { Button } from "primereact/button";
import ProfitLoss from "./ProfitLoss";
import Cashflow from "./Cashflow";
import { Link } from "react-router-dom";
import { insertLogs } from "../../homepurchase.service";
import AddPropertyInformation from "../../RightContent/Tab1/OwnHouse/PropertyInformation/AddPropertyInformation";

const InvestmentAnalysis = ({
  handleInvestmentInputChange,
  assumptionsInput,
  purchasePriceInfo,
  handleInvestmentAnalysisSave,
  leftContentValues
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [propertyInformationModal, setPropertyInformationModal] =
    useState(false);
  const renderFooter = () => {
    return (
      <div>
        <div className="grid">
          <div className="col-6">
            <Button
              label="Save"
              className="p-button-raised p-button-warning p-button-lg"
              onClick={() => handleInvestmentAnalysisSave()}
            />
          </div>
        </div>
      </div>
    );
  };
  const handlePropertyInformationModal = async () => {
    if(!purchasePriceInfo?.purchasePrice) {
      window.alert("Purchase Price cannot be empty");
      return;
    }
    if (!propertyInformationModal) {
        await handleInvestmentAnalysisSave();
      const payload = {
        description: "Add Property Information",
      };
      insertLogs(payload);
    }
    setPropertyInformationModal(!propertyInformationModal);
  };
  const renderPropertyInformationModal = () => (
    <AddPropertyInformation
      purchasePriceInfo={purchasePriceInfo}
      propertyInformationModal={propertyInformationModal}
      handlePropertyInformationModal={handlePropertyInformationModal}
      assumptionsInput={assumptionsInput}
    />
  );
  const purchasePriceAccordionHeader = () => {
    return (
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <h4>Investment</h4>
        <label className="mb-2">Purchase Price</label>
        <div className="p-inputgroup w-90">
          <InputNumber
            className="vw-inputnumber-with-border"
            autoComplete="off"
            required={true}
            prefix="$ "
            name="purchasePrice"
            disabled={false}
            min={0}
            onValueChange={handleInvestmentInputChange}
            value={purchasePriceInfo?.purchasePrice}
          />
        </div>
      </div>
    );
  };

  const profitLossAccordionHeader = () => {
    return (
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <hr className="separatorcls"></hr>
        <h4>Profit and Loss</h4>
        <label className="mb-2">Profit / Loss</label>
        <div className="p-inputgroup w-90">
          <InputNumber
            className="vw-inputnumber-with-border"
            autoComplete="off"
            required={true}
            prefix="$ "
            name="profitLoss"
            // onValueChange={handleInvestmentInputChange}
            value={
              purchasePriceInfo?.expenses_profitloss
                ? Math.round(purchasePriceInfo?.expenses_profitloss)
                : 0
            }
          />
        </div>
      </div>
    );
  };

  const cashflowAccordionHeader = () => {
    return (
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <hr className="separatorcls"></hr>
        <h4>Cash Flow</h4>
        <label className="mb-2">Inflow / Outflow</label>
        <div className="p-inputgroup w-90">
          <InputNumber
            className="vw-inputnumber-with-border"
            autoComplete="off"
            required={true}
            prefix="$ "
            name="profitLoss"
            // onValueChange={handleInvestmentInputChange}
            value={purchasePriceInfo?.outflow_positive}
          />
        </div>
      </div>
    );
  };

  const renderInputs = () => {
    return (
      <Fragment>
        <div className="grid">
          <div className="col-6">
            <div className="">
              <label className="mb-2">Principal</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="mortgage"
                  disabled={true}
                  min={0}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.mortgage}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="">
              <label className="mb-2">Monthly Rent</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="monthlyRent"
                  disabled={false}
                  min={0}
                  onValueChange={handleInvestmentInputChange}
                  value={purchasePriceInfo?.monthlyRent}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <label className="mb-2">Down Payment</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="downPayment"
                  disabled={true}
                  min={0}
                  onValueChange={handleInvestmentInputChange}
                  value={purchasePriceInfo?.downPayment}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <label className="mb-2">Land</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="land"
                  disabled={true}
                  // min={0}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.land}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="">
              <label className="mb-2">Improvement</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="improvement"
                  disabled={true}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.improvement}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="">
              <label className="mb-2">Interest Rate</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  suffix=" %"
                  name="interestRate"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  disabled={true}
                  min={0}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.interestRate}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="">
              <label className="mb-2">Mortgage</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="monthlyMortgage"
                  disabled={true}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.monthlyMortgage}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="">
              <label className="mb-2">Interest</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="interestAmount"
                  disabled={true}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.interestAmount}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="">
              <label className="mb-2">Property Taxes</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="propertyTaxes"
                  disabled={true}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.propertyTaxes}
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="">
              <label className="mb-2">Maintenance</label>
              <div className="p-inputgroup">
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  required={true}
                  prefix="$ "
                  name="maintenance"
                  disabled={true}
                  // onValueChange={(e) => {
                  //     dispatch(
                  //         changeLeftCalculations({
                  //             name: "purchasePrice",
                  //             value: e.value,
                  //         })
                  //     );
                  //     handleLeftCalculations(
                  //         "purchasePrice",
                  //         e.value
                  //     );
                  // }}
                  value={purchasePriceInfo?.maintenance}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className="InvestmentAnalysis">
      <Accordion
        className=""
        expandIcon={<i className="pi pi-plus"></i>}
        collapseIcon={<i className="pi pi-minus"></i>}
      >
        <AccordionTab header={purchasePriceAccordionHeader()}>
          {renderInputs()}
          {renderFooter()}
        </AccordionTab>
        <AccordionTab header={profitLossAccordionHeader()}>
          <ProfitLoss
            purchasePriceInfo={purchasePriceInfo}
            handleInvestmentInputChange={handleInvestmentInputChange}
          />
          {renderFooter()}
        </AccordionTab>
        <AccordionTab header={cashflowAccordionHeader()}>
          <Cashflow
            purchasePriceInfo={purchasePriceInfo}
            handleInvestmentInputChange={handleInvestmentInputChange}
          />

          {renderFooter()}
        </AccordionTab>
      </Accordion>
      <div className="grid">
        <div className="col-7"></div>
        <div className="col-4 text-align-center">
          <Button
            label="Add"
            className={`p-button-raised p-button-warning ${
              isDesktopOrLaptop ? "p-button-lg" : "margin-top-button save-width"
            }`}
            onClick={handlePropertyInformationModal}
          />
        </div>
      </div>
      {propertyInformationModal && renderPropertyInformationModal()}
      <Panel className="mb-2 OwnHouse">
        <div
        // className={`${
        //     isDesktopOrLaptop ? "align-text" : "align-text-left"
        // }`}
        ></div>
      </Panel>
      {!isDesktopOrLaptop && (
        <div className="text-align-center">
          <Link to="/home-purchase/step3/tab1">Home</Link>
        </div>
      )}
    </div>
  );
};

export default InvestmentAnalysis;
