import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { Fragment, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

import { useSearchParams } from "react-router-dom";
import { getSessionUserInformation } from "app/utils/utility.service";
import { Toast } from "primereact/toast";
import { Loading } from "app/components";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import {
  addReminderDetails,
  deleteReminderDetails,
  editReminderDetails,
} from "../../../homepurchase.service";
import { Calendar } from "primereact/calendar";
import { formatDate } from "app/utils/utility.service";
import { formatNumber } from "app/utils/utility.service";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { findDaysInMonth } from "app/utils/utility.service";

const HomeWarranty = ({
  HomeWarrantyModal,
  handleEditHomeWarrantyModal,
  HomeWarrantyDetails,
  fetchHomeWarrantyDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [searchParams] = useSearchParams();
  const sessionValues = getSessionUserInformation();
  const clientId = searchParams.get("clientid");
  const propertyId = searchParams.get("propertyId");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const userId = clientId && clientId !== "null" ? clientId : sessionValues.id;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const prefix = "$ ";

  const options = [
    { name: "Monthly" },
    { name: "Quarterly" },
    { name: "Half Yearly" },
    { name: "Annual" },
  ];
  const autopayOptions = [{ name: "Yes" }, { name: "No" }];
  const initialState = {
    collection_frequency: "Monthly",
    autopay: "No",
    amount: "",
    date: null,
    userId: userId,
    reminder_type: "Home Warranty",
    property_id: propertyId,
    typeValue: "",
  };
  // List of months
  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  const [selectedMonth, setSelectedMonth] = useState(months[0].value);
  const [selectedDay, setSelectedDay] = useState(null);
  const [days, setDays] = useState([]);
  useEffect(() => {
    const daysArray = findDaysInMonth(selectedMonth);
    setSelectedDay(daysArray[0]); // Default to the first day
    setDays(daysArray);
    updateDate(selectedMonth, selectedDay + 1);
  }, []);
  // Handle month selection and update days
  const handleMonthChange = (e) => {
    setSelectedMonth(e.value);
    updateDays(e.value);
  };
  const handleDayChange = (e) => {
    setSelectedDay(e.value);
    if (selectedMonth) updateDate(selectedMonth, e.value);
  };
  // Update days based on the selected month
  const updateDays = (month) => {
    const daysArray = findDaysInMonth(month);
    setDays(daysArray);
    setSelectedDay(daysArray[0]);
    updateDate(month, daysArray[0]);
  };
  // Update the final date
  const updateDate = (month, day) => {
    const year = new Date().getFullYear(); // Default to current year
    const newDate = new Date(year, month - 1, day); // Create the date object
    setFormData({
      ...formData,
      date: formatDate(newDate),
    });
  };
  const [formData, setFormData] = useState(initialState);
  const [message, setMessage] = useState(false);
  const toast = useRef();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  // State to control the number of records displayed
  const [visibleRecords, setVisibleRecords] = useState(3); // Initially display 3 records

  // Function to show more records
  // Function to show more records
  const showMoreRecords = () => {
    setVisibleRecords((prev) => Math.min(prev + 3, HomeWarrantyDetails.length)); // Load 2 more records
  };
  const showLessRecords = () => {
    setVisibleRecords((prev) => Math.max(prev - 3, 3)); // Decrement rows, but not below 5
  };
  const onHide = () => {
    handleEditHomeWarrantyModal(false);
    setVisibleRecords(3);
    setFormSubmitted(false);
    setMessage(false);
    setFormData(initialState);
    setEditClicked(false);
  };

  // Body template for the column to display formatted numbers
  const priceTemplate = (rowData) => {
    return <span>{"$ " + formatNumber(rowData.amount)}</span>;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditClick = (e) => {
    setEditClicked(true);
    const matchingOption = options.find(
      (option) =>
        option.name.toLowerCase() === e.collection_frequency?.toLowerCase()
    );
    const matchingOptionAutopay = autopayOptions.find(
      (option) => option.name.toLowerCase() === e.autopay?.toLowerCase()
    );
    setSelectedID(e.id);
    setSelectedMonth(new Date(e.date).getMonth() + 1);
    setSelectedDay(new Date(e.date).getDate());
    setDays(findDaysInMonth(new Date(e.date).getMonth() + 1));
    const year = new Date().getFullYear(); // Default to current year
    const newDate = new Date(year, new Date(e.date).getMonth() , new Date(e.date).getDate()); // Create the date object
    setFormData({
      ...formData,
      amount: e.amount,
      autopay: matchingOptionAutopay.name,
      date: formatDate(newDate),
      collection_frequency: matchingOption.name,
      typeValue: e.typeValue,
    });
  };
  const validateInput = () => {
    if (!formData.date || formData.amount <= 0 || !formData.typeValue) {
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!validateInput()) {
      //   showError("Please fill in all required fields correctly.");

      return false;
    }

    const hoa = HomeWarrantyDetails.filter((hoa) => hoa.date === formData.date);

    if (editClicked) {
      const details = await editReminderDetails(formData, selectedID);
      if (details) {
        showSuccess("Record updated successfully");
        setIsLoading(false);
        if (hoa.length > 1) setMessage(true);
        setSelectedDate(formData.date);
        setTimeout(() => {
          setFormData(initialState);
          setSelectedDay(1);
          setSelectedMonth(1);
          setFormSubmitted(false);
          setEditClicked(false);
          fetchHomeWarrantyDetails();
        }, 1000);
      } else {
        showError("Error");
      }
    } else {
      const details = await addReminderDetails(formData);
      if (details) {
        showSuccess("Record added successfully");
        setIsLoading(false);
        if (hoa.length > 1) setMessage(true);
        setSelectedDate(formData.date);
        setTimeout(() => {
          setFormData(initialState);
          setSelectedDay(1);
          setSelectedMonth(1);
          setFormSubmitted(false);
          fetchHomeWarrantyDetails();
        }, 1000);
      } else {
        showError("Error");
      }
    }
  };
  const handleDelete = async (id) => {
    const details = await deleteReminderDetails(id);
    if (details) {
      showSuccess("Record deleted successfully");
      // insert settings value
      setIsLoading(false);
      setTimeout(() => {
        fetchHomeWarrantyDetails();
      }, 1000);
    } else {
      showError("Error");
    }
  };
  const actionBodyTemplate = (rowData) => {
    const now = new Date();

    return (
      <div className="grid">
        <div className="col-6">
          <a
            href="javascript:;"
            className=""
            onClick={() => handleEditClick(rowData)}
          >
            <i className={`pi pi-pencil`} />
          </a>
        </div>
        <div className="col-1">
          <a
            href="javascript:;"
            className=""
            onClick={() => handleDelete(rowData.id)}
          >
            <i className={`pi pi-trash`} />
          </a>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Dialog
        header="Home Warranty"
        position="top"
        visible={HomeWarrantyModal}
        onHide={() => onHide()}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "60vw" }}
        className="MonthlyExpensesModal"
        closeOnEscape={false}
        draggable={false}
      >
        {/* Home Warranty */}
        {isLoading && <Loading />}
        <Toast ref={toast} className="ToastMessage" />
        <div className="grid mt-4">
          <div
            className={`${
              isDesktopOrLaptop ? "col-2 align-text-rt" : "col-11"
            }`}
          >
            <label class="mt-3">Home Warranty Collection Frequency</label>
          </div>
          <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
            <Dropdown
              className="vw-dropdown zipcode-dropdown"
              value={formData?.collection_frequency}
              options={options}
              required={true}
              name="collection_frequency"
              onChange={handleInputChange}
              optionLabel="name"
              optionValue="name"
              style={{
                border: "1px solid #ced4da",
                borderRadius: "3px",
                padding: "5px",
              }}
            />
          </div>
          <div
            className={`${
              isDesktopOrLaptop ? "col-3 align-text-rt" : "col-11"
            }`}
          >
            <label class="mt-3">Amount</label>
          </div>
          <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
            <InputNumber
              className="vw-inputnumber-with-border"
              autoComplete="off"
              value={formData?.amount}
              style={{ width: "180px" }}
              required={true}
              name="amount"
              disabled={false}
              prefix="$ "
              onValueChange={handleInputChange}
            />
            {formSubmitted && formData?.amount <= 0 && (
              <small className="p-error">Please enter amount</small>
            )}
          </div>
        </div>
        <div className="grid">
          <div
            className={`${
              isDesktopOrLaptop ? "col-2 align-text-rt" : "col-11"
            }`}
          >
            <label class="mt-3">Auto Pay</label>
          </div>
          <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
            <Dropdown
              className="vw-dropdown zipcode-dropdown"
              options={autopayOptions}
              required={true}
              name="autopay"
              value={formData?.autopay}
              onChange={handleInputChange}
              optionLabel="name"
              optionValue="name"
              style={{
                border: "1px solid #ced4da",
                borderRadius: "3px",
                padding: "5px",
              }}
            />
            <label className="mt-3">
              (Alert e-mail is disabled when Auto-Pay is set to Yes)
            </label>
          </div>
          <div
            className={`${
              isDesktopOrLaptop ? "col-3 align-text-rt" : "col-11"
            }`}
          >
            <label class="mt-3">Date</label>
          </div>

          <div className={`${isDesktopOrLaptop ? "" : "col-11"}`}>
            <div style={{ display: "flex" }}>
              <div className="col-6" style={{ width: "10.3rem" }}>
                <Dropdown
                  className=" vw-dropdown  vw-dropdown-with-border"
                  value={selectedMonth}
                  options={months}
                  onChange={handleMonthChange}
                  placeholder="Month"
                  style={{
                    width: "100px",
                    border: "1px solid #ced4da", // Customize the border color and width
                    borderRadius: "3px", // Optional: round the corners
                    padding: "5px", // Optional: add padding for spacing
                    height: "3.5rem",
                  }}
                />
              </div>
              <div className="col-6" style={{ width: "8.4rem" }}>
                <Dropdown
                  className=" vw-dropdown  vw-dropdown-with-border"
                  value={selectedDay}
                  options={days}
                  onChange={handleDayChange}
                  emptyMessage="Plese select Month"
                  disabled={!selectedMonth} // Disable day dropdown until month is selected
                  style={{
                    width: "10px",
                    border: "1px solid #ced4da", // Customize the border color and width
                    borderRadius: "3px", // Optional: round the corners
                    padding: "5px", // Optional: add padding for spacing
                    height: "3.5rem",
                  }}
                />
              </div>
            </div>
            {formSubmitted && !selectedDay && (
              <small className="p-error">Please enter date</small>
            )}
          </div>
        </div>
        <div className="grid">
          <div
            className={`${
              isDesktopOrLaptop ? "col-2 align-text-rt" : "col-11"
            }`}
          >
            <label class="mt-3">Company Name</label>
          </div>
          <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
            <InputText
              className="vw-inputtext-with-border  insurance-width"
              autoComplete="off"
              value={formData?.typeValue}
              required={true}
              name="typeValue"
              disabled={false}
              onChange={handleInputChange}
            />
            {formSubmitted && !formData?.typeValue && (
              <small className="p-error">Please enter company name</small>
            )}
          </div>
        </div>
        <div className="grid mt-2" style={{ justifyContent: "center" }}>
          <div
            className={`${
              isDesktopOrLaptop ? "col-8" : "col-12 text-align-center"
            }`}
          >
            <Button
              label="Cancel"
              className="p-button-raised p-button-lg-1 mr-4"
              onClick={onHide}
            />
            <Button
              label="Save"
              className="p-button-raised p-button-warning p-button-lg-1"
              onClick={handleSubmit}
            />
          </div>
        </div>
        <div className="ml12 mt-2">
          {message && (
            <small>
              There are two records on {selectedDate} in the history table.
              Please review them.
            </small>
          )}
          {editClicked && <label>Please update Information.</label>}
        </div>
        <div className="ml-6  mb-4 mr-6">
          <span>
            <h4>History</h4>
          </span>

          {/* Display a subset of the data */}
          <DataTable
            value={HomeWarrantyDetails.slice(0, visibleRecords)}
            stripedRows
            emptyMessage="Please create a scheduler to effectively monitor and track payments."
          >
            <Column
              field="collection_frequency"
              header="Home Warranty Collection Frequency"
              style={{ width: "200px" }}
            />
            <Column
              field="typeValue"
              header="Company Name"
              style={{ width: "200px" }}
            />
            <Column
              field="autopay"
              header="Auto Pay"
              style={{ width: "140px" }}
            />
            <Column
              field="amount"
              style={{ width: "120px" }}
              header="Home Warranty Amount"
              body={priceTemplate}
            />
            <Column
              field="date"
              header="Date"
              sortable
              body={(rowData) => moment(rowData.date).format("MM/DD")}
            />

            <Column
              body={actionBodyTemplate}
              style={{ textAlign: "center", width: "100px" }}
            />
          </DataTable>
   
          {/* "Show Less" button */}
          {visibleRecords < HomeWarrantyDetails.length && (
            <Button
              label="Show More Records"
              onClick={showMoreRecords}
              className="p-button-text"
            />
          )}
          {/* "Show More" button */}
          {visibleRecords >= 4 && (
            <Button
              label="Show Less Records"
              onClick={showLessRecords}
              className="p-button-text"
            />
          )}
        </div>
      </Dialog>
    </Fragment>
  );
};

export default HomeWarranty;
