import { useMediaQuery } from "react-responsive";
import LeftContent from "../LeftContent/LeftContent";
import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const reports = [
//  { id: "01", name: "Balance Sheet" },
  { id: "01", name: "Preventive Maintenance" },
  { id: "02", name: "Breakdown Maintenance" },
  { id: "03", name: "Payments Summary" },
];

const ReportList = () => {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const gotoTab = (type) => {
    if (type.indexOf("Payments") > -1) {
      navigate("/home-purchase/report/paymentsummary");
    } else if (type.indexOf("Preventive") > -1) {
      navigate("/home-purchase/report/preventive");
    } else if (type.indexOf("Breakdown") > -1) {
      navigate("/home-purchase/report/breakdown");
    }
  };
   // Custom body template to render hyperlinks
   const linkTemplate = (rowData, options) => {
    return (
      <span
        style={{
          textAlign: "center",
       //   whiteSpace: "pre-line",
          cursor: "pointer",
        }}
        onClick={() => gotoTab(rowData.name)}
      >
        {rowData.name}
      </span>
    );
  };

  return (
    <div className="HomePurchaseStep3Form">
      <div style={{ margin: "2rem" }}>
        <div class="">
        <div className="col-2"></div>
      <div className="grid">
        <div className="bannerStyle col-12">Reports</div>
      </div>
          <div>
            {/* Reports List */}
     
             <DataTable
                      value={reports}
                      className=" propertyTable mt-3"
                      responsiveLayout="scroll"
                     scrollHeight="600px"
                      scrollDirection="both"
                      rows={10}
                      rowHover
                      
                    >
                       <Column field="id" header="ID"  style={{width:"10%"}}/>
                      <Column field="name" header="Name" body={linkTemplate} style={{width:"20%"}}/>
                    </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportList;
