import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";
import LeftContent from "../LeftContent/LeftContent";

const Transactions = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
      });
      return (
        <div className="HomePurchaseStep3Form">
          <div className="content">
            {isDesktopOrLaptop && (
              <div className="left">
                <LeftContent />
              </div>
            )}
            <div class="portfolioRight">
              <Outlet />
            </div>
          </div>
        </div>
      );
};

export default Transactions;
