import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";
import AddComponent from "./AddComponent/AddComponent";

const ComponentMaster= Loadable(lazy(() => import("./ComponentMaster")));

const componentMasterRoutes = [
    {
        path: "/componentmaster",
        element: <ComponentMaster />,
    },
    {
        path: "/componentmaster/addnew",
        element: <AddComponent />,
    },
];

export default componentMasterRoutes;
