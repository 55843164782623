import { Loading } from "app/components";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { formatDate } from "app/utils/utility.service";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatNumber } from "app/utils/utility.service";

const MortgageSchedule = ({
  MortgageModal,
  handleEditMortgagePaymentModal,
  values,
}) => {
  const onHide = () => {
    handleEditMortgagePaymentModal(false);
  };
  const toast = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [extra, setExtra] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [visibleRecords, setVisibleRecords] = useState(3);
  const [visibleRecordsMonth, setVisibleRecordsMonth] = useState(3);
  const [extraPayment, setExtraPayment] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const initialValues = {
    month: "",
    date: "",
    extraPayment: 0,
    principal: "",
    interest: "",
    balance: "",
    year: "",
  };
  const [scheduleType, setScheduleType] = useState("Monthly"); // Toggle state
  const [showMore, setShowMore] = useState(false);
  const [newMonthlyschedule, setNewMonthlyschedule] = useState([initialValues]);
  const [newAnnualschedule, setNewAnnualschedule] = useState([initialValues]);
  const [lastRecordMonth, setLastRecordMonth] = useState([]);
  const [lastRecordYear, setLastRecordYear] = useState([]);
  const [totalMortgagePayment, setTotalMortgagePayment] = useState(0);
  const [totalInterest, setTotaInterest] = useState(0);
  const [term, setTerm] = useState(values?.amortization); // years
  const [instalments, setInstalments] = useState(values?.amortization);
  const [principal, setPrincipal] = useState(values?.mortgage); // Loan amount
  const [rate, setRate] = useState(values?.rateOfInterest); // Annual interest rate
  const [secondrate, setSecondrate] = useState(values?.secondLoanInterestRate);
  const [secondPrincipal, setSecondPrincipal] = useState(
    values?.secondLoanPrincipalAmt
  );

  const currentYear = 1900;

  const years = Array.from({ length: 200 }, (_, i) => ({
    label: `${currentYear + i}`,
    value: currentYear + i,
  }));
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const months = [
    { label: "January", value: "1" },
    { label: "February", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];
  const setPayment = (e) => {

    let updatedScheduleMonthly = [];
    let updatedScheduleAnnual = [];
    //if(scheduleType === 'Monthly') {
    updatedScheduleMonthly = newMonthlyschedule.map((entry) => ({
      ...entry,
      extraPayment: e,
    }));
    // } else {
    updatedScheduleAnnual = newAnnualschedule.map((entry) => ({
      ...entry,
      extraPayment: e,
    }));
    // }
    lastRecordMonth[0].extraPayment = e;

    setNewMonthlyschedule(updatedScheduleMonthly);
    setNewAnnualschedule(updatedScheduleAnnual);
  };

  useEffect(() => {
    setSelectedYear(new Date(values?.createdAt).getFullYear());
    setSelectedMonth(new Date(values?.createdAt).getMonth() + 1);
    calculateAnnualSchedule(scheduleType);
  }, [showMore, handleEditMortgagePaymentModal]);

  const calculateAnnualSchedule = (schedule) => {
    let loanAmount = principal;
    let interestRate = rate;
    let secondInterestRate = secondrate;
    let secondMortgage = secondPrincipal;
    let term1 = term;

    const monthlyRate = interestRate / 100 / 12;
    const monthlyRate2 = secondInterestRate / 100 / 12;
    const months = term1 * 12;
    const annualRate = interestRate / 100;

    // First mortgage monthly payment
    const monthlyPayment1 =
      (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -months));
    setMonthlyPayment(new Intl.NumberFormat("en-US").format(Math.round(monthlyPayment1)));

    const annualPayment = monthlyPayment1 * 12;

    // Second mortgage monthly payment
    let monthlyPayment2 = 0;
    if (secondMortgage > 0)
      monthlyPayment2 =
        (secondMortgage * monthlyRate2) /
        (1 - Math.pow(1 + monthlyRate2, -months));

    let balance1 = loanAmount;
    let balance2 = secondMortgage;
    const newSchedule = [];
    const newScheduleMonthly = [];
    let yearInterest = 0;
    let yearPrincipal = 0;
    let startDate = new Date(values?.createdAt);
    let sumInterest = 0;
    let totalExtraPayments = 0;
    let totalPayments = 0;
    let totalI = 0;
    let totalP = 0;
    for (let i = 1; i <= months; i++) {
      let extraPayment = 0;
      if (schedule === "Monthly")
        extraPayment = Number(getExtraPayment(i, schedule));
      else {
        //Add extra payment only after 12 months
        if ((i - 1) % 12 === 0)
          extraPayment = Number(getExtraPayment(Math.ceil(i / 12), schedule));
        else extraPayment = 0;
      }

      // Monthly calculations
      const interest1 = balance1 * monthlyRate;
      const principal1 = Math.min(
        monthlyPayment1 - interest1 + extraPayment,
        balance1
      );
      const principalY = Math.min(
        annualPayment - balance1 * annualRate + extraPayment,
        balance1
      );
      //setTotalMortgagePayment(monthlyPayment1*months+monthlyPayment2*months);
      totalExtraPayments += extraPayment;
      const interest2 = balance2 * monthlyRate2;
      const principal2 = Math.min(monthlyPayment2 - interest2, balance2);

      balance1 -= principal1;
      balance2 -= principal2;

      const totalInterest = interest1 + interest2;
      const totalPrincipal = principal1 + principal2;
      const totalBalance = balance1 + balance2;
      //  totalPayments += monthlyPayment1 + extraPayment;
      totalPayments = monthlyPayment1 * months + extraPayment;

      let year = 0;
      let month = 0;
      if (!selectedYear) {
        year = new Date(values?.createdAt).getFullYear();
      } else {
        year = selectedYear;
      }
      if (!selectedMonth) {
        month = new Date(values?.createdAt).getMonth() + 1;
      } else {
        month = selectedMonth;
      }
      const monthDate = new Date(year, Number(month) + i - 2);

      // Store monthly or annual data
      if (schedule === "Monthly") {
        newScheduleMonthly.push({
          month: i,
          date: monthDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          }),
          extraPayment: extraPayment.toFixed(2),
          interest: totalInterest.toFixed(2),
          principal: totalPrincipal.toFixed(2),
          balance: totalBalance.toFixed(2),
        });
      }

      totalI += totalInterest;
      totalP += totalPrincipal;

      // Aggregate interest and principal for annual schedule
      yearInterest += totalInterest;
      yearPrincipal += totalPrincipal;

      if (i % 12 === 0 || i === months) {
        // Add annual data every 12 months or at the end of the term
        // if (schedule === "Annual") {
        newSchedule.push({
          year: Math.ceil(i / 12),
          interest: yearInterest.toFixed(2),
          principal: yearPrincipal.toFixed(2),
          balance: totalBalance.toFixed(2),
          extraPayment: Number(getExtraPayment(Math.ceil(i / 12), schedule)),
        });
        //  }
        sumInterest += yearInterest;

        // Reset annual aggregation
        yearInterest = 0;
        yearPrincipal = 0;
      }

      if (totalBalance <= 0 || i == months) {
        if (yearInterest > 0 || yearPrincipal > 0) {
          newSchedule.push({
            year: Math.ceil(i / 12),
            interest: formatNumber(yearInterest.toFixed(2)),
            principal: formatNumber(yearPrincipal.toFixed(2)),
            balance: formatNumber(totalBalance.toFixed(2)),
            extraPayment: Number(getExtraPayment(Math.ceil(i / 12), schedule)),
          });
        }
        sumInterest += yearInterest;
        setTotaInterest(sumInterest);
        let tot = totalP + totalI - extraPayment * (i - 1);
        setTotalMortgagePayment(tot);
        setInstalments(i);
        break; // Stop early if balances are paid off
      }
    }
    if (schedule === "Monthly") {
      setNewMonthlyschedule([...newScheduleMonthly]);
      setLastRecordMonth([newScheduleMonthly[newScheduleMonthly.length - 1]]);
    }

    setNewAnnualschedule([...newSchedule]);
    setLastRecordYear([newSchedule[newSchedule.length - 1]]);
  };
  // Update schedule when extra payment is changed
  const handleExtraPaymentChange = (e, rowIndex, rowData) => {
    const newValue = e.value;
    if (newValue !== Number(rowData.extraPayment)) {
      const updatedSchedule = [...newMonthlyschedule];
      updatedSchedule[rowIndex].extraPayment = e.value;
      setNewMonthlyschedule(updatedSchedule);
    }
  };
  const handleExtraPaymentChangeAnnual = (e, rowIndex, rowData) => {
    const newValue = e.value;
    if (newValue !== Number(rowData.extraPayment)) {
      const updatedSchedule = [...newAnnualschedule];
      updatedSchedule[rowIndex].extraPayment = e.value;
      setNewAnnualschedule(updatedSchedule);
    }
  };
  const mainDataMonth = showMore
    ? newMonthlyschedule.slice(0, newMonthlyschedule.length)
    : newMonthlyschedule.slice(0, 3);
  const mainDataAnnual = showMore
    ? newAnnualschedule.slice(0, newAnnualschedule.length)
    : newAnnualschedule.slice(0, 3);
  // Helper function to get the extra payment for each month from the schedule
  const getExtraPayment = (monthIndex, schedule) => {
    let extraPaymentRow;

    if (schedule === "Monthly") {
      extraPaymentRow = newMonthlyschedule[monthIndex - 1]; // monthIndex is 1-based
    } else {
      extraPaymentRow = newAnnualschedule[monthIndex - 1];
    }

    return extraPaymentRow ? extraPaymentRow.extraPayment : 0;
  };
  // Render the editable extra payment field
  const extraPaymentTemplate = (rowData, { rowIndex }) => {
    return (
      <InputNumber
        className="custom-input-number vw-inputnumber-with-border"
        value={rowData.extraPayment}
        onValueChange={(e) => handleExtraPaymentChange(e, rowIndex, rowData)}
      />
    );
  };
  const extraPaymentTemplateAnnual = (rowData, { rowIndex }) => {
    return (
      <InputNumber
        className="custom-input-number vw-inputnumber-with-border"
        value={rowData.extraPayment}
        onValueChange={(e) =>
          handleExtraPaymentChangeAnnual(e, rowIndex, rowData)
        }
      />
    );
  };
  return (
    <Dialog
      header={"Mortgage"}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "70vw" }}
      className="AnnualExpenseModal"
      visible={MortgageModal}
      position="top"
      closeOnEscape={false}
      draggable={false}
    >
      {isLoading && <Loading />}
      <Toast ref={toast} className="ToastMessage" />

      <div className="grid custom-margin">
        <div className="col-4 align-text mt-4 custom-margin">
          <div className="text-align-center">
            <span style={{ color: "#6c757d", fontSize: "1.2rem" }}>
              <b>First Mortgage</b>
            </span>
          </div>
          <div className="grid mt-3">
            <div className="col-12 md:col-12 lg:col-4 mt-2">
              <label> Loan Amount</label>
            </div>
            <div className="col-12 md:col-12 lg:col-8">
              <div className="">
                <div className="p-inputgroup">
                  <InputNumber
                    className="vw-inputnumber-with-border custom-input-number"
                    autoComplete="off"
                    required={true}
                    name="mortgage"
                    prefix="$ "
                    value={values?.mortgage}
                    onValueChange={(e) => setPrincipal(e.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid mt-1">
            <div className="col-12 md:col-12 lg:col-4 mt-2">
              <label>Loan Term </label>
            </div>
            <div className="col-12 md:col-12 lg:col-8">
              <div className="">
                <div className="p-inputgroup">
                  <InputNumber
                    className="vw-inputnumber-with-border custom-input-number"
                    autoComplete="off"
                    required={true}
                    name="term"
                    value={values?.amortization}
                    suffix={" years"}
                    onValueChange={(e) => setTerm(e.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid mt-1">
            <div className="col-12 md:col-12 lg:col-4 mt-2">
              <label>Interest Rate</label>
            </div>
            <div className="col-12 md:col-12 lg:col-8">
              <div className="">
                <div className="p-inputgroup">
                  <InputNumber
                    className="vw-inputnumber-with-border custom-input-number"
                    autoComplete="off"
                    required={true}
                    name="rateOfInterest"
                    value={values?.rateOfInterest}
                    onValueChange={(e) => setRate(e.value)}
                    suffix={" %"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid mt-1">
            <div className="col-12 md:col-12 lg:col-4 mt-2">
              <label>Start Date</label>
            </div>
            <div className="col-12 md:col-12 lg:col-8">
              <div className="field">
                <div className="grid">
                  <div className="col-6" style={{ width: "8.3rem" }}>
                    <Dropdown
                      className=" vw-dropdown  vw-dropdown-with-border"
                      id="year"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.value)}
                      options={years}
                      placeholder="Select a Year"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                        height: "3.5rem",
                      }}
                    />
                    &nbsp;
                  </div>
                  <div className="col-6" style={{ width: "10.3rem" }}>
                    <Dropdown
                      className=" vw-dropdown  vw-dropdown-with-border custom-dropdown"
                      id="month"
                      value={selectedMonth + ""}
                      options={months}
                      onChange={(e) => setSelectedMonth(e.value)}
                      placeholder="Select a Month"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                        height: "3.5rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid" style={{ width: "53rem" }}>
            {/* Flex Container for All Items */}
            <div
              className="flex align-items-center text-start"
              style={{ gap: "1rem" }}
            >
              {/* Main Checkbox */}
              <div className="flex align-items-center">
                <Checkbox
                  inputId="makeExtraPayments"
                  className="custom-red-checkbox"
                  onChange={(e) => {
                    setChecked(e.checked);
                    if (!e.checked) setExtra(false); // Reset "Uniform" checkbox when unchecked
                    setPayment(0);
                  }}
                  checked={checked}
                />
                <label htmlFor="makeExtraPayments" className="ml-2">
                  Optional – Make extra payments
                </label>
              </div>

              {/* Second Checkbox and Input Field */}
              {checked && (
                <>
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="uniformExtraPayments"
                      className="custom-red-checkbox"
                      onChange={(e) => {
                        setExtra(e.checked);
                        if (!e.checked) setPayment(0); // Reset input value
                      }}
                      checked={extra}
                    />
                    <label htmlFor="uniformExtraPayments" className="ml-2">
                      Uniform extra payments
                    </label>
                  </div>

                  {/* Input Field */}
                  {extra && (
                    <div className="flex align-items-center">
                      <InputNumber
                        className="custom-input-number vw-inputnumber-with-border "
                        autoComplete="off"
                        value={extraPayment}
                        onValueChange={(e) => setPayment(e.value)}
                        prefix={"$ "}
                        placeholder="0"
                        
                        maxFractionDigits={2}
                        style={{ width: "15rem" }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-4 align-text mt-4 custom-margin">
          <div className="text-align-center">
            <span style={{ color: "#6c757d", fontSize: "1.2rem" }}>
              <b>Second Mortgage</b>
            </span>
          </div>
          <div className=" mt-3">
            <div className="grid mt-2">
              <div className="col-12 md:col-12 lg:col-4 mt-2">
                <label> Loan Amount </label>
              </div>
              <div className="col-12 md:col-12 lg:col-7">
                <div className="">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border custom-input-number"
                      autoComplete="off"
                      required={true}
                      name="secondLoanPrincipalAmt"
                      prefix="$ "
                      value={values?.secondLoanPrincipalAmt}
                      onValueChange={(e) => setSecondPrincipal(e.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid mt-1">
              <div className="col-12 md:col-12 lg:col-4 mt-2">
                <label> Interest Rate </label>
              </div>
              <div className="col-12 md:col-12 lg:col-7">
                <div className="">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border custom-input-number"
                      autoComplete="off"
                      required={true}
                      name="secondLoanInterestRate"
                      suffix=" %"
                      value={values?.secondLoanInterestRate}
                      onValueChange={(e) => setSecondrate(e.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="col-3 custom-background mt-5 custom-margin">
          <div className="grid ">
            <div className="col-12 md:col-12 lg:col-6 mt-3 align-text">
              <label>Monthly Pay - </label>
            </div>
            <div className="col-12 md:col-12 lg:col-6 mt-3">
              <div>
                <div>
               
                  <label>{"$ " + monthlyPayment}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="grid ">
            <div className="col-12 md:col-12 lg:col-6 mt-3 align-text">
              <label>House Price - </label>
            </div>
            <div className="col-12 md:col-12 lg:col-6 mt-3">
              <div>
                <div>
                  {/* <InputNumber
                    className="vw-inputnumber-with-border"
                    autoComplete="off"
                    required={true}
                    name="purchasePrice"
                    prefix="$ "
                    disabled={true}
                    value={values?.purchasePrice}
                  />*/}
                  <label>{"$ " + formatNumber(values?.purchasePrice)}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-12 lg:col-6 mt-3 align-text">
              <label>Loan Amount - </label>
            </div>
            <div className="col-12 md:col-12 lg:col-6 mt-3">
              <div>
                <div>
                  <label>
                    {"$ " +
                      formatNumber(values?.purchasePrice - values?.downPayment)}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="grid ">
            <div className="col-12 md:col-12 lg:col-6 mt-3 align-text">
              <label>Down Payment - </label>
            </div>
            <div className="col-12 md:col-12 lg:col-6 mt-3">
              <div>
                <div>
                  <label>{"$ " + formatNumber(values?.downPayment)}</label>
                </div>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-12 lg:col-6 mt-3 align-text">
              <label>Total of {instalments} Mortgage Payments - </label>
            </div>
            <div className="col-12 md:col-12 lg:col-6 mt-3">
              <div>
                <div>
                  <label>
                    {"$ " + formatNumber(totalMortgagePayment.toFixed(2))}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-12 md:col-12 lg:col-6 mt-3 align-text">
              <label>Total Interest - </label>
            </div>
            <div className="col-12 md:col-12 lg:col-6 mt-3">
              <div>
                <div>
                  <label>{"$ " + formatNumber(totalInterest.toFixed(2))}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid mt-2">
        <div className="col-2">
          <label className="bold ml-2"> Amortization Schedule</label>
          &nbsp;
        </div>
        <div class="thick-dash"></div>
        <div className="grid align-items-center tab-container col-4">
          <Button
            label="Annual"
            className={`p-button-text activeButton ${
              scheduleType === "Annual" ? "bold" : ""
            }`}
            onClick={() => {
              setScheduleType("Annual");
              calculateAnnualSchedule("Annual");
            }}
          />
          <label>|</label>
          <Button
            label="Monthly"
            className={`p-button-text activeButton ${
              scheduleType === "Monthly" ? "bold" : ""
            }`}
            onClick={() => {
              setScheduleType("Monthly");
              calculateAnnualSchedule("Monthly");
            }}
          />
        </div>
      </div>
      <div className="grid mt-2">
        <div className="col-2">
          <Button
            type="button"
            label="Calculate"
            className="p-button-raised p-button-warning p-button-lg-1"
            onClick={() => calculateAnnualSchedule(scheduleType)}
          />
        </div>
      </div>
      <div className="mt-4">
        {scheduleType === "Monthly" ? (
          <DataTable
            value={mainDataMonth}
            stripedRows
            style={{ width: "100%" }}
          >
            <Column field="month" header="Month" style={{ width: "10%" }} />
            <Column field="date" header="Date" style={{ width: "15%" }} />
            <Column
              field="interest"
              header="Interest"
              style={{ width: "15%" }}
              body={(rowData) => {
                const interest = formatNumber(rowData.interest);
                return interest ? "$ " + interest : ""; // Show $ only if interest exists
              }}
            />
            <Column
              field="principal"
              header="Principal"
              style={{ width: "15%" }}
              body={(rowData) => {
                const principal = formatNumber(rowData.principal);
                return principal ? "$ " + principal : ""; // Show $ only if interest exists
              }}
            />
            {checked && !extra && (
              <Column
                style={{ width: "15%" }}
                header="Extra Payment"
                body={extraPaymentTemplate}
              />
            )}
            {checked && extra && (
              <Column
                field="extraPayment"
                style={{ width: "15%" }}
                header="Extra Payment"
                body={(rowData) => {
                  const extra = formatNumber(rowData.extraPayment);
                  return extra ? "$ " + extra : ""; // Show $ only if interest exists
                }}
              />
            )}
            <Column
              field="balance"
              style={{ width: "15%" }}
              header="Ending Balance"
              body={(rowData) => {
                const balance = formatNumber(rowData.balance);
                return balance ? "$ " + balance : ""; // Show $ only if interest exists
              }}
            />
          </DataTable>
        ) : (
          <DataTable value={mainDataAnnual} stripedRows>
            <Column field="year" header="Year" style={{ width: "10%" }} />
            <Column
              field="interest"
              header="Total Interest"
              style={{ width: "15%" }}
              body={(rowData) => {
                const interest = formatNumber(rowData.interest);
                return interest ? "$ " + interest : ""; // Show $ only if interest exists
              }}
            />
            <Column
              field="principal"
              style={{ width: "15%" }}
              header="Total Principal"
              body={(rowData) => {
                const principal = formatNumber(rowData.principal);
                return principal ? "$ " + principal : ""; // Show $ only if interest exists
              }}
            />
            {checked && !extra && (
              <Column
                style={{ width: "15%" }}
                header="Extra Payment"
                body={extraPaymentTemplateAnnual}
              />
            )}
            {checked && extra && (
              <Column
                field="extraPayment"
                style={{ width: "15%" }}
                header="Extra Payment"
                body={(rowData) => {
                  const extra = formatNumber(rowData.extraPayment);
                  return extra ? "$ " + extra : ""; // Show $ only if interest exists
                }}
              />
            )}
            <Column
              field="balance"
              style={{ width: "15%" }}
              header="Ending Balance"
              body={(rowData) => {
                const balance = formatNumber(rowData.balance);
                return balance ? "$ " + balance : ""; // Show $ only if interest exists
              }}
            />
          </DataTable>
        )}
        <div className="flex justify-content-end p-4">
          {scheduleType === "Monthly" && newMonthlyschedule.length > 4 && (
            <Fragment>
              <Button
                label={!showMore ? "Show More" : "Show Less"}
                className="p-button-raised p-button-warning mr-2"
                onClick={handleShowMore}
              ></Button>
            </Fragment>
          )}
          {scheduleType === "Annual" && newAnnualschedule.length > 4 && (
            <Fragment>
              <Button
                label={!showMore ? "Show More" : "Show Less"}
                className="p-button-raised p-button-warning mr-2"
                onClick={handleShowMore}
              ></Button>
            </Fragment>
          )}
        </div>

        {!showMore &&
          scheduleType === "Monthly" &&
          newMonthlyschedule.length > 4 && (
            <DataTable
              className="lastRow"
              value={lastRecordMonth || []}
              style={{ width: "100%" }}
            >
              <Column field="month" header="Month" style={{ width: "10%" }} />
              <Column field="date" header="date" style={{ width: "15%" }} />
              <Column
                header="Interest"
                style={{ width: "15%" }}
                field="interest"
                body={(rowData) => {
                  const interest = rowData.interest;
                  return interest ? "$ " + interest : ""; // Show $ only if interest exists
                }}
              />
              <Column
                field="principal"
                style={{ width: "15%" }}
                header="Principal"
                body={(rowData) => {
                  const principal = rowData.principal;
                  return principal ? "$ " + principal : ""; // Show $ only if interest exists
                }}
              />
              {checked && !extra && (
                <Column
                  header="Extra Payment"
                  style={{ width: "15%" }}
                  body={(rowData) => {
                    const extra = rowData.extraPayment;
                    return extra ? "$ " + extra : ""; // Show $ only if interest exists
                  }}
                />
              )}
              {checked && extra && (
                <Column
                  field="extraPayment"
                  style={{ width: "15%" }}
                  header="Extra Payment"
                  body={(rowData) => {
                    const extra = rowData.extraPayment;
                    return extra ? "$ " + extra : ""; // Show $ only if interest exists
                  }}
                />
              )}
              <Column
                field="balance"
                style={{ width: "15%" }}
                body={(rowData) => {
                  const balance = rowData.balance;
                  return balance ? "$ " + balance : ""; // Show $ only if interest exists
                }}
              />
            </DataTable>
          )}
        {checked}
        {extra}
        {!showMore &&
          scheduleType === "Annual" &&
          newAnnualschedule.length > 4 && (
            <DataTable
              className="lastRow"
              value={lastRecordYear || []}
              stripedRows
            >
              <Column field="year" header="Year" style={{ width: "10%" }} />
              <Column
                field="interest"
                style={{ width: "15%" }}
                header="Total Interest"
                body={(rowData) => {
                  const interest = rowData.interest;
                  return interest ? "$ " + interest : ""; // Show $ only if interest exists
                }}
              />
              <Column
                field="principal"
                style={{ width: "15%" }}
                header="Total Principal"
                body={(rowData) => {
                  const principal = rowData.principal;
                  return principal ? "$ " + principal : ""; // Show $ only if interest exists
                }}
              />

              {checked && !extra && (
                <Column
                  header="Extra Payment"
                  style={{ width: "15%" }}
                  body={(rowData) => {
                    const extra = rowData.extraPayment;
                    return extra ? "$ " + extra : ""; // Show $ only if interest exists
                  }}
                />
              )}
              {checked && extra && (
                <Column
                  field="extraPayment"
                  style={{ width: "15%" }}
                  header="Extra Payment"
                  body={(rowData) => {
                    const extra = rowData.extraPayment;
                    return extra ? "$ " + extra : ""; // Show $ only if interest exists
                  }}
                />
              )}
              <Column
                field="balance"
                style={{ width: "15%" }}
                header="Ending Balance"
                body={(rowData) => {
                  const balance = rowData.balance;
                  return balance ? "$ " + balance : ""; // Show $ only if interest exists
                }}
              />
            </DataTable>
          )}
      </div>
    </Dialog>
  );
};

export default MortgageSchedule;
