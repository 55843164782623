import { ErrorToastConfig } from "app/utils/ToastConstants";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { getSessionUserInformation } from "app/utils/utility.service";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { deletePaymentDetails, getPaymentDetails, insertLogs } from "../../homepurchase.service";
import AddPayments from "./AddPayments";
import { formatNumber } from "app/utils/utility.service";
const initialState = {
    addressOne: "",
    paymentType: "",
    date: "",
    description: "",
    amount: "",
    manual_auto:"manual"
  };
const Payments = () => {

    const [searchParams] = useSearchParams();
    const [values, setValues] = useState(initialState);
    const clientId = searchParams.get("clientid");
    const [title, setTitle] = useState("");
    const [paymentModal, setPaymentModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const sessionValues = getSessionUserInformation();
    const toast = useRef();
    const showSuccess = (message) => {
      toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
      toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };
  
    useEffect(() => {
      setIsLoading(true);
      const userId =
        clientId && clientId !== "null" ? clientId : sessionValues.id;
      setValues({
        ...values,
        userId: userId,
      });
      fetchPaymentDetails();
    }, [paymentModal]);
    const renderPaymentModal = () => (
      <AddPayments
        paymentModal={paymentModal}
        handlePaymentModal={handlePaymentModal}
        title={title}
        values={values}
        setValues={setValues}
      />
    );
    const handlePaymentModal = () => {
      if (!paymentModal) {
        const payload = {
          description: "Payments",
        };
        insertLogs(payload);
      }
  
      setTitle("Add Payments");
  
      setPaymentModal(!paymentModal);
    };
    const fetchPaymentDetails = async () => {
      let list = await getPaymentDetails(sessionValues.id);
      if (list) {
        setIsLoading(false);
        if (list) {
          list.map((li, index) => (li.srNo = index + 1));
        }
        setPayments(list);
      }
    };
    const indexBodyTemplate = (rowData, options) => {
      return options.rowIndex + 1; // Row index starts from 0, so add 1 to start from 1
    };
      const priceTemplate = (rowData) => {
        return <span>{"$ " + formatNumber(rowData.amount)}</span>;
      };
      const typeTemplate = (rowData) => {
        let type = "";
      
        if(rowData.payment_type === "Insurance Taxes") {
          type = "Insurance";
        } else {
          type = rowData.payment_type;
        }

        return type;
      };
    const setEditValues = (rowData) => {
      setTitle("Edit Payments");
      setValues({
        ...values,
        addressOne: rowData.addressOne,
        date: rowData.date,
        amount: rowData.amount,
        payment_type: rowData.payment_type,
        description: rowData.insurance_description?rowData.insurance_description:rowData.description,
        autopay: rowData.autopay,
        collection_frequency: rowData.collection_frequency,
        id: rowData.id,
        typeValue:rowData.typeValue,
        property_id:rowData.property_id
      });
      setPaymentModal(!paymentModal);
    };
      // Custom body template to render hyperlinks
  const linkTemplate = (rowData, options) => {
    return (
      <span
        style={{
          textAlign: "center",
       //   whiteSpace: "pre-line",
          cursor: "pointer",
        }}
        onClick={() => setEditValues(rowData)}
      >
        {rowData.portfolioRecord?.addressOne}
      </span>
    );
  };
    const actionTemplate = (rowData, options) => {
      const menu = React.createRef();
  
      const handleEdit = () => {
        setEditValues(rowData);
      };
  
      const handleDelete = async () => {
        const details = await deletePaymentDetails(rowData.id);
        if (details) {
          showSuccess("Payments deleted successfully");
          // insert settings value
          setIsLoading(false);
          setTimeout(() => {
            fetchPaymentDetails();
          }, 1000);
        } else {
          showError("Error");
        }
      };
  
      const items = [
        { label: "Edit", command: handleEdit },
        { label: "Delete", command: handleDelete },
      ];
  
      return (
        <>
          <Menu model={items} popup ref={menu} />
  
          <i
            style={{ cursor: "pointer" }}
            className="pi pi-ellipsis-v"
            onClick={(event) => {
              menu.current.toggle(event);
            }}
          ></i>
        </>
      );
    };
    return (
        <div style={{ margin: "2rem" }}>
              <Toast ref={toast} className="ToastMessage" />
              <div className="grid mr-5">
                
                <div className="col-12" >
                  <div className="bannerStyle " style={{textAlign:"start"}}>Payments</div>
                </div>
               
              </div>
              <div className="col-12" style={{textAlign:"end"}}>
                  <Button
                    type="button"
                    label="Add Payment"
                    className="p-button-raised p-button-warning mr-4"
                    onClick={handlePaymentModal}
                  />
                </div>
              <DataTable
                value={payments}
                className="propertyTable mt-3"
                responsiveLayout="scroll"
                emptyMessage="Please add your payment details."
                scrollHeight="600px"
                scrollDirection="both"
                rows={10}
                loading={isLoading}
                paginator={payments.length > 10}
                rowHover
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink   CurrentPageReport"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              >
                <Column header="ID" body={indexBodyTemplate} />
                <Column
                  sortable
                  field="addressOne"
                  header="Address 1"
                  body={linkTemplate} 
                  style={{ width: "20%" }}
                />
                <Column field="payment_type" body={typeTemplate} header="Payment Type" />
                <Column field="collection_frequency" header="Collection Frequency" />
                <Column field="manual_auto" body={(rowData) => rowData.manual_auto === 'auto' ? 'Auto':'Manual'} header="Record Creation" style={{ width: "10%" }}/>
                <Column field="amount" header="Amount" body={priceTemplate} style={{ width: "15%" }}/>
                <Column field="date" header="Date" />
                <Column field="Actions" body={actionTemplate} header="Actions" />
              </DataTable>
              {paymentModal && renderPaymentModal()}
            </div>
    );

};

export default Payments;