import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteSinglePropertyDetails,
  getPropertyDetails,
} from "../../homepurchase.service";
import React, { useEffect, useRef, useState } from "react";
import { Loading } from "app/components";
import { getSessionUserInformation } from "app/utils/utility.service";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { formatNumber } from "app/utils/utility.service";
const RealEstate = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [searchParams] = useSearchParams();
  const sessionValues = getSessionUserInformation();
  const clientId = searchParams.get("clientid");
  const propertyId = searchParams.get("propertyId");
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  useEffect(() => {
    setIsLoading(true);

    fetchPropertyDetails();
  }, []);

  const fetchPropertyDetails = async () => {
    const userId =
      clientId && clientId !== "null" ? clientId : sessionValues.id;
    let list = await getPropertyDetails(userId);
    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
      }
      setPropertyDetails(list);
    }
  };
  // Custom function to render the index
  const indexBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1; // Row index starts from 0, so add 1 to start from 1
  };
  const priceTemplate = (rowData) => {
    return <span>{"$ " + formatNumber(rowData.purchasePrice)}</span>;
  };
  const mortgageTemplate = (rowData) => {
    return <span>{"$ " + formatNumber(rowData.mortgagePayments)}</span>;
  };
  const showPropertyInfo = (id, options) => {
    navigate(
      `/home-purchase/portfolio/propertydetails/propertyinfo/?propertyId=${id}&index=${
        options.rowIndex + 1
      }`
    );
  };
  const actionTemplate = (rowData, options) => {
    const menu = React.createRef();

    const handleEdit = () => {
      showPropertyInfo(rowData.id, options);
    };

    const handleDelete = async () => {
      const details = await deleteSinglePropertyDetails(rowData.id);
      if (details) {
        showSuccess("Property deleted successfully");
        // insert settings value
        setIsLoading(false);
        setTimeout(() => {
          fetchPropertyDetails();
        }, 1000);
      } else {
        showError("Error");
      }
    };

    const items = [
      { label: "Edit", command: handleEdit },
      { label: "Delete", command: handleDelete },
    ];

    return (
      <>
        <Menu model={items} popup ref={menu} />

        <i
          style={{ cursor: "pointer" }}
          className="pi pi-ellipsis-v"
          onClick={(event) => {
            menu.current.toggle(event);
          }}
        ></i>
      </>
    );
  };
  // Custom body template to render hyperlinks
  const linkTemplate = (rowData, options) => {
    return (
      <span
        style={{
          textAlign: "center",
       //   whiteSpace: "pre-line",
          cursor: "pointer",
        }}
        onClick={() => showPropertyInfo(rowData.id, options)}
      >
        {rowData.addressOne}
      </span>
    );
  };
  return (
    <div style={{ margin: "2rem" }}>
      {isLoading && <Loading />}
      <div className="col-2"></div>
      <div className="grid">
        <div className="bannerStyle col-12">Real Estate</div>
      </div>
      <div>
        <Toast ref={toast} className="ToastMessage" />

        <DataTable
          value={propertyDetails}
          className="propertyTable mt-3"
          responsiveLayout="scroll"
          emptyMessage="Please add your property information from Home Ownership and Investment"
          scrollHeight="600px"
          scrollDirection="both"
          rows={10}
          loading={isLoading}
          paginator={propertyDetails.length > 10}
          rowHover
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink   CurrentPageReport"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <Column body={indexBodyTemplate} header="ID"/>
          <Column
            sortable
            field="addressOne"
            header="Address 1"
            body={linkTemplate}  style={{ width: "40%" }}
          />
          <Column
            field="purchasePrice"
            header="Purchase Price"
            body={priceTemplate}
          />
          <Column
            field="mortgagePayments"
            header="Mortgage Payment"
            body={mortgageTemplate}
          />
          <Column field="Actions" body={actionTemplate} header="Actions" />
        </DataTable>
      </div>
    </div>
  );
};

export default RealEstate;
