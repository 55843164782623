import { Fragment, useEffect, useRef, useState } from "react";
import {
  addWorkHistoryDetails,
  editWorkHistoryDetails,
  getPropertyDetails,
} from "../../homepurchase.service";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { formatDate } from "app/utils/utility.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { InputNumber } from "primereact/inputnumber";

const initialState = {
  addressOne: "",
  date: "",
  component: "",
  work_description: "",
  type: "",
  permit: "",
  amount: "",
  contractor: "",
  breakdown: "",
  preventive: "",
  property_id: "",
};
const permits = [
  { name: "Yes", code: "Yes" },
  { name: "No", code: "No" },
];
const AddWorkHistory = ({
  workHistoryModal,
  handleAddWorkHistoryModal,
  title,
  values,
  setValues,
}) => {
  const toast = useRef();
  const options = [{ name: "Yes" }, { name: "No" }];
  const [selectedId, setSelectedId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isPermit, setIsPermit] = useState(false);
  const [searchParams] = useSearchParams();
  const sessionValues = getSessionUserInformation();
  const clientId = searchParams.get("clientid");
  const [isLoading, setIsLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [label, setLabel] = useState("");
  const onHide = () => {
    setFormSubmitted(false);
    setValues(initialState);
    handleAddWorkHistoryModal(false);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const navigate = useNavigate();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const validateInput = () => {
    if (
      !values.date ||
      !values.component ||
      !values.work_description ||
      !values.type ||
      !values.permit ||
      !values.amount ||
      !values.contractor ||
      !values.breakdown ||
      !values.preventive
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    let label = title.indexOf("Add") > -1 ? "Add" : "Save";
    setLabel(label);

    const fetchPropertyDetails = async () => {
      const userId =
        clientId && clientId !== "null" ? clientId : sessionValues.id;
      let list = await getPropertyDetails(userId);
      if (list) {
        setIsLoading(false);
        if (list) {
          list.map((li, index) => (li.srNo = index + 1));
        }
        setPropertyDetails(list);
        if (title.indexOf("Add") !== -1) {
          setSelectedId(list[0].id);
          console.log(list[0].id);
          setValues({
            ...values,
            property_id: list[0].id,
            addressOne: list[0].addressOne,
          });
        } else {
          setSelectedId(values?.property_id);
          if(values?.permit == "Yes") {
            setIsPermit(true);
          }
        }
      }
    };

    fetchPropertyDetails();
  }, [workHistoryModal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name == "permit" && value == "Yes") {
        setIsPermit(true);
    } else if (name == "permit" && value == "No") {
        setIsPermit(false);
    }   
  };
  const handleDropdownChange = (e) => {
    setSelectedId(e.value);
    console.log("selected id " + e.value);
    const selectedItem = propertyDetails.find((item) => item.id === e.value);
    setValues({
      ...values,
      property_id: e.value,
      addressOne: selectedItem.addressOne,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validateInput()) {
      return false;
    }

    let details;
    setIsLoading(true);
    let msg = "";
    if (title.indexOf("Add") !== -1) {
      details = await addWorkHistoryDetails(values);
      msg = "Work history added successfully";
    } else {
      details = await editWorkHistoryDetails(values, values?.id);
      msg = "Work history updated successfully";
    }
    if (details) {
      showSuccess(msg);

      setTimeout(() => {
        onHide();
      }, 1000);
    } else {
      showError(details?.message);
    }
    setIsLoading(false);
  };
  const renderFooter = () => {
    return (
      <div>
        <div className="grid">
          <div className="col-3"></div>
          <div
            className={`${
              isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"
            }`}
          >
            <Button
              label="Cancel"
              className="p-button-raised p-button-lg"
              onClick={() => onHide()}
            />
            <Button
              type="submit"
              label={label}
              className={`p-button-raised p-button-warning p-button-lg  ${
                isDesktopOrLaptop ? "" : "ml-4"
              }`}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header={title}
      visible={workHistoryModal}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "35vw" }}
      footer={renderFooter()}
      className="AnnualExpenseModal"
      position="top"
      closeOnEscape={false}
      draggable={false}
    >
      <div className="center-align">
        {isLoading && <Loading />}
        <Toast ref={toast} className="ToastMessage" />
        <div className="AddPropertyForm">
          <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
            <div className="grid">
              {/* Address 1 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Address 1</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <Dropdown
                        className="vw-dropdown zipcode-dropdown"
                        value={selectedId}
                        options={propertyDetails}
                        required={true}
                        name="addressOne"
                        onChange={handleDropdownChange}
                        optionLabel="addressOne" // Display the name in the dropdown
                        optionValue="id" // Use the ID as the value
                        style={{
                          border: "1px solid #ced4da", // Customize the border color and width
                          borderRadius: "3px", // Optional: round the corners
                          padding: "5px", // Optional: add padding for spacing
                        }}
                        // placeholder="Select a Country"
                      />
                    </span>
                  </div>
                </div>
              </div>

              {/* Date */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Date</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <Calendar
                      id="buttondisplay"
                      onChange={(date) =>
                        setValues({
                          ...values,
                          date: new Date(
                            Date.UTC(
                              date.value.getFullYear(),
                              date.value.getMonth(),
                              date.value.getDate()
                            )
                          )
                            .toISOString()
                            .split("T")[0],
                        })
                      }
                      value={new Date(formatDate(values?.date))}
                      showIcon
                      dateFormat="mm/dd/yy"
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.date && (
                      <small className="p-error">Please enter date</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Component */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Component</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="component"
                      value={values?.component}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.component && (
                      <small className="p-error">Please enter component</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Work desc */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Work Description</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="work_description"
                      value={values?.work_description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.work_description && (
                      <small className="p-error">
                        Please enter work description
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Type */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Type</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="type"
                      value={values?.type}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.type && (
                      <small className="p-error">Please enter type</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Permit */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Permit</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <Dropdown
                        className="vw-dropdown zipcode-dropdown"
                        value={values?.permit}
                        options={permits}
                        required={true}
                        name="permit"
                        onChange={handleInputChange}
                        optionLabel="name"
                        optionValue="name"
                        style={{
                          border: "1px solid #ced4da", // Customize the border color and width
                          borderRadius: "3px", // Optional: round the corners
                          padding: "5px", // Optional: add padding for spacing
                        }}
                        // placeholder="Select a Country"
                      />
                    </span>
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.permit && (
                      <small className="p-error">Please enter permit</small>
                    )}
                  </div>
                </div>
              </div>

              {isPermit && (
                <Fragment>
             

              {/* Permit Number */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Permit Number</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <div className="p-inputgroup">
                    <InputText
                        className="vw-inputtext-with-border"
                        autoComplete="off"
                        required={true}
                        name="permit_number"
                        onChange={handleInputChange}
                        value={values?.permit_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
             
              {/* City */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">City</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="city"
                      value={values?.city}
                      onChange={handleInputChange}
                    />
                  </div>

                </div>
              </div>
              </Fragment>
)}
              {/* Amount*/}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Amount</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      prefix="$ "
                      name="amount"
                      onValueChange={handleInputChange}
                      value={values?.amount}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.amount && (
                      <small className="p-error">Please enter amount</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Contractor */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Contractor</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="contractor"
                      value={values?.contractor}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.contractor && (
                      <small className="p-error">Please enter Contractor</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Breakdown */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Breakdown</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="breakdown"
                      value={values?.breakdown}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.breakdown && (
                      <small className="p-error">Please enter Breakdown</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Preventive */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Preventive</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                <span className="p-float-label">
                    <Dropdown
                      className="vw-dropdown zipcode-dropdown"
                      value={values?.preventive}
                      options={options}
                      required={true}
                      name="preventive"
                      onChange={(e) => setValues({...values,preventive: e.value})}
                      optionLabel="name" // Display the name in the dropdown
                      optionValue="name" 
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                      }}
                      // placeholder="Select a Country"
                    />
                  </span>
                  <div className="text-align-left">
                    {formSubmitted && !values?.preventive && (
                      <small className="p-error">Please enter Preventive</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default AddWorkHistory;
