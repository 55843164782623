import { Fragment, useEffect, useRef, useState } from "react";
import {
  addPaymentDetails,
  addWorkHistoryDetails,
  editPaymentDetails,
  editWorkHistoryDetails,
  getPropertyDetails,
  getReminderDetails,
} from "../../homepurchase.service";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { formatDate } from "app/utils/utility.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { InputNumber } from "primereact/inputnumber";

const initialState = {
  addressOne: "",
  payment_type: "",
  date: "",
  description: "",
  amount: "",
  autopay: "",
  manual_auto:"manual"
};

const AddPayments = ({
  paymentModal,
  handlePaymentModal,
  title,
  values,
  setValues,
}) => {
  const paymentTypes = [
    { name: "HOA" , label:"HOA"},
    { name: "Property Taxes", label:"Property Taxes" },
    { name: "Insurance Taxes" , label:"Insurance",},
    { name: "Home Warranty" , label:"Home Warranty"},
  ];
  const options = [
    { name: "Monthly" },
    { name: "Quarterly" },
    { name: "Half Yearly" },
    { name: "Annual" },
  ];
  const autopayOptions = [{ name: "Yes" }, { name: "No" }];
  const toast = useRef();
  const [selectedId, setSelectedId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isPermit, setIsPermit] = useState(false);
  const [searchParams] = useSearchParams();
  const sessionValues = getSessionUserInformation();
  const clientId = searchParams.get("clientid");
  const userId = clientId && clientId !== "null" ? clientId : sessionValues.id;
  const [isLoading, setIsLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [paymentTypeSel, setPaymentTypeSel] = useState("");
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [label, setLabel] = useState("");
  const onHide = () => {
    setFormSubmitted(false);
    setValues(initialState);
    handlePaymentModal(false);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const navigate = useNavigate();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const validateInput = () => {
    if (
      !values.addressOne ||
      !values.payment_type ||
      !values.description ||
      !values.date ||
      !values.amount ||
      !values.typeValue
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    let label = title.indexOf("Add") > -1 ? "Add" : "Save";
    setLabel(label);

    const fetchPropertyDetails = async () => {
      const userId =
        clientId && clientId !== "null" ? clientId : sessionValues.id;
      let list = await getPropertyDetails(userId);
      if (list) {
        setIsLoading(false);
        if (list) {
          list.map((li, index) => (li.srNo = index + 1));
        }
        setPropertyDetails(list);
        if (title.indexOf("Add") !== -1) {
          setSelectedId(list[0].id);
          setValues({
            ...values,
            property_id: list[0].id,
            addressOne: list[0].addressOne,
          });
        } else {
          setSelectedId(values?.property_id);
          if(values?.payment_type.indexOf("Insurance Taxes") > -1)
            setPaymentTypeSel("Insurance");
          else 
            setPaymentTypeSel(values?.payment_type);
          if (values?.permit == "Yes") {
            setIsPermit(true);
          }
          let type = values?.payment_type;
          let list = await getReminderDetails(userId, type, values?.property_id);
          setPaymentDetails(list);
        
        }
      }
    };

    fetchPropertyDetails();
  }, [paymentModal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name == "permit" && value == "Yes") {
      setIsPermit(true);
    } else if (name == "permit" && value == "No") {
      setIsPermit(false);
    }
  };
  const handleDropdownChange = async (e) => {
    if (e.target.name == "addressOne") {
      setSelectedId(e.value);
      const selectedItem = propertyDetails.find((item) => item.id === e.value);
      setValues({
        ...values,
        property_id: selectedItem.id,
        addressOne: selectedItem.addressOne,
      });
    } else if (e.target.name == "type") {
      setPaymentTypeSel(e.value);
      if(e.value.indexOf("Insurance Taxes") > -1)
        setPaymentTypeSel("Insurance");
      else 
        setPaymentTypeSel(values?.payment_type);
      let type = e.value;
      let list = await getReminderDetails(userId, type, selectedId);
      list.reverse();
      setPaymentDetails(list);
      let data = list[0];
      setValues({
        ...values,
        payment_type: e.value,
        amount: data?.amount,
        date: data?.date,
        description: data?.insurance_description
          ? data.insurance_description
          : "",
        autopay: data?.autopay,
        collection_frequency: data?.collection_frequency,
        typeValue: data?.typeValue,
      });
    } else if (e.target.name == "autopay") {
      setValues({
        ...values,
        autopay: e.value,
      });
    } else if (e.target.name == "collection_frequency") {
      setValues({
        ...values,
        collection_frequency: e.value,
      });
    } else if (e.target.name == "typeValue") {
      let obj = paymentDetails.find((item) => item.typeValue === e.value);
      setValues({
        ...values,
        amount: obj?.amount,
        date: obj?.date,
        description: obj?.insurance_description
          ? obj.insurance_description
          : "",
        autopay: obj?.autopay,
        collection_frequency: obj?.collection_frequency,
        typeValue: e.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validateInput()) {
      return false;
    }
    let details;
    setIsLoading(true);
    let msg = "";
    if (title.indexOf("Add") !== -1) {
      details = await addPaymentDetails(values);
      msg = "Payments added successfully";
    } else {
      details = await editPaymentDetails(values, values?.id);
      msg = "Payments updated successfully";
    }
    if (details) {
      showSuccess(msg);

      setTimeout(() => {
        onHide();
      }, 1000);
    } else {
      showError(details?.message);
    }
    setIsLoading(false);
  };
  const renderFooter = () => {
    return (
      <div>
        <div className="grid">
          <div className="col-3"></div>
          <div
            className={`${
              isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"
            }`}
          >
            <Button
              label="Cancel"
              className="p-button-raised p-button-lg"
              onClick={() => onHide()}
            />
            <Button
              type="submit"
              label={label}
              className={`p-button-raised p-button-warning p-button-lg  ${
                isDesktopOrLaptop ? "" : "ml-4"
              }`}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header={title}
      visible={paymentModal}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "35vw" }}
      footer={renderFooter()}
      className="AnnualExpenseModal"
      position="top"
      closeOnEscape={false}
      draggable={false}
    >
      <div className="center-align">
        {isLoading && <Loading />}
        <Toast ref={toast} className="ToastMessage" />
        <div className="AddPropertyForm">
          <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
            <div className="grid">
              {/* Address 1 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Address 1</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <Dropdown
                        className="vw-dropdown zipcode-dropdown"
                        value={selectedId}
                        options={propertyDetails}
                        required={true}
                        name="addressOne"
                        onChange={handleDropdownChange}
                        optionLabel="addressOne" // Display the name in the dropdown
                        optionValue="id" // Use the ID as the value
                        style={{
                          border: "1px solid #ced4da", // Customize the border color and width
                          borderRadius: "3px", // Optional: round the corners
                          padding: "5px", // Optional: add padding for spacing
                        }}
                        // placeholder="Select a Country"
                      />
                    </span>
                  </div>
                </div>
              </div>

              {/* Date */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Payment Type</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <span className="p-float-label">
                    <Dropdown
                      className="vw-dropdown zipcode-dropdown"
                      value={values?.payment_type}
                      options={paymentTypes}
                      required={true}
                      name="type"
                      onChange={handleDropdownChange}
                      optionLabel="label" // Display the name in the dropdown
                      optionValue="name"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                      }}

                      // placeholder="Select a Country"
                    />
                  </span>
                  <div className="text-align-left">
                    {formSubmitted && !values?.payment_type && (
                      <small className="p-error">
                        Please enter payment type
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Name */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">{paymentTypeSel=== "Home Warranty"? paymentTypeSel+" Company ": paymentTypeSel} Name</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <span className="p-float-label">
                    <Dropdown
                       className="vw-dropdown zipcode-dropdown"
                      value={values?.typeValue}
                      options={paymentDetails}
                      required={true}
                      editable={true}
                      name="typeValue"
                      onChange={handleDropdownChange}
                      optionLabel="typeValue" // Display the name in the dropdown
                      optionValue="typeValue"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                      }}

                      // placeholder="Select a Country"
                    />
                  </span>
                  <div className="text-align-left">
                    {formSubmitted && !values?.payment_type && (
                      <small className="p-error">
                        Please enter payment type
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Component */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">
                  {paymentTypeSel} Collection Frequency
                </label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <span className="p-float-label">
                    <Dropdown
                      className="vw-dropdown zipcode-dropdown"
                      value={values?.collection_frequency}
                      options={options}
                      required={true}
                      name="collection_frequency"
                      onChange={handleDropdownChange}
                      optionLabel="name" // Display the name in the dropdown
                      optionValue="name"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                      }}
                      // placeholder="Select a Country"
                    />
                  </span>
                </div>
                <div className="text-align-left">
                  {formSubmitted && !values?.collection_frequency && (
                    <small className="p-error">
                      Please enter collection frequency
                    </small>
                  )}
                </div>
              </div>

              {/* Work desc */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Auto Pay</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <span className="p-float-label">
                    <Dropdown
                      className="vw-dropdown zipcode-dropdown"
                      value={values?.autopay}
                      options={autopayOptions}
                      required={true}
                      name="autopay"
                      onChange={handleDropdownChange}
                      optionLabel="name" // Display the name in the dropdown
                      optionValue="name"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                      }}
                      // placeholder="Select a Country"
                    />
                  </span>
                </div>
                <div className="text-align-left">
                  {formSubmitted && !values?.autopay && (
                    <small className="p-error">Please enter auto pay</small>
                  )}
                </div>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">{paymentTypeSel} Amount</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <div className="p-inputgroup">
                      <InputNumber
                        className="vw-inputnumber-with-border"
                        autoComplete="off"
                        required={true}
                        name="amount"
                        prefix="$ "
                        value={values?.amount}
                        onValueChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.amount && (
                      <small className="p-error">Please enter amount</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Date */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Date</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <Calendar
                      id="buttondisplay"
                      onChange={(date) =>
                        setValues({
                          ...values,
                          date: new Date(
                            Date.UTC(
                              date.value.getFullYear(),
                              date.value.getMonth(),
                              date.value.getDate()
                            )
                          )
                            .toISOString()
                            .split("T")[0],
                        })
                      }
                      value={
                        values?.date ? new Date(formatDate(values?.date)) : ""
                      }
                      showIcon
                      dateFormat="mm/dd/yy"
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.date && (
                      <small className="p-error">Please enter date</small>
                    )}
                  </div>
                </div>
              </div>
              {/* Description */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Description</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="description"
                      value={values?.description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.description && (
                      <small className="p-error">
                        Please enter description
                      </small>
                    )}
                  </div>
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default AddPayments;
