import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";
import Investment from "./HomePurchaseStep3/Investment/Investment";
import Tab2 from "./HomePurchaseStep3/RightContent/Tab2/Tab2";
import Tab3 from "./HomePurchaseStep3/RightContent/Tab3/Tab3";
import Tab4 from "./HomePurchaseStep3/RightContent/Tab4/Tab4";
import Tab1 from "./HomePurchaseStep3/RightContent/Tab1/Tab1";
import InvestmentAnalysis from "./HomePurchaseStep3/Investment/InvestmentAnalysis/InvestmentAnalysis";
import Assumptions from "./HomePurchaseStep3/Investment/Assumptions/Assumptions";
import Ratios from "./HomePurchaseStep3/Investment/Ratios/Ratios";
import RealEstate from "./HomePurchaseStep3/Portfolio/RealEstate/RealEstate";
import Portfolio from "./HomePurchaseStep3/Portfolio/Portfolio";
import PropertyDetails from "./HomePurchaseStep3/Portfolio/RealEstate/PropertyDetails";
import PropertyInformation from "./HomePurchaseStep3/Portfolio/RealEstate/PropertyInformation/PropertyInformation";
import PropertyConfiguration from "./HomePurchaseStep3/Portfolio/RealEstate/PropertyConfiguration/PropertyConfiguration";
import PropertyPayments from "./HomePurchaseStep3/Portfolio/RealEstate/PropertyPayments/PropertyPayments";
import WorkHistory from "./HomePurchaseStep3/Transactions/WorkHistory/WorkHistory";
import Transactions from "./HomePurchaseStep3/Transactions/Transactions";
import Payments from "./HomePurchaseStep3/Transactions/Payments/Payments";
import ReportList from "./HomePurchaseStep3/Reports/ReportList";
import PaymentSummaryReport from "./HomePurchaseStep3/Reports/PaymentSummaryReport";
import Reports from "./HomePurchaseStep3/Reports/Reports";
import PreventiveMaintainenceReport from "./HomePurchaseStep3/Reports/PreventiveMaintainenceReport";
import BreakdownMaintainenceReport from "./HomePurchaseStep3/Reports/BreakdownMaintainenceReport";


const HomePurchaseRentStep1 = Loadable(lazy(() => import("./HomePurchaseStep1")));
const HomePurchaseRentStep2 = Loadable(lazy(() => import("./HomePurchaseStep2")));
const HomePurchaseRentStep3 = Loadable(lazy(() => import("./HomePurchaseStep3/HomePurchaseStep3")));

const homePurchaseRoutes = [
    {
        path: "/home-purchase/step1",
        element: <HomePurchaseRentStep1 />,
    },
    {
        path: "/home-purchase/step2",
        element: <HomePurchaseRentStep2 />,
    }, {
        path: "/home-purchase/step3",
        element: <HomePurchaseRentStep3 />,
        children: [
            {
                path: "tab1",
                 element: <Tab1 />,
                },
            {
            path: "tab2",
             element: <Tab2 />,
            },
            {
                path: "tab3",
                 element: <Tab3 />,
                },
                {
                    path: "tab4",
                     element: <Tab4 />,
                    }
        ]
    },
    {
        path: "/home-purchase/investment",
        element: <Investment />,
        children: [
            {
                path: "analysis",
                element: <InvestmentAnalysis />,
            },
            {
                path: "assumptions",
                element: <Assumptions />,
            },
            {
                path: "ratios",
                element: <Ratios />,
            },
        ]
    },
    {
        path: "/home-purchase/portfolio",
        element: <Portfolio />,
        children: [
            {
                path: "realestate",
                element: <RealEstate />,
            },
            {
                path: "propertydetails",
                element: <PropertyDetails />,
                children: [
                    {
                        path: "propertyinfo",
                        element: <PropertyInformation />,
                    },
                    {
                        path: "propertyconfig",
                        element: <PropertyConfiguration />,
                    },
                    {
                        path: "propertypayments",
                        element: <PropertyPayments />,
                    },
                ]
            },
        ]
    },
    {
        path: "/home-purchase/transactions",
        element: <Transactions />,
        children: [
            {
                path: "workhistory",
                element: <WorkHistory />,
            },
            {
                path: "payments",
                element: <Payments />,
            },
        ]
    },
    {
        path: "/home-purchase/report",
        element: <Reports />,
        children: [
            {
                path: "list",
                element: <ReportList />,
            },
            {
                path: "paymentsummary",
                element: <PaymentSummaryReport />,
            },
            {
                path: "preventive",
                element: <PreventiveMaintainenceReport />,
            },
            {
                path: "breakdown",
                element: <BreakdownMaintainenceReport />,
            },
        ]
        
    },

];

export default homePurchaseRoutes;
