import { getSessionUserInformation } from "app/utils/utility.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteWorkHistoryDetails,
  getWorkHistoryDetails,
  insertLogs,
} from "../../homepurchase.service";
import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { Button } from "primereact/button";
import AddWorkHistory from "./AddWorkHistory";
import { formatNumber } from "app/utils/utility.service";
const initialState = {
  addressOne: "",
  date: "",
  component: "",
  work_description: "",
  type: "",
  permit: "",
  amount: "",
  contractor: "",
  breakdown: "",
  preventive: "",
};
const WorkHistory = () => {

  const [searchParams] = useSearchParams();
  const [values, setValues] = useState(initialState);
  const clientId = searchParams.get("clientid");
  const [title, setTitle] = useState("");
  const [workHistoryModal, setWorkHistoryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [workHistoryDetails, setWorkHistoryDetails] = useState([]);
  const sessionValues = getSessionUserInformation();
  const toast = useRef();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };

  useEffect(() => {
    setIsLoading(true);
    const userId =
      clientId && clientId !== "null" ? clientId : sessionValues.id;
    setValues({
      ...values,
      userId: userId,
    });
    fetchWorkHistoryDetails();
  }, [workHistoryModal]);
  const renderAddWorkHistoryModal = () => (
    <AddWorkHistory
      workHistoryModal={workHistoryModal}
      handleAddWorkHistoryModal={handleAddWorkHistoryModal}
      title={title}
      values={values}
      setValues={setValues}
    />
  );
  const handleAddWorkHistoryModal = () => {
    if (!workHistoryModal) {
      const payload = {
        description: "Work history",
      };
      insertLogs(payload);
    }

    setTitle("Add Permit and Work History");

    setWorkHistoryModal(!workHistoryModal);
  };
  const fetchWorkHistoryDetails = async () => {
    let list = await getWorkHistoryDetails(sessionValues.id);

    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
      }
      setWorkHistoryDetails(list);
    }
  };
  const indexBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1; // Row index starts from 0, so add 1 to start from 1
  };
  const linkTemplate = (rowData, options) => {
    return (
      <span
        style={{
          textAlign: "center",
       //   whiteSpace: "pre-line",
          cursor: "pointer",
        }}
        onClick={() => setEditValues(rowData)}
      >
        {rowData.portfolioRecord.addressOne}
      </span>
    );
  };
  const setEditValues = (rowData) => {
    setTitle("Edit Permit and Work History");
    setValues({
      ...values,
      addressOne: rowData.addressOne,
      date: rowData.date,
      component: rowData.component,
      work_description: rowData.work_description,
      type: rowData.type,
      permit: rowData.permit,
      amount: rowData.amount,
      contractor: rowData.contractor,
      breakdown: rowData.breakdown,
      preventive: rowData.preventive,
      property_id: rowData.property_id,
      permit_number: rowData.permit_number,
      city:rowData.city,
      id: rowData.id,
    });
    setWorkHistoryModal(!workHistoryModal);
  };
  const priceTemplate = (rowData) => {
    return <span>{"$ " + formatNumber(rowData.amount)}</span>;
  };
  const actionTemplate = (rowData, options) => {
    const menu = React.createRef();

    const handleEdit = () => {
      setEditValues(rowData);
    };

    const handleDelete = async () => {
      const details = await deleteWorkHistoryDetails(rowData.id);
      if (details) {
        showSuccess("Work history deleted successfully");
        // insert settings value
        setIsLoading(false);
        setTimeout(() => {
          fetchWorkHistoryDetails();
        }, 1000);
      } else {
        showError("Error");
      }
    };

    const items = [
      { label: "Edit", command: handleEdit },
      { label: "Delete", command: handleDelete },
    ];

    return (
      <>
        <Menu model={items} popup ref={menu} />

        <i
          style={{ cursor: "pointer" }}
          className="pi pi-ellipsis-v"
          onClick={(event) => {
            menu.current.toggle(event);
          }}
        ></i>
      </>
    );
  };
  return (
    <div style={{ margin: "2rem" }}>
      <Toast ref={toast} className="ToastMessage" />
      <div className="grid mr-5">
        
        <div className="col-12" >
          <div className="bannerStyle " style={{textAlign:"start"}}>Permit and Work History</div>
        </div>
       
      </div>
      <div className="col-12" style={{textAlign:"end"}}>
          <Button
            type="button"
            label="Add Permit and Work History"
            className="p-button-raised p-button-warning mr-4"
            onClick={handleAddWorkHistoryModal}
          />
        </div>
      <DataTable
        value={workHistoryDetails}
        className="propertyTable mt-3"
        responsiveLayout="scroll"
        emptyMessage="Please add permit and work history details."
        scrollHeight="600px"
        scrollDirection="both"
        rows={10}
        loading={isLoading}
        paginator={workHistoryDetails.length > 10}
        rowHover
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink   CurrentPageReport"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      >
        <Column header="ID" body={indexBodyTemplate}/>
        <Column
          sortable
          field="addressOne"
          header="Address 1"
          style={{ width: "25%" }}
          body={linkTemplate} 
        />
        <Column field="component" header="Component" />
        <Column field="work_description" header="Work Description" />
        <Column field="amount" body={priceTemplate} header="Amount" />
        <Column field="Actions" body={actionTemplate} header="Actions" />
      </DataTable>
      {workHistoryModal && renderAddWorkHistoryModal()}
    </div>
  );
};

export default WorkHistory;
