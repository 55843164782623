import { Button } from "primereact/button";
import SelectComponent from "./SelectComponent";
import React, { useEffect, useRef, useState } from "react";
import { insertLogs } from "../../../homepurchase.service";
import { getUserComponentList } from "app/views/component-master/component.service";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { Menu } from "primereact/menu";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { Toast } from "primereact/toast";

import { deleteUserComponent } from "app/views/component-master/component.service";
import { useSearchParams } from "react-router-dom";

const PropertyConfiguration = ({
  addressOne
  })  => {
  const [selectComponentModal, setSelectComponentModal] = useState(false); 
  const initialState = {
    component: "",
    component_name: "",
    component_type: "",
    life_expectancy: "",
    maintenance_interval: "",
    recall_information: "",
    recall_date: "",
    date_created:"",
    material_description:"",
    warranty:""
  };

  const [values, setValues] = useState(initialState);
  const [title, setTitle] = useState("");
  const [componentData, setComponentData] = useState([]);

  const [searchParams] = useSearchParams();
 const propertyId = searchParams.get("propertyId");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const renderSelectComponentModal = () => (
    <SelectComponent
    selectComponentModal={selectComponentModal}
    handleselectComponentModal={handleselectComponentModal}
    values={values}
    setValues={setValues}
    title={title}
    />
);
const fetchUserComponents = async () => {
  let list = await getUserComponentList(propertyId);
  if (list) {
   
    if (list) {
      list.map((li, index) => (li.srNo = index + 1));
    }
    setComponentData(list);
  } 
  setIsLoading(false);
};
useEffect(() => {
  setIsLoading(true);

  fetchUserComponents();
}, [selectComponentModal]);
const actionTemplate = (rowData) => {
  const menu = React.createRef();

  const handleEdit = () => {
    setTitle("Edit Component");
    setValues({
      ...values,
      component: rowData.component,
      component_name: rowData.component_name,
      component_type: rowData.component_type,
      life_expectancy: rowData.life_expectancy,
      maintenance_interval: rowData.maintenance_interval,
      recall_information: rowData.recall_information,
      recall_date: rowData.recall_date,
      date_created: rowData.date_created,
      warranty:rowData.warranty,
      material_description:rowData.material_description,
      id: rowData.id,
    });
    setSelectComponentModal(!selectComponentModal);
  };

  const handleDelete = async () => {
    const details = await deleteUserComponent(rowData.id);
    if (details.message) {
      showSuccess("Component deleted successfully");
      // insert settings value
      setIsLoading(false);
      setTimeout(() => {
        fetchUserComponents();
      }, 1000);
    } else {
      showError("Error");
    }
  };

  const items = [
    { label: "Edit", command: handleEdit },
    { label: "Delete",  command: handleDelete },
  ];

  return (
    <>
      <Menu model={items} popup ref={menu} />
      {/*<Button
        style={{ cursor: "pointer" }}
        icon="pi pi-ellipsis-v"
        className="p-button-rounded p-button-text"
        onClick={(e) => menu.current.toggle(e)}
      /> */}
      <i
                  style={{ cursor: "pointer" }}
                  className="pi pi-ellipsis-v"
                  onClick={(event) => { menu.current.toggle(event)}}
              ></i>
    </>
  );
};
const handleselectComponentModal = () => {
        if (!selectComponentModal) {
            const payload = {
                description: "Select component",
            };
            insertLogs(payload);
        }
        setIsLoading(true);
        setTitle("Select component for your property");
       
        setSelectComponentModal(!selectComponentModal);

       
  
};
 // Function to display index (row number)
 const bodyTemplate = (rowData, { rowIndex }) => {
  return rowIndex + 1; // Add 1 because rowIndex is zero-based
};

  return (
    <div style={{ margin: "2rem" }}>
      <div className="grid mr-6">
        <div className="col-1"></div>
        <div className="col-7 mt-2">
          <span style={{ color: "#6c757d", fontSize: "1.2rem" }}>
            <b>{addressOne}</b>
          </span>
        </div>
        <div className="col-4 align-text-rt">
        <Button
            type="button"
            label="Property Component"
            className="p-button-raised p-button-warning mr-4"
            onClick={handleselectComponentModal}
          />
          </div>
      </div>
      <Toast ref={toast} className="ToastMessage" />
      <DataTable
        value={componentData}
        responsiveLayout="scroll"
      //  className="mt-6 p-datatable-gridlines"
        emptyMessage="Please add your property components details"
        scrollHeight="600px"
        scrollDirection="both"
        className="propertyTable mt-3"
        rows={10}
        loading={isLoading}
        paginator={componentData.length > 10}
        rowHover
        stripedRows 
      >
        <Column   body={bodyTemplate} header="ID" />
        <Column  field="component" header="Component" />
        <Column  field="component_name" header="Component Name" />
        <Column  field="component_type" header="Component Type" />  
        <Column field="Actions" body={actionTemplate} header="Actions" />
      </DataTable>
      { selectComponentModal && renderSelectComponentModal()}
    </div>
  );
};

export default PropertyConfiguration;
