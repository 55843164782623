import { FormatAmount } from "app/utils/utility.service";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLeftCalculations, getLeftCalculations } from "../../../HomePurchaseStep3Slice";
import { useMediaQuery } from "react-responsive";

const { InputText } = require("primereact/inputtext");
const { Panel } = require("primereact/panel");

const BasicInformation = ({handleLeftCalculations}) => {
    const dispatch = useDispatch();
    const leftContentValues = useSelector(getLeftCalculations);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 768px) and (max-width: 1024px)'
      });
    const headerTemplate = (options) => {
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} pl-1 flex-1`;
        return (
            <Fragment>
                <div className={className}>
                    <span className={titleClassName}>Basic Information</span>
                    <div>
                        <label
                            style={{ color: "#333" }}
                            className={`${options.titleClassName}`}
                        >
                            Rent&nbsp;-&nbsp;
                        </label>
                        <label
                            style={{ color: "#333" }}
                            className={`${options.titleClassName}`}
                        >
                            {`$${leftContentValues?.rent
                                    ? FormatAmount(
                                        parseInt(leftContentValues?.rent)
                                    )
                                    : 0
                                }`}
                        </label>
                    </div>
                    {/* <div>
						<label
							style={{ marginTop: "-2rem", color: "#333" }}
							className={`${options.titleClassName}`}
						>
							Rent
						</label>
						<Button
							style={{ height: "3rem" }}
							label={`$ ${leftContentValues?.rent ? FormatAmount(leftContentValues?.rent) : 0}`}
							className="p-button-raised p-button-warning p-button-lg ml-4"
						/>
					</div> */}
                </div>
            </Fragment>
        );
    };
    const zipcode = 
        <Fragment>
            <div className="col-12 md:col-12 lg:col-2">
                <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>ZIP Code</label>
            </div>
            <div className="col-12 md:col-11 lg:col-4">
                <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                   <div className="p-inputgroup">
                    <InputNumber
                        className="vw-inputnumber-with-border"
                        autoComplete="off"
                        required={true}
                        name="zipCode"
                        useGrouping={false} 
                        autoFocus
                        value={leftContentValues?.zipCode}
                        onChange={(e) => {
                            dispatch(
                                changeLeftCalculations({
                                    name: "zipCode",
                                    value: e.value,
                                })
                            );
                        }}/>
                    </div>
                </div>
            </div>
        </Fragment>
    
    const rent = 
        <Fragment>
            <div className={`col-12 md:col-12 lg:col-2 ${isTablet ? "ml-6" : ""}`}>
                <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>Rent</label>
            </div>
            <div className={`col-12 md:col-11 lg:col-4 ${isTablet ? "ml-6" : ""}`}>
                <div className="field">
                    <div className="p-inputgroup">
                        <InputNumber
                            className="vw-inputnumber-with-border"
                            autoComplete="off" 
                            required={true}
                            prefix="$ " 
                            name="rent"
                            disabled={false}
                            min={0}
                            // mode="decimal"
                            // minFractionDigits={2}
                            onValueChange={(e) => {
                                dispatch(
                                    changeLeftCalculations({
                                        name: "rent",
                                        value: e.target.value,
                                    })
                                    );
                            }}
                            // onBlur={(e) => {
                            //     if (!e.target.value) {
                            //         dispatch(
                            //             changeLeftCalculations({
                            //                 name: "downPayment",
                            //                 value: 3.5,
                            //             })
                            //         );
                            //         handleLeftCalculations(
                            //             "downPayment",
                            //             3.5
                            //         );
                            //     } else {
                            //         handleLeftCalculations(
                            //             "downPayment",
                            //             e.target.value
                            //         );
                            //     }
                            // }}
                            value={leftContentValues?.rent}
                        // value={
                        //     leftContentValues?.downPayment
                        //         ? FormatAmount(
                        //               leftContentValues?.downPayment
                        //           )
                        //         : 0
                        // }
                        />
                    </div>
                </div>
            </div>
        </Fragment>

    return (
        <Panel
            className={`BasicInformation ${isDesktopOrLaptop ? "mb-2" : "align-text-left"}
            ${isTablet ? "mt-2" : ""}`}
            // header="Basic Information"
            headerTemplate={headerTemplate}
        >
            <div className={`${isDesktopOrLaptop ? "align-text" : "align-text-left"}`}>
                <div className="grid">
                {isDesktopOrLaptop ? (
                   <Fragment>
                    {zipcode}
                   </Fragment>
                ):
                <div className={`${isTablet ? "w-50 mt-2" : ""}`}>
                    {zipcode}
                </div>
                }
                {isDesktopOrLaptop ? (
                    <Fragment>
                        {rent}
                    </Fragment>
                    ):
                    <div className={`${isTablet ? "w-50 mt-2" : ""}`}>
                        {rent}
                    </div>
                    }
                </div>
            </div>
            {isDesktopOrLaptop ? (
                null
            ) :(
                <hr className="separator" />
            )}
        </Panel>
    );
};

export default BasicInformation;
