import { ErrorToastConfig } from "app/utils/ToastConstants";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  editPropertyDetails,
  getReminderDetails,
  insertLogs,
} from "../../../homepurchase.service";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Loading } from "app/components";
import HOA from "./HOA";
import PropertyTaxes from "./PropertyTaxes";
import InsuranceTaxes from "./InsuranceTaxes";
import HomeWarranty from "./HomeWarranty";
import { getSessionUserInformation } from "app/utils/utility.service";
import MortgageSchedule from "./MortgageSchedule";

const PropertyPayments = ({ handleInputChange, values, options }) => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [HOADetails, setHOADetails] = useState([]);
  const [PropertyTaxDetails, setPropertyTaxDetails] = useState([]);
  const [InsuranceTaxDetails, setInsuranceTaxDetails] = useState([]);
  const [HomeWarrantyDetails, setHomeWarrantyDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetchedHOA, setIsDataFetchedHOA] = useState(false);
  const [isDataFetchedPropTax, setIsDataFetchedPropTax] = useState(false);
  const [isDataFetchedInsTax, setIsDataFetchedInsTax] = useState(false);
  const [totalInsurance, setTotalInsurance] = useState(0);
  const [totalHOA, setTotalHOA] = useState(0);
  const [totalPTax, setTotalPTax] = useState(0);
  const [totalHomeWarranty, setTotalHomeWarranty] = useState(0);
  const [isDataFetchedHomeWarranty, setIsDataFetchedHomeWarranty] =
    useState(false);
  const [searchParams] = useSearchParams();
  const sessionValues = getSessionUserInformation();
  const propertyId = searchParams.get("propertyId");
  const clientId = searchParams.get("clientid");
  const userId = clientId && clientId !== "null" ? clientId : sessionValues.id;
  const [HOAModal, setHOAModal] = useState(false);
  const [PropertyTaxModal, setPropertyTaxModal] = useState(false);
  const [InsuranceTaxModal, setInsuranceTaxModal] = useState(false);
  const [HomeWarrantyModal, setHomeWarrantyModal] = useState(false);
  const [MortgageModal, setMortgageModal] = useState(false);
  const toast = useRef();

  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const amortizationYears = [
    { name: "15 Years", code: 15 },
    { name: "30 Years", code: 30 },
    { name: "40 Years", code: 40 },
  ];

  const renderFooter = () => {
    return (
      <div className="grid">
        <div
          className={`${
            isDesktopOrLaptop ? "col-8" : "col-12 text-align-center"
          }`}
        >
          <Button
            type="button"
            label="Cancel"
            className="p-button-raised p-button-lg-1 mr-4"
            onClick={() =>  navigate("/home-purchase/portfolio/realestate")}
          />
          <Button
            type="submit"
            label="Save"
            className={`p-button-raised p-button-warning p-button-lg-1  ${
              isDesktopOrLaptop ? "" : "ml-4"
            }`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    );
  };

  const validateInput = () => {
    if (
      !values.addressOne ||
      !values.addressTwo ||
      !values.city ||
      !values.state ||
      !values.country ||
      !values.zipcode ||
      !values.yearBuilt ||
      !values.typeOfBuilding ||
      !values.description ||
      !values.occupancy ||
      !values.category ||
      !values.mortgage ||
      !values.rateOfInterest ||
      !values.amortization
    ) {
      return false;
    }

    if (isNaN(values.zipcode)) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    fetchHOADetails();
    fetchInsTaxDetails();
    fetchPropertyTaxDetails();
    fetchHomeWarrantyDetails();
  }, []);
  const fetchHOADetails = async () => {
    let list = await getReminderDetails(userId, "HOA", propertyId);
    list.reverse();
    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
        const totalTax = list.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
        setTotalHOA(totalTax);
      }
      setHOADetails(list);
    }
  };
  const fetchPropertyTaxDetails = async () => {
    let list = await getReminderDetails(userId, "Property Taxes", propertyId);
    list.reverse();
    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
        const totalTax = list.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
        setTotalPTax(totalTax);
      }
      setPropertyTaxDetails(list);
    }
  };
  const fetchInsTaxDetails = async () => {
    let list = await getReminderDetails(userId, "Insurance Taxes", propertyId);
    list.reverse();
    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
        const totalTax = list.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
        setTotalInsurance(totalTax);
      }
      setInsuranceTaxDetails(list);
    }
  };
  const fetchHomeWarrantyDetails = async () => {
    let list = await getReminderDetails(userId, "Home Warranty", propertyId);
    list.reverse();
    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
        const totalTax = list.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
        setTotalHomeWarranty(totalTax);
      }
      setHomeWarrantyDetails(list);
    }
  };
  const handleEditHOAModal = async () => {
    if (!HOAModal) {
      const payload = {
        description: "HOA",
      };
      insertLogs(payload);
    }
    if (!isDataFetchedHOA) {
      fetchHOADetails();
      setIsDataFetchedHOA(true);
    }
    setHOAModal(!HOAModal);
  };
  const handleEditPropertyTaxModal = () => {
    if (!PropertyTaxModal) {
      const payload = {
        description: "Property Tax",
      };
      insertLogs(payload);
    }
    if (!isDataFetchedPropTax) {
      fetchPropertyTaxDetails();
      setIsDataFetchedPropTax(true);
    }
    setPropertyTaxModal(!PropertyTaxModal);
  };
  const handleEditInsuranceTaxModal = () => {
    if (!InsuranceTaxModal) {
      const payload = {
        description: "Insurance Tax",
      };
      insertLogs(payload);
    }
  //  if (!isDataFetchedInsTax) {
    //  fetchInsTaxDetails();
      //setIsDataFetchedInsTax(true);
    //}
    setInsuranceTaxModal(!InsuranceTaxModal);
  };
  const handleEditHomeWarrantyModal = () => {
    if (!HomeWarrantyModal) {
      const payload = {
        description: "Insurance Tax",
      };
      insertLogs(payload);
    }
    if (!isDataFetchedHomeWarranty) {
      fetchHomeWarrantyDetails();
      setIsDataFetchedHomeWarranty(true);
    }
    setHomeWarrantyModal(!HomeWarrantyModal);
  };
  const handleEditMortgagePaymentModal = async () => {
    if (!MortgageModal) {
      const payload = {
        description: "Mortgage",
      };
      insertLogs(payload);
    }
   
    setMortgageModal(!MortgageModal);
  };
  const renderEditHOAModal = () => (
    <HOA
      HOAModal={HOAModal}
      handleEditHOAModal={handleEditHOAModal}
      HOADetails={HOADetails}
      fetchHOADetails={fetchHOADetails}
    />
  );
  const renderEditPropertyTaxesModal = () => (
    <PropertyTaxes
      PropertyTaxModal={PropertyTaxModal}
      handleEditPropertyTaxModal={handleEditPropertyTaxModal}
      PropertyTaxDetails={PropertyTaxDetails}
      fetchPropertyTaxDetails={fetchPropertyTaxDetails}
    />
  );
  const renderEditInsuranceTaxesModal = () => (
    <InsuranceTaxes
      InsuranceTaxModal={InsuranceTaxModal}
      handleEditInsuranceTaxModal={handleEditInsuranceTaxModal}
      InsuranceTaxDetails={InsuranceTaxDetails}
      fetchInsTaxDetails={fetchInsTaxDetails}
      
    />
  );
  const renderEditHomeWarrantyModal = () => (
    <HomeWarranty
      HomeWarrantyModal={HomeWarrantyModal}
      handleEditHomeWarrantyModal={handleEditHomeWarrantyModal}
      HomeWarrantyDetails={HomeWarrantyDetails}
      fetchHomeWarrantyDetails={fetchHomeWarrantyDetails}
    />
  );
  const renderEditMortgageModal = () => (
    <MortgageSchedule
      MortgageModal={MortgageModal}
      handleEditMortgagePaymentModal={handleEditMortgagePaymentModal}
      values={values}
    />
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validateInput()) {
      //   showError("Please fill in all required fields correctly.");

      return false;
    }
    const details = await editPropertyDetails(values, values.id);
    if (details) {
      showSuccess("Record updated successfully");
      // insert settings value
      setIsLoading(false);
      setTimeout(() => {
        navigate("/home-purchase/portfolio/realestate");
      }, 1000);
    } else {
      showError("Error");
    }
  };

  return (
    <div>
      <div className="grid mr-5">
        <div className="col-4"></div>
        <div className="col-6 mt-2">
          <span style={{ color: "#6c757d", fontSize: "1.2rem" }}>
            <b>{values?.addressOne}</b>
          </span>
        </div>
      </div>
      <Panel className={`mb-2 OwnHouse `}>
        {isLoading && <Loading />}
        <Toast ref={toast} className="ToastMessage" />
        <div className={`mb-2 align-text`}>
          <div>
            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Purchase Price</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      autoComplete="off"
                      required={true}
                      name="purchasePrice"
                      disabled={true}
                      prefix="$ "
                      value={values?.purchasePrice}
                    />
                  </div>
                </div>

                <div className="text-align-left">
                  {formSubmitted && !values?.purchasePrice && (
                    <small className="p-error">
                      Please enter purchase price
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Downpayment</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      autoComplete="off"
                      required={true}
                      name="downPayment"
                      disabled={true}
                      prefix="$ "
                      value={values?.downPayment}
                    />
                  </div>
                </div>

                <div className="text-align-left">
                  {formSubmitted && !values?.downPayment && (
                    <small className="p-error">Please enter down payment</small>
                  )}
                </div>
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Principal</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      autoFocus
                      prefix="$ "
                      name="mortgage"
                      onValueChange={handleInputChange}
                      value={values?.mortgage}
                    />
                  </div>
                </div>

                {formSubmitted && !values?.mortgage && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter mortgage</small>
                  </div>
                )}
              </div>

            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Mortgage Payment</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      autoFocus
                      prefix="$ "
                      name="mortgagePayments"
                      onValueChange={handleInputChange}
                      value={values?.mortgagePayments}
                    />
                  </div>
                </div>

                {formSubmitted && !values?.mortgagePayments && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter mortgage payment</small>
                  </div>
                )}
              </div>
              <div className="col-1 pt-4">
                <a href="javascript:;" className=""  onClick={handleEditMortgagePaymentModal}>
                  <i className={`pi pi-pencil`} />
                </a>
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Rate</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      suffix=" %"
                      min={1.0}
                      max={99.999}
                      minFractionDigits={1}
                      name="rateOfInterest"
                      onValueChange={handleInputChange}
                      value={values?.rateOfInterest}
                    />
                  </div>
                </div>

                {formSubmitted && !values?.rateOfInterest && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter rate</small>
                  </div>
                )}
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Rate Type</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <Dropdown
                      className="vw-dropdown zipcode-dropdown"
                      value={values?.rateOfInterestType}
                      options={options}
                      required={true}
                      name="rateOfInterestType"
                      onChange={handleInputChange}
                      optionLabel="name"
                      optionValue="name"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                      }}
                    />
                  </div>
                </div>

                {formSubmitted && !values?.rateOfInterestType && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter rate type</small>
                  </div>
                )}
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>First Reset</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      suffix=" %"
                      min={1.0}
                      max={99.999}
                      minFractionDigits={1}
                      name="firstReset"
                      onValueChange={handleInputChange}
                      value={values?.firstReset}
                    />
                  </div>
                </div>

                {formSubmitted && !values?.city && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter first reset</small>
                  </div>
                )}
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Rate Cap</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      suffix=" %"
                      min={1.0}
                      max={99.999}
                      minFractionDigits={1}
                      name="rateCap"
                      onValueChange={handleInputChange}
                      value={values?.rateCap}
                    />
                  </div>
                </div>

                {formSubmitted && !values?.city && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter rate cap</small>
                  </div>
                )}
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Max Rate</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      name="maxRate"
                      suffix=" %"
                      min={1.0}
                      max={99.999}
                      minFractionDigits={1}
                      onValueChange={handleInputChange}
                      value={values?.maxRate}
                    />
                  </div>
                </div>

                {formSubmitted && !values?.city && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter max rate</small>
                  </div>
                )}
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>Amortization</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <Dropdown
                      className="vw-dropdown vw-dropdown-with-border"
                      value={values?.amortization}
                      options={amortizationYears}
                      required={true}
                      name="amortization"
                      onChange={handleInputChange}
                      optionLabel="name"
                      optionValue="code"
                    />
                  </div>
                </div>

                {formSubmitted && !values?.amortization && (
                  <div className="text-align-left errormsg-margin">
                    <small className="p-error">Please enter Amortization</small>
                  </div>
                )}
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label>HOA</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      name="hoa"
                      disabled={true}
                      prefix="$ "
                      onValueChange={handleInputChange}
                      value={totalHOA !== 0? totalHOA : values?.hoa}
                    />
                  </div>
                </div>
              </div>
              <div className="ml-4 pt-4">
                <a
                  href="javascript:;"
                  className=""
                  onClick={handleEditHOAModal}
                >
                  <i className={`pi pi-pencil`} />
                </a>
              </div>
              <div className="ml-4 pt-4">
                <span onClick={handleEditHOAModal} style={{ cursor: "pointer" }}>Set Alert</span>
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label> Property Taxes</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      required={true}
                      name="propertyTaxes"
                      disabled={true}
                      prefix="$ "
                      onValueChange={handleInputChange}
                      value={totalPTax !== 0? totalPTax : values?.propertyTaxes}
                    />
                  </div>
                </div>
              </div>
              <div className="ml-4 pt-4">
                <a
                  href="javascript:;"
                  className=""
                  onClick={handleEditPropertyTaxModal}
                >
                  <i className={`pi pi-pencil`} />
                </a>
              </div>
              <div className="ml-4 pt-4">
                <span onClick={handleEditPropertyTaxModal} style={{ cursor: "pointer" }}>Set Alert</span>
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label> Insurance</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border w-100"
                      autoComplete="off"
                      required={true}
                      disabled={true}
                      prefix="$ "
                      name="insurance"
                      onValueChange={handleInputChange}
                      value={totalInsurance !== 0? totalInsurance : values?.insurance}
                    />
                  </div>
                </div>
              </div>
              <div className="ml-4 pt-4">
                <a
                  href="javascript:;"
                  className=""
                  onClick={handleEditInsuranceTaxModal}
                >
                  <i className={`pi pi-pencil`} />
                </a>
              </div>
              <div className="ml-4 pt-4">
                <span onClick={handleEditInsuranceTaxModal} style={{ cursor: "pointer" }}>Set Alert</span>
              </div>
            </div>

            <div className="grid mr-6">
              <div className="col-12 md:col-12 lg:col-4 mt-3">
                <label> Home Warranty</label>
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <div className="field">
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border w-100"
                      autoComplete="off"
                      required={true}
                      prefix="$ "
                      disabled={true}
                      name="homeWarranty"
                      onValueChange={handleInputChange}
                      value={totalHomeWarranty !== 0? totalHomeWarranty : values?.homeWarranty}
                    />
                  </div>
                </div>
              </div>
              <div className="ml-4 pt-4">
                <a
                  href="javascript:;"
                  className=""
                  onClick={handleEditHomeWarrantyModal}
                >
                  <i className={`pi pi-pencil`} />
                </a>
              </div>
              <div className="ml-4 pt-4">
                <span onClick={handleEditHomeWarrantyModal} style={{ cursor: "pointer" }}>Set Alert</span>
              </div>
            </div>

            {renderFooter()}
          </div>
        </div>
      </Panel>
      {HOAModal && renderEditHOAModal()}

      {PropertyTaxModal && renderEditPropertyTaxesModal()}

      {InsuranceTaxModal && renderEditInsuranceTaxesModal()}
      {HomeWarrantyModal && renderEditHomeWarrantyModal()}
      {MortgageModal && renderEditMortgageModal()}
    </div>
  );
};

export default PropertyPayments;
