import React, { forwardRef, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { editPropertyDetails } from "../../../homepurchase.service";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { Toast } from "primereact/toast";
import { Loading } from "app/components";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { formatDate } from "app/utils/utility.service";

const PropertyInformation = ({
  handleInputChange,
  handleDateChange,
  values,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const toast = useRef();

  const countries = [
    { name: "Australia", code: "AU" },
    { name: "Brazil", code: "BR" },
    { name: "China", code: "CN" },
    { name: "Egypt", code: "EG" },
    { name: "France", code: "FR" },
    { name: "Germany", code: "DE" },
    { name: "India", code: "IN" },
    { name: "Japan", code: "JP" },
    { name: "Spain", code: "ES" },
    { name: "United States", code: "US" },
  ];
  const [searchParams] = useSearchParams();
  const index = searchParams.get("index");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const currentYear = 1900;
  const years = Array.from({ length: 200 }, (_, i) => ({
    label: `${currentYear + i}`,
    value: (currentYear + i).toString(),
  }));

  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const [formData, setFormData] = useState({ name: "", email: "" });
  const validateInput = () => {
    if (
      !values.addressOne ||
      !values.city ||
      !values.state ||
      !values.country ||
      !values.zipcode ||
      !values.yearBuilt ||
      !values.typeOfBuilding ||
      !values.description ||
      !values.occupancy ||
      !values.category
    ) {
      return false;
    }

    if (isNaN(values.zipcode)) {
      return false;
    }
    return true;
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validateInput()) {
      //   showError("Please fill in all required fields correctly.");

      return false;
    }
    const details = await editPropertyDetails(values, values.id);
    if (details) {
      showSuccess("Record updated successfully");
      // insert settings value
      setIsLoading(false);
      setTimeout(() => {
        navigate("/home-purchase/portfolio/realestate");
      }, 1000);
    } else {
      showError("Error");
    }
  };
  const renderFooter = () => {
    return (
      <div className="grid">
        <div
          className={`${
            isDesktopOrLaptop ? "col-8" : "col-12 text-align-center"
          }`}
        >
          <Button
            type="button"
            label="Cancel"
            className="p-button-raised p-button-lg mr-4"
            onClick={() => navigate("/home-purchase/portfolio/realestate")}
          />
          <Button
            type="submit"
            label="Save"
            className={`p-button-raised p-button-warning p-button-lg  ${
              isDesktopOrLaptop ? "" : "ml-4"
            }`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    );
  };
  return (
    <Panel className={`mb-2 OwnHouse `}>
      {isLoading && <Loading />}
      <Toast ref={toast} className="ToastMessage" />
      <div className="grid mr-6">
        <div className="col-4"></div>
        <div className="col-6 mb-4">
          <span style={{ color: "#6c757d" }}>
            <b>{values?.addressOne}</b>
          </span>
        </div>
      </div>
      <div className={`mb-2 align-text`}>
        <div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Property ID</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="propertyId"
                    disabled={true}
                    value={index}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Purchase Date</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <Calendar
                    id="buttondisplay"
                    name="purchaseDate"
                    value={
                      values?.purchaseDate
                        ? new Date(formatDate(values?.purchaseDate))
                        : new Date(formatDate(values?.createdAt))
                    }
                    showIcon
                    dateFormat="mm/dd/yy"
                    onChange={handleDateChange}
                  />
                </div>
              </div>

              <div className="text-align-left">
                {formSubmitted && !values?.createdAt && (
                  <small className="p-error">Please enter purchase date</small>
                )}
              </div>
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Address 1</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    id="addressOne"
                    autoFocus
                    className="vw-inputtext-with-border"
                    name="addressOne" // Set name to identify the property
                    value={values?.addressOne} // Bind value to specific property
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {formSubmitted && !values?.addressOne && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter address1</small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Address 2</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    name="addressTwo"
                    id="addressTwo"
                    value={values?.addressTwo}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>City</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="city"
                    onChange={handleInputChange}
                    value={values?.city}
                  />
                </div>
              </div>

              {formSubmitted && !values?.city && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter city</small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>State</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="state"
                    onChange={handleInputChange}
                    value={values?.state}
                  />
                </div>
              </div>

              {formSubmitted && !values?.state && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter state</small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Country</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <span className="p-float-label">
                    <Dropdown
                      className="vw-dropdown zipcode-dropdown"
                      value={values?.country}
                      options={countries}
                      required={true}
                      name="country"
                      onChange={handleInputChange}
                      optionLabel="name"
                      optionValue="name"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                      }}
                      // placeholder="Select a Country"
                    />
                  </span>
                </div>
              </div>
              <div className="text-align-left">
                {formSubmitted && !values?.country && (
                  <small className="p-error">Please enter country</small>
                )}
              </div>
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>ZIP CODE</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="zipcode"
                    onChange={handleInputChange}
                    value={values?.zipcode}
                  />
                </div>
              </div>

              {formSubmitted && !values?.zipcode && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter ZIP CODE</small>
                </div>
              )}
              {formSubmitted && isNaN(values?.zipcode) && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">
                    Zip code must be in numeric format
                  </small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Year built</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <Dropdown
                    className=" vw-dropdown  vw-dropdown-with-border"
                    name="yearBuilt"
                    value={values?.yearBuilt}
                    onChange={handleInputChange}
                    options={years}
                    placeholder="Select a Year"
                    style={{
                      border: "1px solid #ced4da", // Customize the border color and width
                      borderRadius: "3px", // Optional: round the corners
                      padding: "5px", // Optional: add padding for spacing
                      height: "3.5rem",
                    }}
                  />
                  
                </div>
              </div>

              {formSubmitted && !values?.yearBuilt && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter year built</small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Type of Building</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="typeOfBuilding"
                    onChange={handleInputChange}
                    value={values?.typeOfBuilding}
                  />
                </div>
              </div>
              {formSubmitted && !values?.typeOfBuilding && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">
                    Please enter type of building
                  </small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Description</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="description"
                    onChange={handleInputChange}
                    value={values?.description}
                  />
                </div>
              </div>
              {formSubmitted && !values?.description && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter description</small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Occupancy</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="occupancy"
                    onChange={handleInputChange}
                    value={values?.occupancy}
                  />
                </div>
              </div>

              {formSubmitted && !values?.occupancy && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter occupancy</small>
                </div>
              )}
            </div>
          </div>
          <div className="grid mr-6">
            <div className="col-12 md:col-12 lg:col-4 mt-3">
              <label>Category</label>
            </div>
            <div className="col-12 md:col-12 lg:col-6">
              <div className="field">
                <div className="p-inputgroup">
                  <InputText
                    className="vw-inputtext-with-border"
                    autoComplete="off"
                    required={true}
                    name="category"
                    onChange={handleInputChange}
                    value={values?.category}
                  />
                </div>
              </div>

              {formSubmitted && !values?.category && (
                <div className="text-align-left errormsg-margin">
                  <small className="p-error">Please enter category</small>
                </div>
              )}
            </div>
          </div>
          {renderFooter()}
        </div>
      </div>
    </Panel>
  );
};
const CustomInput = forwardRef(({ value, onClick, customclass }, ref) => (
  <div style={{ position: "relative", display: "inline-block" }}>
    <input
      type="text"
      value={value}
      onClick={onClick}
      ref={ref}
      class={customclass}
    />
    {/* Custom icon to open the calendar */}
    <img
      src="/calendar.jpg"
      alt="calendar icon"
      onClick={onClick}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        width: "20px",
        height: "20px",
      }}
    />
  </div>
));
export default PropertyInformation;
