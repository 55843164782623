import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  generateUserPaymentReport,
  getPropertyDetails,
} from "../homepurchase.service";
import { Loading } from "app/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSessionUserInformation } from "app/utils/utility.service";
import ReactToPrint, { useReactToPrint } from "react-to-print";

// Component to render the printable content
const ComponentToPrint = React.forwardRef(
  ({ formattedData, totalSummary, isDesktopOrLaptop }, ref) => {
    return (
      <div
        ref={ref}
        style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
      >
        {formattedData.map((item, index) => (
          <div
            key={index}
            className="report-row"
            style={{ marginBottom: "10px" }}
          >
            <label
              style={{
                fontWeight: "bold",
                display: "block",
                marginBottom: "5px",
              }}
            >
              {item.address}
            </label>
            <div className="grid mt-4">
              <div
                className={`${
                  isDesktopOrLaptop ? "col-1 align-text-rt" : "col-11"
                }`}
              >
                <label class="mt-3">HOA</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  value={item["HOA"].toFixed(2)}
                  style={{ width: "180px" }}
                  disabled={true}
                  name="amount"
                  prefix="$ "
                />
              </div>
              <div
                className={`${
                  isDesktopOrLaptop ? "col-3 align-text-rt" : "col-11"
                }`}
              >
                <label class="mt-3">Property Tax </label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-2" : "col-11"}`}>
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  value={item["Property Taxes"].toFixed(2)}
                  style={{ width: "180px" }}
                  name="amount"
                  disabled={true}
                  prefix="$ "
                />
              </div>
            </div>

            <div className="grid mt-4">
              <div
                className={`${
                  isDesktopOrLaptop ? "col-1 align-text-rt" : "col-11"
                }`}
              >
                <label class="mt-3">Insurance</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  value={item["Insurance Taxes"].toFixed(2)}
                  style={{ width: "180px" }}
                  name="amount"
                  disabled={true}
                  prefix="$ "
                />
              </div>
              <div
                className={`${
                  isDesktopOrLaptop ? "col-3 align-text-rt" : "col-11"
                }`}
              >
                <label class="mt-3">Home Warranty</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-2" : "col-11"}`}>
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  value={item["Home Warranty"].toFixed(2)}
                  style={{ width: "180px" }}
                  name="amount"
                  disabled={true}
                  prefix="$ "
                />
              </div>
            </div>

            <div className="grid mt-4">
              <div
                className={`${
                  isDesktopOrLaptop ? "col-1 align-text-rt" : "col-11"
                }`}
              >
                <label class="mt-3">Total</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <InputNumber
                  className="vw-inputnumber-with-border"
                  autoComplete="off"
                  value={
                    item["HOA"] +
                    item["Home Warranty"] +
                    item["Property Taxes"] +
                    item["Insurance Taxes"]
                  }
                  style={{ width: "180px" }}
                  name="amount"
                  disabled={true}
                  prefix="$ "
                />
              </div>
            </div>

            <hr className="lineCls"/>
          </div>
        ))}
        {formattedData?.length > 0 && (
          <Fragment>
            <div className="grid mt-4">
              <div className="grid col-4">
                <div
                  className={`${
                    isDesktopOrLaptop ? "col-1 align-text-rt ml-5" : "col-11"
                  }`}
                >
                  <label class="mt-3">HOA Total</label>
                </div>
                <div
                  className={`${isDesktopOrLaptop ? "col-8 ml-5" : "col-11"}`}
                >
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      value={totalSummary["HOA"]?totalSummary["HOA"].toFixed(2):"0"}
                      name="amount"
                      disabled={true}
                      prefix="$ "
                    />
                  </div>
                </div>
              </div>

              <div className=" grid col-4">
                <div
                  className={`${
                    isDesktopOrLaptop ? "col-1 align-text-rt" : "col-11"
                  }`}
                >
                  <label class="mt-3">Property Tax Total</label>
                </div>
                <div
                  className={`${isDesktopOrLaptop ? "col-8 ml-7" : "col-11"}`}
                >
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      value={totalSummary["Property Taxes"]?totalSummary["Property Taxes"]?.toFixed(2):"0"}
                      name="amount"
                      disabled={true}
                      prefix="$ "
                    />
                  </div>
                </div>
              </div>

              <div className="grid col-4">
                <div
                  className={`${
                    isDesktopOrLaptop ? "col-1 align-text-rt" : "col-11"
                  }`}
                >
                  <label class="mt-3">Insurance Total</label>
                </div>
                <div
                  className={`${isDesktopOrLaptop ? "col-8 ml-7" : "col-11"}`}
                >
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      value={totalSummary["Insurance Taxes"]?totalSummary["Insurance Taxes"].toFixed(2):"0"}
                      style={{ width: "50px" }}
                      name="amount"
                      disabled={true}
                      prefix="$ "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid mt-4">
              <div className="grid col-4">
                <div
                  className={`${
                    isDesktopOrLaptop ? "col-1 align-text-rt" : "col-11"
                  }`}
                >
                  <label class="mt-3">Home Warranty Total</label>
                </div>
                <div
                  className={`${isDesktopOrLaptop ? "col-8 ml-7" : "col-11"}`}
                >
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      value={totalSummary["Home Warranty"]?totalSummary["Home Warranty"].toFixed(2):"0"}
                      name="amount"
                      disabled={true}
                      prefix="$ "
                    />
                  </div>
                </div>
              </div>
              <div className="grid col-4">
                <div
                  className={`${
                    isDesktopOrLaptop ? "col-1 align-text-rt" : "col-11"
                  }`}
                >
                  <label class="mt-3">All Total </label>
                </div>

                <div
                  className={`${isDesktopOrLaptop ? "col-8 ml-7" : "col-11"}`}
                >
                  <div className="p-inputgroup">
                    <InputNumber
                      className="vw-inputnumber-with-border"
                      autoComplete="off"
                      value={
                        totalSummary['all']
                      }
                      style={{ width: "50px" }}
                      name="amount"
                      disabled={true}
                      prefix="$ "
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
);

const PaymentSummaryReport = () => {
  // States
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const currentYear = 1900;
  const years = Array.from({ length: 200 }, (_, i) => ({
    label: `${currentYear + i}`,
    value: currentYear + i,
  }));
  const months = [
    { label: "January", value: "1" },
    { label: "February", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonthStart, setSelectedMonthStart] = useState(months[0].value);
  const [selectedYearStart, setSelectedYearStart] = useState(years[0].value);
  const [selectedMonthEnd, setSelectedMonthEnd] = useState(months[0].value);
  const [selectedYearEnd, setSelectedYearEnd] = useState(years[0].value);
  let componentRef = useRef(null);
  const promiseResolveRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [isPrinting, setIsPrinting] = useState(false);
  const sessionValues = getSessionUserInformation();
  const clientId = searchParams.get("clientid");
  const userId = clientId && clientId !== "null" ? clientId : sessionValues.id;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    setIsLoading(true);

    fetchPropertyDetails();
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);
  const fetchPropertyDetails = async () => {
    const userId =
      clientId && clientId !== "null" ? clientId : sessionValues.id;
    let list = await getPropertyDetails(userId);
    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
      }
      const allOption = { id: "all", addressOne: "All" };
      const updatedOptions = [allOption, ...list];
      setPropertyDetails(updatedOptions);
      setSelectedAddress(updatedOptions[0].id);
    }
  };
  const fetchDetails = async () => {
    // Ensure the month is two digits (e.g., 01, 02, etc.)
    const formattedMonth = selectedMonthStart.toString().padStart(2, "0");
    // Combine year, month, and day to form YYYY-MM-DD
    const date1 = `${selectedYearStart}-${formattedMonth}-01`;

    const formattedMonthEnd = selectedMonthEnd.toString().padStart(2, "0");
    // Combine year, month, and day to form YYYY-MM-DD
    const date2 = `${selectedYearEnd}-${formattedMonthEnd}-01`;

    let list = await generateUserPaymentReport(
      userId,
      date1,
      date2,
      selectedAddress
    );
    if (list) {
      setIsLoading(false);
      if (list) {
        list.map((li, index) => (li.srNo = index + 1));
      }
      setData(list);
    }
  };
  const handleDropdownChange = async (e) => {
    setSelectedAddress(e.value);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Payment Summary Report",
  });

  const groupedData = data.reduce((acc, item) => {
    const address = item["portfolioRecord.addressOne"];
    if (!acc[address]) {
      acc[address] = { address, payments: {} };
    }

    // Add the payment type and amount
    acc[address].payments[item.payment_type] = parseFloat(item.totalAmount);
    return acc;
  }, {});
 
  const result = Object.values(groupedData);
  // Format the data to access specific payment types like HOA and Property Taxes
  const formattedData = result.map((addressObj) => ({
    address: addressObj.address,
    "HOA": addressObj.payments["HOA"] || 0,
    "Insurance Taxes": addressObj.payments["Insurance Taxes"]  || 0, // Assuming "Insurance Taxes" is equivalent to Property Taxes
    "Home Warranty": addressObj.payments["Home Warranty"] || 0,
    "Property Taxes": addressObj.payments["Property Taxes"] || 0,
  }));


  // Calculate total amounts for all addresses
  const totalSummary = {};
  const total = data.reduce((sum, item) => sum + parseFloat(item.totalAmount), 0);
  totalSummary['all'] = total;
  data.forEach((item) => {
    
    if (!totalSummary[item.payment_type]) {
      totalSummary[item.payment_type] = 0;
    }
    totalSummary[item.payment_type] += parseFloat(item.totalAmount);
  });
  
  return (
    <div>
      {isLoading && <Loading />}
      <div className="payment-summary">
      <div className="grid">
        <div className="bannerStyle col-12 mb-4">Payment Summary Report</div>
      </div>

        {/* Filters */}
        <div style={{ marginBottom: "20px" }}>
          <Dropdown
            className=" vw-dropdown  vw-dropdown-with-border"
            options={propertyDetails}
            value={selectedAddress}
            onChange={handleDropdownChange}
            optionLabel="addressOne" // Display the name in the dropdown
            optionValue="id"
            style={{
              width: "100px",
              border: "1px solid #ced4da", // Customize the border color and width
              borderRadius: "3px", // Optional: round the corners
              padding: "5px", // Optional: add padding for spacing
              height: "3.5rem",
            }}
          />
        </div>
        <div className="grid" style={{ alignItems: "center" }}>
          <label className="ml-2">From Date</label>
          <div className="col-4" style={{ display: "flex" }}>
            <div className="col-6" style={{ width: "10.3rem" }}>
              <Dropdown
                className=" vw-dropdown  vw-dropdown-with-border"
                options={years}
                value={selectedYearStart}
                onChange={(e) => setSelectedYearStart(e.value)}
                style={{
                  width: "100px",
                  border: "1px solid #ced4da", // Customize the border color and width
                  borderRadius: "3px", // Optional: round the corners
                  padding: "5px", // Optional: add padding for spacing
                  height: "3.5rem",
                }}
              />
            </div>
            <div className="col-6" style={{ width: "9.4rem" }}>
              <Dropdown
                className=" vw-dropdown  vw-dropdown-with-border"
                options={months}
                value={selectedMonthStart + ""}
                onChange={(e) => setSelectedMonthStart(e.value)}
                style={{
                  width: "10px",
                  border: "1px solid #ced4da", // Customize the border color and width
                  borderRadius: "3px", // Optional: round the corners
                  padding: "5px", // Optional: add padding for spacing
                  height: "3.5rem",
                }}
              />
            </div>
          </div>
          <label className="ml-6">To Date</label>
          <div className="col-6" style={{ width: "10.3rem" }}>
            <Dropdown
              className=" vw-dropdown  vw-dropdown-with-border"
              options={years}
              value={selectedYearEnd}
              onChange={(e) => setSelectedYearEnd(e.value)}
              style={{
                width: "100px",
                border: "1px solid #ced4da", // Customize the border color and width
                borderRadius: "3px", // Optional: round the corners
                padding: "5px", // Optional: add padding for spacing
                height: "3.5rem",
              }}
            />
          </div>
          <div className="col-8" style={{ width: "9.4rem" }}>
            <Dropdown
              className=" vw-dropdown  vw-dropdown-with-border"
              options={months}
              value={selectedMonthEnd}
              onChange={(e) => setSelectedMonthEnd(e.value)}
              style={{
                width: "10px",
                border: "1px solid #ced4da", // Customize the border color and width
                borderRadius: "3px", // Optional: round the corners
                padding: "5px", // Optional: add padding for spacing
                height: "3.5rem",
              }}
            />
          </div>
          <Button
            label="Generate Report"
            className="p-button-warning"
            onClick={() => fetchDetails()}
          />
        </div>

        {/* Report Data */}

        {/* component to be printed */}
        <ComponentToPrint
          formattedData={formattedData}
          totalSummary={totalSummary}
          isDesktopOrLaptop={isDesktopOrLaptop}
          ref={componentRef}
        />
      </div>
      {/* Buttons */}
      <div style={{margin:"2rem", textAlign:"end"}}>
        <Button
          label="Print Report"
          className="p-button-raised p-button-warning mr-2"
          onClick={handlePrint}
        />
        <Button
          label="Cancel"
          className="p-button-raised p-button"
          onClick={() => navigate(-1)}
        />
        <div></div>
      </div>
    </div>
  );
};
export default PaymentSummaryReport;
