import { getSessionUserInformation } from "app/utils/utility.service";
import { getSessionClientInformation } from "app/utils/utility.service";
import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const updateRentDownPayment = async (payload, userId) => {
    const result = await restClient.put(`${BASE_URL}/users/${userId}`,payload);
    return result;
}


export const insertUpdateMonthlyExpenses = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/monthlyexpenses/create`,payload);
    return result?.data;
}

export const getMonthlyExpenses = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/monthlyexpenses/${payload.userId}`,[]);
    return result?.data; 
}


export const getIncomeCalculations = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/incomecalculations/${payload.userId}`,[]);
    return result?.data; 
}

export const getMortgageStructureDebts = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/mortgagedebts/${payload.userId}`,[]);
    return result?.data; 
}

export const getMortgageStructureDirectIncome = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/mortgagedirectincomes/${payload.userId}`,[]);
    return result?.data; 
}
export const getMortgageStructureIndirectIncome = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/mortgageindirectincomes/${payload.userId}`,[]);
    return result?.data; 
}

export const insertUpdateIncomeCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/incomecalculations/create`,payload);
    return result?.data;
}

export const insertUpdateMortgageDebtCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/mortgagedebts/create`,payload);
    return result?.data;
}
export const insertUpdateMortgageDirectIncomeCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/mortgagedirectincomes/create`,payload);
    return result?.data;
}
export const insertUpdateMortgageIndirectIncomeCalculations = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/mortgageindirectincomes/create`,payload);
    return result?.data;
}

export const insertSettings = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/settings/create`,payload);
    return result;
}

export const updateSettings = async (payload) => {
    const result = await restClient.put(`${BASE_URL}/settings/${payload.userId}`,payload);
    return result;
}

export const insertRentalIncome = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/rentalincomes/create`,payload);
    return result?.data;
}

export const updateRentalIncome = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/rentalincomes/${id}`,payload);
    return result?.data;
}

export const getRentalList = async (payload) =>{
    const result = await restClient.get(`${BASE_URL}/rentalincomes/${payload.userId}`,[]);
    return result?.data; 
}

export const deleteRentalRecord = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/rentalincomes/${id}`);
    return result?.data; 
}

export const getClientSetting = async(payload) => {
    const result = await restClient.get(`${BASE_URL}/settings/${payload}`,[]);
    return result?.data; 
}


export const getClientUser = async(payload) => {
    const result = await restClient.get(`${BASE_URL}/users/${payload}`,[]);
    return result?.data; 
}

export const insertLogs = async (payload) => {
    const sessionValues = getSessionUserInformation();
    const clientSessionValues = getSessionClientInformation();
    if(!!clientSessionValues?.id) {
        payload.userId = clientSessionValues?.id;
    } else {
        payload.userId = sessionValues?.id;
    }
    payload.updatedBy = sessionValues.id;
    payload.brokerId = sessionValues?.brokerId ? sessionValues?.brokerId : null;
    payload.agentId = sessionValues?.agentId ? sessionValues?.agentId : null;
    const result = await restClient.post(`${BASE_URL}/logs/create`,payload);
    return result?.data;
}

export const insertAssumptions = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/assumptions/create`,payload);
    return result;
}

export const updateAssumptions = async (payload) => {
    const result = await restClient.put(`${BASE_URL}/assumptions/${payload.userId}`,payload);
    return result;
}

export const getUserAssumptions = async (payload) =>{
    const result = await restClient.get(`${BASE_URL}/assumptions/${payload.userId}`,[]);
    return result?.data; 
}


export const insertInvestmentAnalysis = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/investment-analysis/create`,payload);
    return result;
}

export const updateInvestmentAnalysis = async (payload) => {
    const result = await restClient.put(`${BASE_URL}/investment-analysis/${payload.userId}`,payload);
    return result;
}

export const getInvestmentAnalysis = async (payload) =>{
    const result = await restClient.get(`${BASE_URL}/investment-analysis/${payload.userId}`,[]);
    return result?.data; 
}

export const getCurrentUser = async(userId) => {
    const result = await restClient.get(`${BASE_URL}/users/${userId}`,[]);
    return result?.data; 
}
export const getCurrentUserSettings = async(userId) => {
    const result = await restClient.get(`${BASE_URL}/settings/${userId}`,[]);
    return result?.data; 
}
export const getPropertyDetails = async(userId) => {
    const result = await restClient.get(`${BASE_URL}/portfolios?userId=${userId}`,[]);
    return result?.data; 
}
export const addPropertyDetails = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/portfolios/create`,payload);
    return result?.data;
}
export const editPropertyDetails = async (payload,userId) => {
    const result = await restClient.put(`${BASE_URL}/portfolios/${userId}`,payload);
    return result?.data;
}
export const getSinglePropertyDetails = async (propertyId) => {
    const result = await restClient.get(`${BASE_URL}/portfolios/${propertyId}`,[]);
    return result?.data;
}
export const deleteSinglePropertyDetails = async (propertyId) => {
    const result = await restClient.delete(`${BASE_URL}/portfolios/${propertyId}`,[]);
    return result;
}
export const getReminderDetails = async (userId,reminderType,propertyId) => {
    const result = await restClient.get(`${BASE_URL}/reminders/?userId=${userId}&reminder_type=${reminderType}&property_id=${propertyId}`,[]);
    return result?.data;
}
export const editReminderDetails = async (payload,hoaID) => {
    const result = await restClient.put(`${BASE_URL}/reminders/${hoaID}`,payload);
    return result?.data;
}
export const deleteReminderDetails = async (hoaId) => {
    const result = await restClient.delete(`${BASE_URL}/reminders/${hoaId}`,[]);
    return result;
}
export const addReminderDetails = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/reminders/create`,payload);
    return result?.data;
}
export const getWorkHistoryDetails = async (userId,propertyId) => {
    const result = await restClient.get(`${BASE_URL}/workpermits/?userId=${userId}`,[]);
    return result?.data;
}
export const editWorkHistoryDetails = async (payload,id) => {
    const result = await restClient.put(`${BASE_URL}/workpermits/${id}`,payload);
    return result?.data;
}
export const deleteWorkHistoryDetails = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/workpermits/${id}`,[]);
    return result;
}
export const addWorkHistoryDetails = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/workpermits/create`,payload);
    return result?.data;
}
export const getPaymentDetails = async (userId,propertyId) => {
    const result = await restClient.get(`${BASE_URL}/payments/?userId=${userId}`,[]);
    return result?.data;
}
export const editPaymentDetails = async (payload,id) => {
    const result = await restClient.put(`${BASE_URL}/payments/${id}`,payload);
    return result?.data;
}
export const deletePaymentDetails = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/payments/${id}`,[]);
    return result;
}
export const addPaymentDetails = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/payments/create`,payload);
    return result?.data;
}
export const generateUserPaymentReport = async (userId,startDate,endDate,propertyId) => {
    const result = await restClient.get(`${BASE_URL}/payments/generateUserPaymentReport?userId=${userId}&startDate=${startDate}&endDate=${endDate}&property_id=${propertyId}`,[]);
    return result?.data;
}
export const generateBreakdownReport = async (userId,startDate,endDate,propertyId,preventive) => {
    const result = await restClient.get(`${BASE_URL}/workpermits/generateBreakdownReport?userId=${userId}&startDate=${startDate}&endDate=${endDate}&property_id=${propertyId}&preventive=${preventive}`,[]);
    return result?.data;
}
