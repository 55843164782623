import { Loading } from "app/components";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { addComponent, updateComponent } from "../component.service";
import { formatDate } from "app/utils/utility.service";

const AddComponent = ({
  addComponentModal,
  handleAddComponentModal,
  title,
  values,
  setValues,
  onSave,
}) => {
  const initialState = {
    component: "",
    component_name: "",
    component_type: "",
    life_expectancy: "",
    maintenance_interval: "",
    recall_information: "",
    recall_date: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const toast = useRef();

  const onHide = () => {
    setFormSubmitted(false);
    handleAddComponentModal(false);
    setValues(initialState);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const navigate = useNavigate();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };

  const validateInput = () => {
    if (
      !values.component ||
      !values.component_name ||
      !values.component_type ||
      !values.life_expectancy ||
      !values.maintenance_interval
    ) {
      return false;
    }

    return true;
  };
   const handleDateChange = (e) => {
    const selectedDate = e.value;

    if (selectedDate) {
      // Adjust the date to UTC
      const utcDate = new Date(Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      ));
      setValues({
        ...values,
        recall_date: utcDate
      })
    } else {
        setValues({
            ...values,
            recall_date: null
          })
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validateInput()) {
      //   showError("Please fill in all required fields correctly.");

      return false;
    }
    setIsLoading(true);
    let details;
    if (title.indexOf("Add") !== -1) {
      details = await addComponent(values);
    } else {
      details = await updateComponent(values?.id, values);
    }
    if (details.status) {
      if (title.indexOf("Add") !== -1)
        showSuccess("Component added successfully");
      else showSuccess("Component updated successfully");
      setIsLoading(false);
      setTimeout(() => {
        handleAddComponentModal(!handleAddComponentModal);
        setValues(initialState);
      }, 1000);
    } else {
      setIsLoading(false);
      showError(details.message);
    }

    onSave();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    // Validate if the input is not empty
  };
  const renderFooter = () => {
    return (
      <div>
        <div className="grid">
          <div className="col-3"></div>
          <div
            className={`${
              isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"
            }`}
          >
            <Button
              label="Cancel"
              className="p-button-raised p-button-lg"
              onClick={() => onHide()}
            />
            <Button
              type="submit"
              label="Save"
              className={`p-button-raised p-button-warning p-button-lg  ${
                isDesktopOrLaptop ? "" : "ml-4"
              }`}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <Dialog
      header={title}
      visible={addComponentModal}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "35vw" }}
      footer={renderFooter()}
      className="AnnualExpenseModal"
      position="top"
      closeOnEscape={false}
      draggable={false}
    >
      {isLoading && <Loading />}
      <Toast ref={toast} className="ToastMessage" />
      <div className="center-align">
        <div className="AddPropertyForm">
          <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
            <div className="grid">
              {/* Address 1 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Component</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      autoFocus
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="component"
                      value={values?.component}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.component && (
                      <small className="p-error">Please enter component</small>
                    )}
                  </div>
                </div>
              </div>
              {/* Address 2 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Component Name</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="component_name"
                      value={values?.component_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.component_name && (
                      <small className="p-error">
                        Please enter component name
                      </small>
                    )}
                  </div>
                </div>
              </div>
              {/* City */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Component Type</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="component_type"
                      value={values?.component_type}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.component_type && (
                      <small className="p-error">
                        Please enter component type
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* State */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Life Expectancy</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="life_expectancy"
                      value={values?.life_expectancy}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.life_expectancy && (
                      <small className="p-error">
                        Please enter life expectancy
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Country */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Maintenance Interval</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <InputText
                        className="vw-inputtext-with-border"
                        autoComplete="off"
                        required={true}
                        name="maintenance_interval"
                        value={values?.maintenance_interval}
                        onChange={handleInputChange}
                      />
                    </span>
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.maintenance_interval && (
                      <small className="p-error">Please enter interval</small>
                    )}
                  </div>
                </div>
              </div>

              {/* ZIP Code */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Recall Information</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="recall_information"
                      value={values?.recall_information}
                      onChange={handleInputChange}
                    />
                  </div>

                </div>
              </div>

              {/* ZIP Code */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Recall Date</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <Calendar
                      id="buttondisplay"
                      onChange={handleDateChange}
                      value={values?.recall_date? new Date(formatDate(values?.recall_date)) : ""}
                      showIcon
                      dateFormat="mm/dd/yy"
                     
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default AddComponent;
