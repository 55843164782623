import { Loading } from "app/components";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { validateEmail } from "app/utils/utility.service";
import { FormatAmount } from "app/utils/utility.service";
import { changeAnnualExpenses } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { getAnnualExpenseCalculation } from "app/views/home-purchase/HomePurchaseStep3/HomePurchaseStep3Slice";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import "react-datepicker/dist/react-datepicker.css";
import { addPropertyDetails } from "app/views/home-purchase/HomePurchaseStep3/homepurchase.service";
import PurchasePrice from "../../../Tab2/components/PurchasePrice";
import { getSessionUserInformation } from "app/utils/utility.service";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";

const initialState = {
  userId: "",
  purchasePrice: "",
  downPayment: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  state: "",
  country: "",
  zipcode: "",
  yearBuilt: "",
  typeOfBuilding: "",
  description: "",
  occupancy: "",
  category: "",
  portfolioType: "",
};

const countries = [
  { name: "Australia", code: "AU" },
  { name: "Brazil", code: "BR" },
  { name: "China", code: "CN" },
  { name: "Egypt", code: "EG" },
  { name: "France", code: "FR" },
  { name: "Germany", code: "DE" },
  { name: "India", code: "IN" },
  { name: "Japan", code: "JP" },
  { name: "Spain", code: "ES" },
  { name: "United States", code: "US" },
];

const AddPropertyInformation = ({
  purchasePriceInfo,
  propertyInformationModal,
  handlePropertyInformationModal,
  assumptionsInput,
  mortgageMonthlyExpenses,
}) => {
  const sessionValues = getSessionUserInformation();
  const [values, setValues] = useState(initialState);

  const toast = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const onHide = () => {
    setFormSubmitted(false);
    handlePropertyInformationModal(false);
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const currentYear = 1900;
  const years = Array.from({ length: 200 }, (_, i) => ({
    label: `${currentYear + i}`,
    value: currentYear + i+"",
  }));
  const navigate = useNavigate();
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const validateInput = () => {
    if (
      !values.addressOne ||
      !values.city ||
      !values.state ||
      !values.country ||
      !values.zipcode ||
      !values.yearBuilt ||
      !values.typeOfBuilding ||
      !values.description ||
      !values.occupancy ||
      !values.category
    ) {
      return false;
    }

    // if(!recordId) {
    //     if(!values.password) {
    //         return false;
    //     }
    // }

    if (isNaN(values.zipcode)) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    let type = "";
    if (window.location.hash.indexOf("/step3") > 1) {
      type = "property";
    } else {
      type = "investment";
    }

    if (type == "investment") {
      setValues({
        ...values,
        mortgage: purchasePriceInfo?.mortgage,
        mortgagePayments: purchasePriceInfo?.monthlyMortgage,
        rateOfInterest: purchasePriceInfo?.interestRate,
        amortization: assumptionsInput?.amortization,
        hoa: purchasePriceInfo?.expenses_hoa,
        propertyTaxes: purchasePriceInfo?.propertyTaxes,
        insurance: purchasePriceInfo?.expenses_insurance,
        downPayment: purchasePriceInfo?.downPayment,
        purchasePrice: purchasePriceInfo?.purchasePrice,
        userId: sessionValues.id,
        country: countries[9].name, //Set default to US
        portfolioType: type,
      });
    } else {
      setValues({
        ...values,
        mortgagePayments:
          mortgageMonthlyExpenses?.firstPayment +
          mortgageMonthlyExpenses?.secondPayment,
        downPayment: sessionValues.downPayment,
        purchasePrice: sessionValues.purchasePrice,
        userId: sessionValues.id,
        country: countries[9].name, //Set default to US
        portfolioType: type,
      });
    }
  }, [sessionValues?.purchasePrice]);
  const handleDateChange = (date) => {
    let dateOnly = new Date(
      Date.UTC(
        date.value.getFullYear(),
        date.value.getMonth(),
        date.value.getDate()
      )
    );
    setValues({
      ...values,
      yearBuilt: dateOnly, // Update only the date field
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    // Validate if the input is not empty
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validateInput()) {
      //   showError("Please fill in all required fields correctly.");

      return false;
    }
    const details = await addPropertyDetails(values);
    if (details) {
      showSuccess("Record updated successfully");
      // insert settings value
      setIsLoading(false);
      setTimeout(() => {
        navigate("/home-purchase/portfolio/realestate");
      }, 1000);
    } else {
      showError("Error");
    }
  };
  const renderFooter = () => {
    return (
      <div>
        <div className="grid">
          <div className="col-3"></div>
          <div
            className={`${
              isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"
            }`}
          >
            <Button
              label="Cancel"
              className="p-button-raised p-button-lg"
              onClick={() => onHide()}
            />
            <Button
              type="submit"
              label="Add"
              className={`p-button-raised p-button-warning p-button-lg  ${
                isDesktopOrLaptop ? "" : "ml-4"
              }`}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header="Add Property Information"
      visible={propertyInformationModal}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "35vw" }}
      footer={renderFooter()}
      className="AnnualExpenseModal"
      position="top"
      closeOnEscape={false}
      draggable={false}
    >
      <div className="center-align">
        {isLoading && <Loading />}
        <Toast ref={toast} className="ToastMessage" />
        <div className="AddPropertyForm">
          <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
            <div className="grid">
              {/* Address 1 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Address 1</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      autoFocus
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="addressOne"
                      value={values?.addressOne}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.addressOne && (
                      <small className="p-error">Please enter address1</small>
                    )}
                  </div>
                </div>
              </div>
              {/* Address 2 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Address 2</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      name="addressTwo"
                      value={values?.addressTwo}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              {/* City */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">City</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="city"
                      value={values?.city}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.city && (
                      <small className="p-error">Please enter city</small>
                    )}
                  </div>
                </div>
              </div>

              {/* State */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">State</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="state"
                      value={values?.state}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.state && (
                      <small className="p-error">Please enter state</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Country */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Country</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <Dropdown
                        className="vw-dropdown zipcode-dropdown"
                        value={values?.country}
                        options={countries}
                        required={true}
                        name="country"
                        onChange={handleInputChange}
                        optionLabel="name"
                        optionValue="name"
                        style={{
                          border: "1px solid #ced4da", // Customize the border color and width
                          borderRadius: "3px", // Optional: round the corners
                          padding: "5px", // Optional: add padding for spacing
                        }}
                        // placeholder="Select a Country"
                      />
                    </span>
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.country && (
                      <small className="p-error">Please enter country</small>
                    )}
                  </div>
                </div>
              </div>

              {/* ZIP Code */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">ZIP Code</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="zipcode"
                      value={values?.zipcode}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.zipcode && (
                      <small className="p-error">Please enter ZIP Code</small>
                    )}
                    {formSubmitted && isNaN(values?.zipcode) && (
                      <small className="p-error">
                        Zip code must be in numeric format
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* ZIP Code */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Year Built</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <Dropdown
                      className=" vw-dropdown  vw-dropdown-with-border"
                      id="yearBuilt"
                      name="yearBuilt"
                      value={values?.yearBuilt}
                      onChange={handleInputChange}
                      options={years}
                      placeholder="Select a Year"
                      style={{
                        border: "1px solid #ced4da", // Customize the border color and width
                        borderRadius: "3px", // Optional: round the corners
                        padding: "5px", // Optional: add padding for spacing
                        height: "3.5rem",
                      }}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.yearBuilt && (
                      <small className="p-error">Please enter year built</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Type of Building*/}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Type of Building</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="typeOfBuilding"
                      value={values?.typeOfBuilding}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.typeOfBuilding && (
                      <small className="p-error">
                        Please enter type of Building
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Description */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Description</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="description"
                      value={values?.description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.description && (
                      <small className="p-error">
                        Please enter Description
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Occupancy */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Occupancy</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="occupancy"
                      value={values?.occupancy}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.occupancy && (
                      <small className="p-error">Please enter Occupancy</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Category */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Category</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="category"
                      value={values?.category}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.category && (
                      <small className="p-error">Please enter Category</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddPropertyInformation;
