import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const getComponentList = async () => {
    const result = await restClient.get(`${BASE_URL}/components`);
    return result?.data; 
}
export const addComponent = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/components/create`, payload);
    return result; 
}
export const updateComponent = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/components/${id}`, payload);
    return result; 
}

export const getSingleComponent = async (id) => {
    const result = await restClient.get(`${BASE_URL}/components/${id}`);
    return result?.data; 
}
export const deleteComponent = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/components/${id}`);
    return result?.data; 
}
export const addUserComponent = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/usercomponents/create`, payload);
    return result; 
}
export const updateUserComponent = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/usercomponents/${id}`, payload);
    return result; 
}

export const getSingleUserComponent = async (id) => {
    const result = await restClient.get(`${BASE_URL}/usercomponents/${id}`);
    return result?.data; 
}
export const deleteUserComponent = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/usercomponents/${id}`);
    return result?.data; 
}
export const getUserComponentList = async (propertyId) => {
    const result = await restClient.get(`${BASE_URL}/usercomponents?property_id=${propertyId}`);
    return result?.data; 
}