import { NavLink, Route, Routes, useSearchParams } from "react-router-dom";
import PropertyConfiguration from "./PropertyConfiguration/PropertyConfiguration";
import PropertyPayments from "./PropertyPayments/PropertyPayments";
import PropertyInformation from "./PropertyInformation/PropertyInformation";
import { getSinglePropertyDetails } from "../../homepurchase.service";
import { useEffect, useState } from "react";
import { getUserSettings } from "app/views/sessions/SessionSlice";
import {
  getAnnualExpenseCalculation,
  getMortgageStructureExpensesCalculations,
} from "../../HomePurchaseStep3Slice";
import { useSelector } from "react-redux";
import { Loading } from "app/components";
import moment from "moment";

const PropertyDetails = () => {
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("clientid");
  const propertyId = searchParams.get("propertyId");
  const [isLoading, setIsLoading] = useState(false);
  const userSettings = useSelector(getUserSettings);
  const index = searchParams.get("index");
  const annualExpenseValues = useSelector(getAnnualExpenseCalculation);
  const mortgageMonthlyExpenses = useSelector(
    getMortgageStructureExpensesCalculations
  );

  const initialState = {
    id: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    yearBuilt: "",
    type: "",
    description: "",
    occupancy: "",
    category: "",
    firstReset: "",
    rateCap: "",
    maxRate: "",
    createdAt: "",
  };
  const [values, setValues] = useState(initialState);
  const options = [
    { name: "Fixed", code: "FI" },
    { name: "Adjustable", code: "AD" },
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    const { name, value } = date.target;
    let dateOnly = new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate()
      )
    );
    setValues({
      ...values,
      [name]: dateOnly, // Update only the date field
    });
  };
  useEffect(() => {
    fetchProperty();
  }, []);

  const fetchProperty = async () => {
    setIsLoading(true);
    let record = await getSinglePropertyDetails(propertyId);
    setValues(record);
    //For the first time, get it from mortgage screen

    if (record.createdAt == null || record.createdAt === "")
      setValues((values) => ({
        ...values,
        createdAt: moment(record.createdAt).format("MM/DD/YYYY"),
      }));
    if (record.homeWarranty == null || record.homeWarranty === "")
      if (record.portfolioType === "property")
        setValues((values) => ({
          ...values,
          homeWarranty: annualExpenseValues.homeWarranty,
        }));
    if (record.mortgage == null || record.mortgage === "")
      setValues((values) => ({
        ...values,
        mortgage: mortgageMonthlyExpenses.firstLoanPrincipalAmt,
      }));
    if (record.rateOfInterest == null || record.rateOfInterest === "")
      setValues((values) => ({
        ...values,
        rateOfInterest: mortgageMonthlyExpenses.firstLoanInterestRate,
      }));
    if (record.mortgagePayments == null || record.mortgagePayments === "") {
      setValues((values) => ({
        ...values,
        mortgagePayments:
          mortgageMonthlyExpenses.firstPayment +
          mortgageMonthlyExpenses.secondPayment,
      }));
    }
    if (record.hoa == null || record.hoa === "")
      setValues((values) => ({
        ...values,
        hoa: mortgageMonthlyExpenses.hoa,
      }));
    if (record.propertyTaxes == null || record.propertyTaxes === "")
      setValues((values) => ({
        ...values,
        propertyTaxes: annualExpenseValues.dedPropertyTax,
      }));
    if (record.insurance == null || record.insurance === "")
      setValues((values) => ({
        ...values,
        insurance: annualExpenseValues.insurance,
      }));
    if (record.amortization == null || record.amortization === "")
      setValues((values) => ({
        ...values,
        amortization: userSettings.amortizationYears,
      }));
    if (record.secondLoanInterestRate == null || record.secondLoanInterestRate === "")
      setValues((values) => ({
        ...values,
        secondLoanInterestRate:mortgageMonthlyExpenses.secondLoanInterestRate,
      }));
      if (record.secondLoanPrincipalAmt == null || record.secondLoanPrincipalAmt === "")
        setValues((values) => ({
          ...values,
          secondLoanPrincipalAmt: mortgageMonthlyExpenses.secondLoanPrincipalAmt,
        }));
      if (record.rateOfInterestType == null || record.rateOfInterestType === "")
        setValues((values) => ({
          ...values,
          rateOfInterestType: options[0].name,
        }));
    if (!!record) setIsLoading(false);
  };

  return (
    <div className="card">
      {isLoading && <Loading />}
      <nav className="navbarcls">
        <div className="container">
          <div className={`nav-elements `}>
            {!clientId || clientId === "null" ? (
              <ul style={{ padding: "0.2rem" }}>
                <li>
                  <NavLink
                    to={`/home-purchase/portfolio/propertydetails/propertyinfo/?propertyId=${propertyId}&index=${index}`}
                  >
                    Property Information
                  </NavLink>
                </li>
                <li>
                  <NavLink style={{ width: "165px"}}
                    to={`/home-purchase/portfolio/propertydetails/propertyconfig/?propertyId=${propertyId}&index=${index}`}
                  >
                    Property Configuration
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/home-purchase/portfolio/propertydetails/propertypayments/?propertyId=${propertyId}&index=${index}`}
                  >
                    Schedule Payments
                  </NavLink>
                </li>
              </ul>
            ) : (
              <ul style={{ padding: "0.2rem" }}>
                <li>
                  <NavLink
                    to={`/home-purchase/portfolio/propertydetails/propertyinfo/?clientid=${clientId}`}
                  >
                    Property Information
                  </NavLink>
                </li>
                <li>
                  <NavLink style={{ width: "165px"}}
                    to={`/home-purchase/portfolio/propertydetails/propertyconfig/?clientid=${clientId}`}
                  >
                    Property Configuration
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/home-purchase/portfolio/propertydetails/propertypayments/?clientid=${clientId}`}
                  >
                    Schedule Payments
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
      <Routes>
        <Route
          path="/propertyinfo"
          element={
            <PropertyInformation
              handleInputChange={handleInputChange}
              handleDateChange={handleDateChange}
              values={values}
            />
          }
        />
        <Route path="/propertyconfig" element={<PropertyConfiguration addressOne={values?.addressOne} />} />
        <Route
          path="/propertypayments"
          element={
            <PropertyPayments
              handleInputChange={handleInputChange}
              handleDateChange={handleDateChange}
              values={values}
              options={options}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default PropertyDetails;
