import { getSessionUserInformation } from "app/utils/utility.service";
import { getRentOwnedEstateRecords } from "app/utils/utility.service";
import { getSessionUserSettings } from "app/utils/utility.service";
import { FormatAmount } from "app/utils/utility.service";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { insertLogs } from "../../../homepurchase.service";
import {
    changeLeftCalculations,
    changeMonthlyMortgageExpenses,
    getExpenses,
    getLeftCalculations,
    getMortgageStructureExpensesCalculations,
} from "../../../HomePurchaseStep3Slice";
import AnnualExpense from "./AnnualExpense/AnnualExpense";
import MonthlyExpense from "./MonthlyExpense/MonthlyExpense";
import RentalEstateGraphModal from "./RentalEstateGraphModal";
import { FIRST_LOAN_RATE } from "app/utils/Constants";
import { useMediaQuery } from "react-responsive";
import AddPropertyInformation from "./PropertyInformation/AddPropertyInformation";


const OwnHouse = ({
    handleLeftCalculations,
    handleExpensesSave,
    handleOwnHouseSave,
    mortgageStructureModal,
    handleMortgageStructureModal,
    taxBreakModal,
    handleTaxBreakModal,
    handleReportChangeSave
}) => {
    const [monthlyExpenseModal, setMonthlyExpenseModal] = useState(false);
    const [annualExpenseModal, setAnnualExpenseModal] = useState(false);
    const [rentalEstateModal, setRentalEstateModal] = useState(false);
    const [propertyInformationModal, setPropertyInformationModal] = useState(false);  
    const [graphRecord, setGraphRecord] = useState(null);
    const expensesRecord = useSelector(getExpenses);
    const userSettings = getSessionUserSettings();
    const userRecord = getSessionUserInformation();
    const dispatch = useDispatch();
    const leftContentValues = useSelector(getLeftCalculations);
    const mortgageMonthlyExpenses = useSelector(
        getMortgageStructureExpensesCalculations
    );

    //const [searchParams] = useSearchParams();
   // const clientId = searchParams.get("clientid");
    const {state} = useLocation();
	const clientId  = state?.clientid; 
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const isTablet = useMediaQuery({
        query: "(min-width: 768px) and (max-width: 1024px)",
    });
    const prefix = "$ ";
    const handleMonthlyExpenseModal = () => {
        if (!monthlyExpenseModal) {
            const payload = {
                description: "View Monthly Expenses",
            };
            insertLogs(payload);
        }
        setMonthlyExpenseModal(!monthlyExpenseModal);
    };

    const handleAnnualExpenseModal = () => {
        if (!annualExpenseModal) {
            const payload = {
                description: "View Annual Expenses",
            };
            insertLogs(payload);
        }
        setAnnualExpenseModal(!annualExpenseModal);
    };

    const handlePropertyInformationModal = () => {
        if (!propertyInformationModal) {
            if(clientId && clientId !== "null") {
                handleReportChangeSave(clientId, false)
            } else {
                handleReportChangeSave(null, false)

            }
            const payload = {
                description: "Add Property Information",
            };
            insertLogs(payload);
        }
        if(leftContentValues?.purchasePrice)
        setPropertyInformationModal(!propertyInformationModal);
    };

    const handleRentalEstateGraphModal = () => {
        setGraphRecord(null);
        if (!rentalEstateModal) {
            const fetchedRecord = getRentOwnedEstateRecords(
                userRecord,
                expensesRecord,
                userSettings
            );
            setGraphRecord(fetchedRecord);
            // const payload = {
            //     description: "View Rental / Owned Estate Graph",
            // };
            // insertLogs(payload);
        }
        setRentalEstateModal(!rentalEstateModal);
    };

    // const handleMortgageStructureModal = () => {
    //     setMortgageStructureModal(!mortgageStructureModal);
    // };

    // const renderMortgageStructureModal = () => (
    //     <MortgageStructure
    //         mortgageStructureModal={mortgageStructureModal}
    //         handleMortgageStructureModal={handleMortgageStructureModal}
    //         handleExpensesSave={handleExpensesSave}
    //     />
    // );

    const renderMonthlyExpenseModal = () => (
        <MonthlyExpense
            monthlyExpenseModal={monthlyExpenseModal}
            handleMonthlyExpenseModal={handleMonthlyExpenseModal}
            handleExpensesSave={handleExpensesSave}
            mortgageStructureModal={mortgageStructureModal}
            handleMortgageStructureModal={handleMortgageStructureModal}
            taxBreakModal={taxBreakModal}
            handleTaxBreakModal={handleTaxBreakModal}
        />
    );

    const renderAnnualExpenseModal = () => (
        <AnnualExpense
            annualExpenseModal={annualExpenseModal}
            handleAnnualExpenseModal={handleAnnualExpenseModal}
            handleExpensesSave={handleExpensesSave}
        />
    );

    const renderPropertyInformationModal = () => (
        <AddPropertyInformation
        mortgageMonthlyExpenses={mortgageMonthlyExpenses}
        propertyInformationModal={propertyInformationModal}
        handlePropertyInformationModal={handlePropertyInformationModal}
        
        />
    );

    const renderRentalEstateGraphModal = () => (
        <RentalEstateGraphModal
            rentalEstateModal={rentalEstateModal}
            handleRentalEstateGraphModal={handleRentalEstateGraphModal}
            graphRecords={graphRecord}
        />
    );

    const renderFooter = () => {
        return (
            <div>
                <div className="grid">
                    {isDesktopOrLaptop && <div className="col-2"></div>}
                    <div
                        className={`${
                            isDesktopOrLaptop
                                ? "col-10"
                                : "col-12 text-align-center"
                        }`}
                    >
                        <div>
                            {/* <Button
								label="Cancel"
								className="p-button-raised p-button p-button-lg"
								// onClick={() => onHide()}
							/> */}
                            {/* <Button
								label="Save"
								className="p-button-raised p-button-warning p-button-lg"
								onClick={handleOwnHouseSave}
							/> */}

                            <div
                                className={` ${
                                    isDesktopOrLaptop ? "mt-2 mb-4" : "mt-5"
                                }`}
                            >
                                {clientId && clientId !== "null" ? (
                                    <Fragment>
                                        <a
                                            href="javascript:;"
                                            onClick={()=>handleReportChangeSave(clientId, true, 'home-ownership')}
                                            className={`${
                                                isDesktopOrLaptop
                                                    ? "mr-5"
                                                    : "mr-4"
                                            }`}
                                        >
                                            Home Ownership Design
                                        </a>
                                        <a
                                            href="javascript:;"
                                            onClick={()=>handleReportChangeSave(clientId, true, 'home-value')}
                                            className={`${
                                                isDesktopOrLaptop
                                                    ? "mr-5"
                                                    : "mr-4"
                                            }`}
                                        >
                                            Home Value Projection
                                        </a>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <a
                                            href="javascript:;"
                                            onClick={()=>handleReportChangeSave(null, true,'home-ownership')}
                                            className={`${
                                                isDesktopOrLaptop
                                                    ? "mr-5"
                                                    : "mr-3"
                                            }`}
                                        >
                                            Home Ownership Design
                                        </a>
                                        <a
                                            href="javascript:;"
                                            onClick={()=>handleReportChangeSave(null, true,'home-value')}
                                            className={`${
                                                isDesktopOrLaptop
                                                    ? "mr-5"
                                                    : "mr-3"
                                            }`}
                                        >
                                            Home Value Projection
                                        </a>
                                        <div className="mt-4"></div>
                                        {!isDesktopOrLaptop &&
                                        <Link
                                            to="/home-purchase/investment/analysis"
                                            className={`${
                                                isDesktopOrLaptop
                                                    ? "mr-5"
                                                    : "mr-3"
                                            }`}
                                        >
                                            Investment
                                        </Link>
                                        }
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const headerTemplate = (options) => {
        const className = `${options.className} p-d-flex justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;
        return (
            <Fragment>
                <div className={className}>
                    <span className={titleClassName}>Ownsership Design</span>
                    {/* <div>
                        <label
                            style={{ color: "#333", marginRight: "1rem" }}
                            className={`${options.titleClassName}`}
                        >
                            <a
                                href="javascript:;"
                                onClick={handleRentalEstateGraphModal}
                                style={{ fontWeight: "500" }}
                            >
                                Rental v/s Owned Real Estate
                            </a>
                        </label>
                    </div> */}
                    <div>
                        <label
                            style={{ color: "#333" }}
                            className={`${options.titleClassName}`}
                        >
                            Own&nbsp;-&nbsp;
                        </label>
                        <label
                            style={{ color: "#333" }}
                            className={`${options.titleClassName}`}
                        >
                            {`$${FormatAmount(
                                leftContentValues?.totalMonthlyExpense
                            )}`}
                        </label>
                    </div>
                    {/* <Button
                            style={{ height: "3rem" }}
                            label={`$ ${FormatAmount(
                                leftContentValues?.totalMonthlyExpense
                            )}`}
                            className="p-button-raised p-button-warning p-button-lg ml-4"
                        /> */}
                </div>
            </Fragment>
        );
    };
    const pp = (
        <Fragment>
            <div className="col-12 md:col-12 lg:col-2">
                <label
                    className={`${
                        isDesktopOrLaptop ? "mt-3 margin-pp nowrap" : ""
                    }`}
                >
                    Purchase Price
                </label>
            </div>
            <div className="col-12 md:col-11 lg:col-4">
                <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                    <div className="p-inputgroup">
                        <InputNumber
                            className="vw-inputnumber-with-border"
                            autoComplete="off"
                            required={true}
                            name="purchasePrice"
                            disabled={false}
                            useGrouping={true}
                            prefix="$ "
                            min={0}
                            onValueChange={(e) => {
                                dispatch(
                                    changeLeftCalculations({
                                        name: "purchasePrice",
                                        value: e.value,
                                    })
                                );
                                handleLeftCalculations(
                                    "purchasePrice",
                                    e.value
                                );
                            }}
                            value={leftContentValues?.purchasePrice}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );

    const interest = (
        <Fragment>
            <div
                className={`col-12 md:col-12 lg:col-2 ${
                    isTablet ? "ml-6" : ""
                }`}
            >
                <label className={`${isDesktopOrLaptop ? "mt-3" : ""}`}>
                    Interest Rate
                </label>
            </div>
            <div
                className={`col-12 md:col-11 lg:col-4 ${
                    isTablet ? "ml-6" : ""
                }`}
            >
                <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                    <div className="p-inputgroup">
                        <InputNumber
                            className="vw-inputnumber-with-border"
                            autoComplete="off"
                            required={true}
                            name="interestRate"
                            placeholder="Interest Rate..."
                            disabled={false}
                            suffix=" %"
                            min={1.0}
                            max={99.999}
                            minFractionDigits={1}
                            useGrouping={false}
                            value={
                                mortgageMonthlyExpenses?.firstLoanInterestRate
                            }
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    dispatch(
                                        changeMonthlyMortgageExpenses({
                                            name: "firstLoanInterestRate",
                                            value: FIRST_LOAN_RATE,
                                        })
                                    );
                                } else {
                                    let interestRate = e.target.value.replace(
                                        "%",
                                        ""
                                    );
                                    if (interestRate < 1) {
                                        alert(
                                            "Interest Rate must be greater than 0"
                                        );

                                        // dispatch(
                                        //     changeMonthlyMortgageExpenses({
                                        //         name: "firstLoanInterestRate",
                                        //         value: 0,
                                        //     })
                                        // );
                                        return;
                                    }
                                    if (interestRate > 99.999) {
                                        alert(
                                            "Interest Rate must be lesser than 100"
                                        );
                                        // dispatch(
                                        //     changeMonthlyMortgageExpenses({
                                        //         name: "firstLoanInterestRate",
                                        //         value: 0,
                                        //     })
                                        // );
                                        return;
                                    } else {
                                        dispatch(
                                            changeMonthlyMortgageExpenses({
                                                name: "firstLoanInterestRate",
                                                value: interestRate,
                                            })
                                        );
                                    }
                                }
                            }}
                            onValueChange={(e) => {
                                dispatch(
                                    changeMonthlyMortgageExpenses({
                                        name: "firstLoanInterestRate",
                                        value: e.target.value,
                                    })
                                );
                                // if(!e.target.value) {
                                //     dispatch(
                                //         changeMonthlyMortgageExpenses({
                                //             name: "firstLoanInterestRate",
                                //             value: FIRST_LOAN_RATE,
                                //         })
                                //     );
                                // } else {
                                //     console.log(e.target.value);
                                //     if(e.target.value > 99) {
                                //         alert("Interest Rate must be lesser than 100");
                                //         dispatch(
                                //             changeMonthlyMortgageExpenses({
                                //                 name: "firstLoanInterestRate",
                                //                 value: FIRST_LOAN_RATE,
                                //             })
                                //         );
                                //     } else {
                                //         dispatch(
                                //             changeMonthlyMortgageExpenses({
                                //                 name: "firstLoanInterestRate",
                                //                 value: e.target.value,
                                //             })
                                //         );
                                //     }
                                // }
                            }}
                            // onBlur={(e) => {
                            //     dispatch(
                            //         changeMonthlyMortgageExpenses({
                            //             name: "firstLoanInterestRate",
                            //             value: !!e.target.value ? e.target.value : FIRST_LOAN_RATE,
                            //         })
                            //     );
                            // }}
                            maxFractionDigits={3}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );

    const downpaymentpercent = (
        <Fragment>
            <div className="col-12 md:col-12 lg:col-2">
                <label
                    className={`${
                        isDesktopOrLaptop ? "mt-3 margin-pp nowrap" : ""
                    }`}
                >
                    Down Payment
                </label>
            </div>
            <div className="col-12 md:col-11 lg:col-4">
                <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                    <div className="p-inputgroup">
                        <InputNumber
                            className="vw-inputnumber-with-border"
                            autoComplete="off"
                            required={true}
                            name="downPaymentPercent"
                            disabled={false}
                            suffix=" %"
                            min={3.5}
                            max={100}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={leftContentValues?.downPaymentPercent}
                            onValueChange={(e) => {
                                dispatch(
                                    changeLeftCalculations({
                                        name: "downPaymentPercent",
                                        value: e.target.value,
                                    })
                                );
                                // if (e.target.value) {
                                //     handleLeftCalculations(
                                //         "downPaymentPercent",
                                //         e.target.value
                                //     );
                                // }
                                // handleLeftCalculations(
                                //     "downPaymentPercent",
                                //     e.target.value
                                // );
                            }}
                            onBlur={(e) => {
                                e.target.value = e.target.value.replace(
                                    "%",
                                    ""
                                );
                                if (!e.target.value || e.target.value === "0") {
                                    dispatch(
                                        changeLeftCalculations({
                                            name: "downPaymentPercent",
                                            value: 3.5,
                                        })
                                    );
                                    handleLeftCalculations(
                                        "downPaymentPercent",
                                        3.5
                                    );
                                } else {
                                    handleLeftCalculations(
                                        "downPaymentPercent",
                                        e.target.value
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );

    const downpayment = (
        <Fragment>
            <div className="col-12 md:col-12 lg:col-2 margin-top-dp"></div>

            <div
                className={`col-12 md:col-11 lg:col-4 ${
                    isTablet ? "ml-6" : ""
                }`}
            >
                <div className={`${isDesktopOrLaptop ? "mb-4 field" : ""}`}>
                    <div className="p-inputgroup">
                        {/* <span
                        className="p-input"
                        style={{
                            width: "100%",
                        }}
                    > */}
                        <InputNumber
                            className="vw-inputnumber-with-border"
                            autoComplete="off"
                            required={true}
                            name="downPayment"
                            prefix="$ "
                            disabled={false}
                            // mode="decimal"
                            // minFractionDigits={2}
                            onValueChange={(e) => {
                                dispatch(
                                    changeLeftCalculations({
                                        name: "downPayment",
                                        value: e.target.value,
                                    })
                                );
                                if (e.target.value) {
                                    handleLeftCalculations(
                                        "downPayment",
                                        e.target.value
                                    );
                                } else {
                                    dispatch(
                                        changeLeftCalculations({
                                            name: "downPayment",
                                            value: 0,
                                        })
                                    );
                                    handleLeftCalculations("downPayment", 0);
                                }
                            }}
                            // onBlur={(e) => {
                            //     if (!e.target.value) {
                            //         dispatch(
                            //             changeLeftCalculations({
                            //                 name: "downPayment",
                            //                 value: 3.5,
                            //             })
                            //         );
                            //         handleLeftCalculations(
                            //             "downPayment",
                            //             3.5
                            //         );
                            //     } else {
                            //         handleLeftCalculations(
                            //             "downPayment",
                            //             e.target.value
                            //         );
                            //     }
                            // }}
                            value={leftContentValues?.downPayment}
                            // value={
                            //     leftContentValues?.downPayment
                            //         ? FormatAmount(
                            //               leftContentValues?.downPayment
                            //           )
                            //         : 0
                            // }
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );

    const monthlyexpense = (
        <Fragment>
            <div className={`col-12 md:col-8 lg:col-2 ${
                    isTablet ? "ml-3" : ""
                }`}>
                <label
                    className={`${
                        isDesktopOrLaptop ? "mt-3 nowrap margin-me" : ""
                    } ${
                        isTablet ? "ml-6" : "" 
                    }`}
                >
                    Monthly Expenses
                </label>
            </div>
            <div className={`col-12 md:col-11 lg:col-10 ${
                    isTablet ? "ml-6" : ""
                }`}>
                <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                    <div
                        className={`${
                            isTablet ? "grid" : "flex-cls"
                        }`}
                    >
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="monthlyExpenses"
                                disabled={true}
                                value={
                                    leftContentValues?.monthlyExpense
                                        ? prefix +
                                          FormatAmount(
                                              leftContentValues?.monthlyExpense
                                          )
                                        : ""
                                }
                            />
                        </div>
                        <div className="col-2 md:col-1 lg:col-1">
                            <a
                                href="javascript:;"
                                className=""
                                onClick={handleMonthlyExpenseModal}
                            >
                                <i className="pi pi-pencil" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    const equityValue = (
        <Fragment>
            <div className="col-12 md:col-8 lg:col-2">
                <label
                    className={`${
                        isDesktopOrLaptop ? "mt-3 nowrap margin-me" : "" 
                    } ${
                        isTablet ? "ml-6" : "" 
                    }`}
                >
                    Equity
                </label>
            </div>
            <div className="col-12 md:col-11 lg:col-10">
                <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                    <div
                        className={`${
                            isTablet ? "grid ml-6" : "flex-cls"
                        }`}
                    >
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="equity"
                                disabled={true}
                                value={
                                    leftContentValues?.equity
                                        ? prefix +
                                          FormatAmount(
                                              Number(leftContentValues?.equity).toFixed()
                                          )
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    const annualexpenses = (
        <Fragment>
            <div
                className={`col-12 md:col-8 lg:col-2 ${
                    isTablet ? "ml-3" : ""
                }`}
            >
                <label
                    className={`${
                        isDesktopOrLaptop ? "mt-3 nowrap margin-me" : ""
                    } ${
                        isTablet ? "ml-6" : "" 
                    }`}
                >
                    Annual Expenses
                    <br />
                    {isDesktopOrLaptop && (
                        <Fragment>(Prorated Monthly)</Fragment>
                    )}
                </label>
            </div>
            <div
                className={`col-12 md:col-11 lg:col-10 ${
                    isTablet ? "ml-6" : ""
                }`}
            >
                <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                    <div
                        className={`${
                            isTablet ? "grid" : "flex-cls"
                        }`}
                    >
                        <div className="p-inputgroup">
                            <InputText
                                className="vw-input-nfl"
                                autoComplete="off"
                                required={true}
                                name="proratedMonthlyExpense"
                                disabled={true}
                                value={
                                    leftContentValues?.proratedMonthlyExpense
                                        ? prefix +
                                          FormatAmount(
                                              leftContentValues?.proratedMonthlyExpense
                                          )
                                        : ""
                                }
                            />
                        </div>

                        <div className="col-2 md:col-1 lg:col-1">
                            <a
                                href="javascript:;"
                                className=""
                                onClick={handleAnnualExpenseModal}
                            >
                                <i
                                    className={`pi pi-pencil ${
                                        isDesktopOrLaptop ? "" : "ml-1 "
                                    }`}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    const totalMonthlyExpense = (
        <Fragment>
            <div className="col-12 md:col-8 lg:col-2">
                <label
                    className={`${
                        isDesktopOrLaptop ? "mt-3 nowrap margin-me" : ""
                    }`}
                >
                    Total Monthly Expenses
                </label>
            </div>
            <div className="col-12 md:col-8 lg:col-5">
                <div className={`${isDesktopOrLaptop ? "field" : ""}`}>
                    <div className="p-inputgroup">
                        <InputText
                            className="vw-input-nfl"
                            prefix="$ "
                            autoComplete="off"
                            required={true}
                            name="totalMonthlyExpense"
                            disabled={true}
                            value={
                                leftContentValues?.totalMonthlyExpense
                                    ? prefix +
                                      FormatAmount(
                                          leftContentValues?.totalMonthlyExpense
                                      )
                                    : ""
                            }
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            <Panel
                headerTemplate={headerTemplate}
                className={`mb-2 OwnHouse ${isTablet ? "mt-3" : ""}`}
            >
                <div
                    className={`${
                        isDesktopOrLaptop ? "align-text" : "align-text-left"
                    }`}
                >
                    <div className="grid">
                        {isDesktopOrLaptop ? (
                            <Fragment>{pp}</Fragment>
                        ) : (
                            <Fragment>
                                <div
                                    className={`${isTablet ? "w-50 mt-2" : ""}`}
                                >
                                    {pp}
                                </div>
                                <div className={`${isTablet ? "w-50" : ""}`}>
                                    {interest}
                                </div>
                            </Fragment>
                        )}
                        {isDesktopOrLaptop ? (
                            <Fragment>{interest}</Fragment>
                        ) : null}
                    </div>
                    <div className="grid">
                        {isDesktopOrLaptop ? (
                            <Fragment>{downpaymentpercent}</Fragment>
                        ) : (
                            <Fragment>
                                <div className={`${isTablet ? "w-50" : ""}`}>
                                    {downpaymentpercent}
                                </div>
                                <div className={`${isTablet ? "w-50" : ""}`}>
                                    {downpayment}
                                </div>
                            </Fragment>
                        )}
                        {isDesktopOrLaptop ? (
                            <Fragment>{downpayment}</Fragment>
                        ) : null}
                    </div>
                    {/* Monthly Expenses */}

                    {isDesktopOrLaptop ? (
                        <Fragment>
                            <div className="grid">{equityValue}</div>
                            <div className="grid">{monthlyexpense}</div>
                            <div className="grid">{annualexpenses}</div>
                        </Fragment>
                    ) : (
                        <Fragment>
                        {isTablet ? (
                            <Fragment>
                                <div className={`${isTablet ? "" : ""}`}>
                                    {equityValue}
                                </div>
                                <div className={`${isTablet ? "" : ""}`}>
                                    {monthlyexpense}
                                </div>
                                <div className={`${isTablet ? "" : ""}`}>
                                    {annualexpenses}
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                            <div className={`${isTablet ? "w-50" : ""}`}>
                                {equityValue}
                            </div>
                            <div className={`${isTablet ? "w-50" : ""}`}>
                                {monthlyexpense}
                            </div>
                            <div className={`${isTablet ? "w-50" : ""}`}>
                                {annualexpenses}
                            </div>
                            </Fragment>
                        )}
                        </Fragment>
                    )}
                    {/* Annual Expenses */}
                    {/* Total Monthly Expenses */}
                    <div className="grid">
                        {isDesktopOrLaptop ? (
                            <Fragment>{totalMonthlyExpense}</Fragment>
                        ) : (
                            <div
                                className={`${
                                    isTablet
                                        ? "w-50 margin-3"
                                        : "totalexpense-width"
                                }`}
                            >
                                {totalMonthlyExpense}
                            </div>
                        )}
                        <div
                            className={`col-2 md:col-6 lg:col-6 ${
                                isTablet ? "text-align-center" : "text-align-left"
                            }`}
                        >
                            <Button
                                label="Save"
                                className={`p-button-raised p-button-warning ${
                                    isDesktopOrLaptop
                                        ? "p-button-lg margin-rt-button"
                                        : "margin-top-button save-width"
                                }`}
                                onClick={handleOwnHouseSave}
                            />
                            <Button
                                label="Add"
                                className={`p-button-raised p-button-warning ${
                                    isDesktopOrLaptop
                                        ? "p-button-lg"
                                        : "margin-top-button save-width"
                                }`}
                                onClick={handlePropertyInformationModal}
                            />
                        </div>
                    </div>
                </div>
                {renderFooter()}
            </Panel>
            {renderMonthlyExpenseModal()}
            {renderAnnualExpenseModal()}
            {renderPropertyInformationModal()}
            {/* {renderRentalEstateGraphModal()} */}
            {/* {renderMortgageStructureModal()} */}
        </Fragment>
    );
};

export default OwnHouse;
