import { Card } from "primereact/card";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Tab1 from "../RightContent/Tab1/Tab1";
import Tab2 from "../RightContent/Tab2/Tab2";
import Tab3 from "../RightContent/Tab3/Tab3";

const LeftContent = ({ handleLeftCalculations, mortgageMonthlyExpenses }) => {
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("clientid");
  const navigate = useNavigate();
  const types = ["Home Ownership", "Investment"];
  const portfoilios = [
    "Real Estate",
    "Financials",
    "Commodities",
    "Cryptocurrency",
  ];
  const transactions = ["Permit and Work History", "Payments"];
  const [active, setActive] = useState(null);
  const { pathname } = useLocation();
  const gotoTab = (type) => {
    if (!clientId || clientId === "null") {
      if (type === "Home Ownership") {
        navigate(`/home-purchase/step3/tab1`);
      } else if (type == "Real Estate") {
        navigate(`/home-purchase/portfolio/realestate`);
      } else if (type == "Investment") {
        navigate(`/home-purchase/investment/analysis`); 
      } else if (type == "Permit and Work History") {
        navigate(`/home-purchase/transactions/workhistory`); 
      }  else if (type == "Payments") {
        navigate(`/home-purchase/transactions/payments`); 
      } 
    } else {
      if (type === "Home Ownership") {
        navigate(`/home-purchase/step3/tab1/?clientid=${clientId}`);
      } else {
        navigate(`/home-purchase/investment/analysis?clientid=${clientId}`);
      }
    }
  };

  useEffect(() => {
    if (pathname.includes("/home-purchase/step3")) {
      setActive(types[0]);
    } else if (pathname.includes("/home-purchase/investment")) {
      setActive(types[1]);
    } else if (
      pathname.includes("/home-purchase/portfolio/realestate") ||
      pathname.includes("/home-purchase/portfolio/propertydetails")
    ) {
      setActive(portfoilios[0]);
    } else if (pathname.includes("/home-purchase/portfolio/propertydetails")) {
      setActive(portfoilios[1]);
    } else if (pathname.includes("/home-purchase/transactions/workhistory")) {
      setActive(transactions[0]);
    } else if (pathname.includes("/home-purchase/transactions/payments")) {
      setActive(transactions[1]);
    }
  }, [pathname]);

  return (
    <Card>
      {/* <div className="tabs">
                <div className="tabs-nav">
                {types.map((type) => (
                    <div
                    className={`${active === type ? "nav-item active" : "nav-item"}`}
                    id={type}
                    key={type}
                    onClick={() => gotoTab(type)}
                    >
                    {type}
                    </div>
                ))}
                </div>
            </div>*/}
      <div>
        {/* Vertical Navigation Tabs */}
        <nav className="tabs">
          <ul>
            <li>
              <NavLink
                to="/home-purchase/step3/tab1"
                className={`${
                  active === "Analyze and Design" ||
                  window.location.hash.indexOf("/home-purchase/step3") > 0 ||
                  window.location.hash.indexOf("/investment") > 1
                    ? "nav-item active"
                    : "nav-item"
                }`}
              >
                Analyze and Design
              </NavLink>
              <div className="tabs">
                <div className="tabs-nav">
                  {types.map((type) => (
                    <div
                      className={`${
                        active === type ? "nav-item active" : "nav-item"
                      }`}
                      id={type}
                      key={type}
                      onClick={() => gotoTab(type)}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/home-purchase/portfolio/realestate"
                className={`${
                  active === "Portfolio" ||
                  window.location.hash.indexOf("/portfolio") > 1
                    ? "nav-item active"
                    : "nav-item"
                }`}
              >
                Portfolio
              </NavLink>
              <div className="tabs">
                <div className="tabs-nav">
                  {portfoilios.map((type) => (
                    <div
                      className={`${
                        active === type ? "nav-item active" : "nav-item"
                      }`}
                      id={type}
                      key={type}
                      onClick={() => gotoTab(type)}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/home-purchase/transactions/workhistory"
                className={`${
                  active === "Transactions" ||
                  window.location.hash.indexOf("/transactions") > 1
                    ? "nav-item active"
                    : "nav-item"
                }`}
                
              >
                Transactions
              </NavLink>
              <div className="tabs">
                <div className="tabs-nav">
                  {transactions.map((type) => (
                    <div
                      className={`${
                        active === type ? "nav-item active" : "nav-item"
                      }`} style={{textTransform:"none"}}
                      id={type}
                      key={type}
                      onClick={() => gotoTab(type)}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <NavLink to="/home-purchase/report/list" 
              className={`${
                window.location.hash.indexOf("/report") > -1 ? "nav-item active" : "nav-item"
              }`}
              onClick={() => gotoTab("Reports")} >
                Reports
              </NavLink>
            </li>
          </ul>
        </nav>

        {/* Content for the selected tab */}
      </div>
    </Card>
  );
};

export default LeftContent;
