import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useRef, useState } from "react";
import LeftContent from "../LeftContent/LeftContent";
import { Loading } from "app/components";
import { Toast } from "primereact/toast";
import { useMediaQuery } from "react-responsive";
import "../../HomePurchase.scss";
import InvestmentAnalysis from "./InvestmentAnalysis/InvestmentAnalysis";
import Assumptions from "./Assumptions/Assumptions";
import {
  ASSUMPTION_CONSTANTS,
  PURCHASE_PRICE_INITIAL_STATE,
} from "./investment_constants";
import {
  getInvestmentAnalysis,
  getUserAssumptions,
  insertAssumptions,
  insertInvestmentAnalysis,
  insertLogs,
  updateAssumptions,
  updateInvestmentAnalysis,
} from "../homepurchase.service";
import { convertSettingToNumber } from "app/utils/utility.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";

import { getCalculatedPercentage } from "app/utils/utility.service";
import { PMT } from "app/utils/utility.service";
import { NavLink, Route, Routes, useSearchParams } from "react-router-dom";
import Ratios from "./Ratios/Ratios";
import { useSelector } from "react-redux";
import { getLeftCalculations } from "../HomePurchaseStep3Slice";

const Investment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [assumptionsInput, setAssumptionsInput] =
    useState(ASSUMPTION_CONSTANTS);
  const [purchasePriceInfo, setPurchasePriceInfo] = useState(
    PURCHASE_PRICE_INITIAL_STATE
  );
  const leftContentValues = useSelector(getLeftCalculations);
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("clientid");

  const toast = useRef();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const sessionValues = getSessionUserInformation();

  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };

  const doInvestmentCalculation = (type) => {
    let purchasePriceValues = { ...purchasePriceInfo };

    const mortgage =
      Number(purchasePriceValues?.purchasePrice) *
      (1 - getCalculatedPercentage(assumptionsInput?.percentageDown));
    const downPayment =
      Number(purchasePriceValues?.purchasePrice) - Number(mortgage);
    const improvement =
      Number(assumptionsInput?.builtUp) *
      Number(assumptionsInput?.constructionCost);
    const land = Number(purchasePriceValues?.purchasePrice) - improvement;
    const interestAmount =
      Math.round(
        Number(mortgage) *
          (getCalculatedPercentage(assumptionsInput?.interestRate) / 12)
      ) * -1;
    const propertyTaxes =
      Number(purchasePriceValues?.purchasePrice) *
      getCalculatedPercentage(assumptionsInput?.propertyTaxRate);
    let maintenance = 0;
    const income_rental =
      Number(purchasePriceInfo?.monthlyRent) *
      12 *
      (1 - getCalculatedPercentage(assumptionsInput?.vacancyFactor));
    //const income_rental = Number(purchasePriceInfo?.monthlyRent)*12;

    if (Number(purchasePriceInfo?.monthlyRent) > 0) {
      maintenance =
        Number(income_rental) *
        getCalculatedPercentage(assumptionsInput?.maintenance);
    }
    const monthlyMortgage =
      Math.round(
        PMT(
          assumptionsInput?.interestRate / 1200, // Annual interest into months
          30 * 12, // Total months for life of loan
          mortgage,
          0,
          0
        ) * -1
      ) * -1;

    // Income and Expenses Calculation
    const income_total = income_rental;
    const expenses_interest =
      Number(purchasePriceValues?.mortgage) *
      getCalculatedPercentage(assumptionsInput?.interestRate);
    const expenses_propertyTax =
      Number(purchasePriceValues?.purchasePrice) *
      getCalculatedPercentage(assumptionsInput?.propertyTaxRate);
    const expenses_maintenance =
      getCalculatedPercentage(assumptionsInput?.maintenance) *
      Number(income_rental);
    const expenses_propertyManagement =
      Number(income_rental) *
      getCalculatedPercentage(assumptionsInput?.propertyManagementFees);
    const hoa = purchasePriceInfo?.expenses_hoa
      ? Number(purchasePriceInfo?.expenses_hoa)
      : 0;
    const insurance = purchasePriceInfo?.expenses_insurance
      ? Number(purchasePriceInfo?.expenses_insurance)
      : 0;
    const utilities = purchasePriceInfo?.expenses_utilities
      ? Number(purchasePriceInfo?.expenses_utilities)
      : 0;

    const expenses_total =
      Number(expenses_interest) +
      Number(expenses_propertyTax) +
      Number(hoa) +
      Number(insurance) +
      Number(expenses_maintenance) +
      Number(expenses_propertyManagement) +
      Number(utilities);
    const expenses_operatingexpenses =
      Number(expenses_total) - Number(expenses_interest);
    const expenses_noi =
      Number(income_total) - Number(expenses_operatingexpenses);
    const expenses_profitloss = Number(income_total) - Number(expenses_total);
    const expenses_final = expenses_total + expenses_profitloss;
    const expenses_depreciation = Math.round(
      getCalculatedPercentage(3.84) * Number(purchasePriceInfo?.improvement)
    );
    const expenses_taxable_income = expenses_profitloss - expenses_depreciation;

    // Cash Flow Calculation
    const inflow = income_total;
    const inflow_caprate =
      (income_total / Number(purchasePriceInfo?.purchasePrice)) * 100;

    const outflow =
      Number(monthlyMortgage) * -1 * 12 + expenses_total - expenses_interest;
    const outflow_positive = inflow - outflow;
    const outflow_cashpercentage =
      (outflow_positive /
        (Number(purchasePriceInfo?.purchasePrice) - Number(mortgage))) *
      100;
    const outflow_contribution_to_principle =
      Number(monthlyMortgage) * -1 * 12 - Number(expenses_interest);
    const outflow_caprate =
      (Number(expenses_profitloss) / Number(purchasePriceInfo?.purchasePrice)) *
      100;

    const ratios_caprate =
      (Number(expenses_noi) / Number(purchasePriceValues?.purchasePrice)) * 100;
    const ratios_grossrent =
      Number(purchasePriceValues?.purchasePrice) / Number(income_total);
    const ratios_leverage =
      Number(purchasePriceValues?.purchasePrice) / Number(downPayment);
    const ratios_loanToValueRatio =
      (Number(mortgage) / Number(purchasePriceValues?.purchasePrice)) * 100;
    setPurchasePriceInfo({
      ...purchasePriceInfo,
      mortgage,
      improvement,
      land,
      downPayment,
      interestRate: assumptionsInput?.interestRate,
      interestAmount,
      propertyTaxes,
      maintenance,
      monthlyMortgage,
      income_rental,
      income_total,
      expenses_interest,
      expenses_propertyTax,
      expenses_maintenance,
      expenses_propertyManagement,
      expenses_total,
      expenses_profitloss,
      expenses_final,
      expenses_depreciation,
      expenses_taxable_income,
      expenses_hoa: hoa,
      expenses_insurance: insurance,
      expenses_utilities: utilities,
      expenses_noi,
      expenses_operatingexpenses,
      inflow,
      inflow_caprate,
      outflow,
      outflow_positive,
      outflow_cashpercentage,
      outflow_contribution_to_principle,
      outflow_caprate,
      ratios_caprate,
      ratios_grossrent,
      ratios_leverage,
      ratios_loanToValueRatio,
    });
    if (type === "save") {
      const values = {
        ...purchasePriceInfo,
        mortgage,
        improvement,
        land,
        downPayment,
        interestRate: assumptionsInput?.interestRate,
        interestAmount,
        propertyTaxes,
        maintenance,
        monthlyMortgage,
        income_rental,
        income_total,
        expenses_interest,
        expenses_propertyTax,
        expenses_maintenance,
        expenses_propertyManagement,
        expenses_total,
        expenses_profitloss,
        expenses_final,
        expenses_depreciation,
        expenses_taxable_income,
        expenses_hoa: hoa,
        expenses_insurance: insurance,
        expenses_utilities: utilities,
        expenses_noi,
        expenses_operatingexpenses,
        inflow,
        inflow_caprate,
        outflow,
        outflow_positive,
        outflow_cashpercentage,
        outflow_contribution_to_principle,
        outflow_caprate,
        ratios_caprate,
        ratios_grossrent,
        ratios_leverage,
        ratios_loanToValueRatio,
      };
      handleInvestmentAnalysisSave(values);
    }
  };

  useEffect(() => {
    fetchAssumptions();
  }, []);

  useEffect(() => {
    if (Number(purchasePriceInfo?.purchasePrice) > 0) {
      doInvestmentCalculation();
    }
  }, [
    purchasePriceInfo?.purchasePrice,
    purchasePriceInfo?.monthlyRent,
    purchasePriceInfo?.expenses_hoa,
    purchasePriceInfo?.expenses_insurance,
    purchasePriceInfo?.expenses_utilities,
    purchasePriceInfo?.monthlyRent,
  ]);

  const handleAssumptionsInputChange = (e) => {
    let { name, value } = e.target;

    setAssumptionsInput({
      ...assumptionsInput,
      [name]: value,
    });

    // Save/Update Tab2 Values
    // Save/Update Tab1 Values
  };

  const handleInvestmentInputChange = (e) => {
    const { name, value } = e.target;
    setPurchasePriceInfo({
      ...purchasePriceInfo,
      [name]: value,
    });
  };

  const fetchUserInvestmentAnalysis = async () => {
    setIsLoading(true);
    const payload = {
      userId: sessionValues?.id,
    };
    const record = await getInvestmentAnalysis(payload);
    if (!!record) {
      setIsLoading(false);
      setPurchasePriceInfo(record);
      // Insert Assumptions for user
    } else {
      setIsLoading(false);
    }
  };

  const fetchAssumptions = async () => {
    setIsLoading(true);
    const payload = {
      userId: sessionValues?.id,
    };
    const record = await getUserAssumptions(payload);
    if (!!record) {
      setIsLoading(false);
      setAssumptionsInput(record);

      fetchUserInvestmentAnalysis();
      // Insert Assumptions for user
    } else {
      handleAssumptionsSave("add");
      setIsLoading(false);
    }
  };

  const handleAssumptionsSave = async (insertType = null) => {
    // sessionStorage.setItem("userSettings",JSON.stringify(settings));
    setIsLoading(true);
    let payload = {};
    let record = null;
    if (insertType === "add") {
      payload = { ...ASSUMPTION_CONSTANTS, userId: sessionValues?.id };
      record = await insertAssumptions(payload);
    } else {
      payload = { ...assumptionsInput, userId: sessionValues?.id };
      record = await updateAssumptions(payload);
    }
    if (!!record?.data) {
      // setIsSave((prev) => ({ ...prev, isSave: true }));
      // const settingRecord = convertSettingToNumber(record?.data);
      // if(clientId && clientId !== "null") {
      //     setSessionClientSettings(settingRecord);
      // }
      const logsPayload = {
        description: "Stored Assumptions",
      };
      insertLogs(logsPayload);

      setAssumptionsInput(record?.data);

      // setSettingSave((prev) => ({ ...prev, settingSave: true }));
      // setTimeout(()=>{
      //     handleMonthlyExpenses();
      // },2000)
      if (!insertType) {
        showSuccess("Assumptions updated successfully");
      }
      setIsLoading(false);
      doInvestmentCalculation("save");
    } else {
      showError(record?.message);
      setIsLoading(false);
    }
    // If save triggered, update all the calculations w.r.t new setting values
  };

  const handleInvestmentAnalysisSave = async (values = null) => {
    setIsLoading(true);
    let payload = {};
    let record = null;
    let insertType = null;
    if (!purchasePriceInfo?.userId) {
      insertType = "add";
      payload = { ...purchasePriceInfo, userId: sessionValues?.id };
      record = await insertInvestmentAnalysis(payload);
    } else {
      if (!values) {
        payload = { ...purchasePriceInfo, userId: sessionValues?.id };
      } else {
        payload = { ...values };
      }
      record = await updateInvestmentAnalysis(payload);
    }
    if (!!record?.data) {
      // setIsSave((prev) => ({ ...prev, isSave: true }));
      // const settingRecord = convertSettingToNumber(record?.data);
      // if(clientId && clientId !== "null") {
      //     setSessionClientSettings(settingRecord);
      // }
      const logsPayload = {
        description: "Stored Investment Analysis",
      };
      insertLogs(logsPayload);

      setPurchasePriceInfo(record?.data);

      if (insertType === "add") {
        showSuccess("Investment Analysis inputs added successfully");
      } else {
        showSuccess("Investment Analysis inputs updated successfully");
      }
      // setSettingSave((prev) => ({ ...prev, settingSave: true }));
      // setTimeout(()=>{
      //     handleMonthlyExpenses();
      // },2000)
      setIsLoading(false);
    } else {
      showError(record?.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="HomePurchaseStep3Form">
      {isLoading && <Loading />}
      <Toast ref={toast} />
      <div className="content">
        {isDesktopOrLaptop && (
          <div className="left">
            <LeftContent />
          </div>
        )}
        <div className="right">
          <div className="card">
            <nav className="navbarcls">
              <div className="container">
                <div className={`nav-elements `}>
                  {!clientId || clientId === "null" ? (
                    <ul style={{ padding: "0.2rem" }}>
                      <li>
                        <NavLink to="/home-purchase/investment/analysis">
                          Investment Analysis
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/home-purchase/investment/ratios">
                          Ratios
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/home-purchase/investment/assumptions">
                          Assumptions
                        </NavLink>
                      </li>
                    </ul>
                  ) : (
                    <ul style={{ padding: "0.2rem" }}>
                      <li>
                        <NavLink
                          to={`/home-purchase/investment/analysis/?clientid=${clientId}`}
                        >
                          Investment Analysis
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/home-purchase/investment/ratios/?clientid=${clientId}`}
                        >
                          Ratios
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`/home-purchase/investment/assumptions/?clientid=${clientId}`}
                        >
                          Assumptions
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </nav>
            <Routes>
              <Route
                path="/analysis"
                element={
                  <InvestmentAnalysis
                    handleInvestmentInputChange={handleInvestmentInputChange}
                    assumptionsInput={assumptionsInput}
                    setPurchasePriceInfo={setPurchasePriceInfo}
                    purchasePriceInfo={purchasePriceInfo}
                    handleInvestmentAnalysisSave={handleInvestmentAnalysisSave}
                    leftContentValues={leftContentValues}
                  />
                }
              />
              <Route
                path="/ratios"
                element={<Ratios ratiosInput={purchasePriceInfo} />}
              />
              <Route
                path="/assumptions"
                element={
                  <Assumptions
                    handleAssumptionsSave={handleAssumptionsSave}
                    handleAssumptionsInputChange={handleAssumptionsInputChange}
                    assumptionsInput={assumptionsInput}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investment;
