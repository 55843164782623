import { Loading } from "app/components";
import { SuccessToastConfig } from "app/utils/ToastConstants";
import { ErrorToastConfig } from "app/utils/ToastConstants";
import { getSessionUserInformation } from "app/utils/utility.service";
import { formatDate } from "app/utils/utility.service";
import { updateUserComponent } from "app/views/component-master/component.service";
import { addUserComponent } from "app/views/component-master/component.service";
import { getComponentList } from "app/views/component-master/component.service";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {  useSearchParams } from "react-router-dom";
const initialState = {
  userId: "",
  property_id:"",
  component:"",
  component_name:"",
  component_type:"",
  life_expectancy:"",
  maintenance_interval:"",
  recall_information:"",
  recall_date:"",
  date_created:"",
  material_description:"",
  warranty:""
};
const SelectComponent = ({
  handleselectComponentModal,
  selectComponentModal,
  values,
  setValues,
  title
}) => {
  const toast = useRef();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [componentData, setComponentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 // const [values, setValues] = useState(initialState);
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get("propertyId");
  const sessionValues = getSessionUserInformation();
   const [componentNames, setComponentNames] = useState([]); // Filtered component names
  const [categories, setCategories] = useState([]);

  const [selectedComponentCategory, setSelectedComponentCategory] =
    useState("");
  const [selectedComponentName, setSelectedComponentName] = useState("");
  const [selectedComponentType, setSelectedComponentType] = useState("");
  const [componentTypes, setComponentTypes] = useState([]);
  const showSuccess = (message) => {
    toast.current.show(SuccessToastConfig(message));
  };
  const showError = (message) => {
    toast.current.show(ErrorToastConfig(message ? message : "Error"));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    // Validate if the input is not empty
  };
  const validateInput = () => {
    if (
      !values.life_expectancy ||
      !values.maintenance_interval ||
      !values.date_created
    ) {
      return false;
    }

    return true;
  };
  const handleDateChange = (e,dateType) => {
    const selectedDate = e.value;

    if (selectedDate) {
      // Adjust the date to UTC
      const utcDate = new Date(Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      ));
      setValues({
        ...values,
        [dateType]: utcDate
      })
    } else {
        setValues({
            ...values,
            [dateType]: ""
          })
    }
  };
  useEffect(() => {
    const fetchComponents = async () => {
      let list = await getComponentList();
      if (list) {
        setIsLoading(false);
        if (list) {
          list.map((li, index) => (li.srNo = index + 1));
        }
        setComponentData(list);

        const uniqueComponentsData = Array.from(
          list
            .reduce((map, item) => map.set(item.component, item), new Map())
            .values()
        );

        setCategories(uniqueComponentsData);
        let category = "";
        if(title.indexOf("Select") !== -1) {
          category =  uniqueComponentsData[0].component;
        } else {
          category = values.component;
        }

        const filteredNames = list.filter(
          (item) => item.component === category
        );
        const seen = new Set();
        const names = filteredNames.filter((item) => {
          if (seen.has(item.component_name)) {
            return false;
          }
          seen.add(item.component_name);
          return true;
        });
        setComponentNames(names);

        let name = "";
        if(title.indexOf("Select") !== -1) {
          name =  uniqueComponentsData[0].component_name;
        } else {
          name = values.component_name;
        }

        const filteredTypes = list.filter(
          (item) =>
            item.component_name === name
        );

        setComponentTypes(filteredTypes);
        if(title.indexOf("Select") !== -1) {
          setSelectedComponentCategory(uniqueComponentsData[0].component);
          setSelectedComponentName(uniqueComponentsData[0].component_name);
          setSelectedComponentType(filteredTypes[0].component_type);
          setValues({
            ...values,
            life_expectancy: uniqueComponentsData[0].life_expectancy,
            maintenance_interval: uniqueComponentsData[0].maintenance_interval,
            recall_information: uniqueComponentsData[0].recall_information,
            recall_date: uniqueComponentsData[0].recall_date,
            userId:sessionValues.id,
            property_id:propertyId,
            component:uniqueComponentsData[0].component,
            component_name: uniqueComponentsData[0].component_name,
            component_type:filteredTypes[0].component_type
          });
        } else {
          setSelectedComponentCategory(values.component);
          setSelectedComponentName(values.component_name);
          setSelectedComponentType(values.component_type);
          setValues({
            ...values,
            life_expectancy: values.life_expectancy,
            maintenance_interval:values.maintenance_interval,
            recall_information: values.recall_information,
            recall_date: values.recall_date,
            userId:sessionValues.id,
            property_id:propertyId,
            component:values.component,
            component_name: values.component_name,
            component_type:values.component_type
          });
        }
       
      }
    };
    fetchComponents();
  }, [selectComponentModal]);
  const onHide = () => {
    setFormSubmitted(false);
    setValues(initialState);
    handleselectComponentModal(false);
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
   
    if (!validateInput()) {
      return false;
    }
   let details;
   setIsLoading(true);
   let msg = "";
  if(title.indexOf("Select") !== -1) {
    details = await addUserComponent(values);
    msg = "Component selected successfully";
  } else {
    details = await updateUserComponent(values?.id, values);
    msg = "Component information updated successfully";
  }
  if(details.status) {
    showSuccess(msg);
    setTimeout(() => {
      handleselectComponentModal(false);
      setValues(initialState);
    }, 1000);
   
  } else {
    showError(details?.message);
  }
 setIsLoading(false); 

 
  /*  const details = await addUserComponent(values);
    if (details) {
      showSuccess("Component added successfully");
      setIsLoading(false);
     if(details) {
      setSelectedDate(formData.date);
      setTimeout(() => {
        setFormData(initialState);
        setFormSubmitted(false);
        setEditClicked(false);
        fetchInsTaxDetails();
    
    } else {
      showError("Error");
    }*/
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear dependent dropdowns when parent dropdown changes
    if (name === "component") {
      setValues((prev) => ({
        ...prev,
        component_name: "", // Reset component_name when component changes
      }));
    }
    const category = e.value;
    setSelectedComponentCategory(category);
    const filteredNames = componentData.filter(
      (item) => item.component === category
    );

    const seen = new Set();
    const uniqueComponentsData = filteredNames.filter((item) => {
      if (seen.has(item.component_name)) {
        return false;
      }
      seen.add(item.component_name);
      return true;
    });

    setSelectedComponentName(uniqueComponentsData[0].component_name);
    setComponentNames(uniqueComponentsData); // Get unique names
    // Get component types based on selected component name
    const filteredTypes = componentData.filter(
      (item) => item.component_name === uniqueComponentsData[0].component_name
    );
    setComponentTypes(filteredTypes); // Extract component types
    setSelectedComponentType(uniqueComponentsData[0].component_type);

    setValues({
      ...values,
      life_expectancy: uniqueComponentsData[0].life_expectancy,
      maintenance_interval: uniqueComponentsData[0].maintenance_interval,
      recall_information: uniqueComponentsData[0].recall_information,
      recall_date: uniqueComponentsData[0].recall_date,
      component:category,
      component_name:uniqueComponentsData[0].component_name,
      component_type:uniqueComponentsData[0].component_type
    });

  };
  // Handle component name selection
  const handleComponentNameChange = (e) => {
    const name = e.value;
    setSelectedComponentName(name);

    // Get component types based on selected component name
    const filteredTypes = componentData.filter(
      (item) => item.component_name === name
    );
    setComponentTypes(filteredTypes); // Extract component types
    setSelectedComponentType(filteredTypes[0].component_type);

    setValues({
      ...values,
      life_expectancy: filteredTypes[0].life_expectancy,
      maintenance_interval: filteredTypes[0].maintenance_interval,
      recall_date: filteredTypes[0].recall_date,
      recall_information: filteredTypes[0].recall_information,
      component_name:name,
      component_type:filteredTypes[0].component_type
    });
  };

  const handleTypeChange = (e) => {
    const type = e.value;
    setSelectedComponentType(type);

    // Get component types based on selected component name
    const filteredTypes = componentData.filter(
      (item) => item.component_type === type
    );

    setValues({
      ...values,
      life_expectancy: filteredTypes[0].life_expectancy,
      maintenance_interval: filteredTypes[0].maintenance_interval,
      recall_information: filteredTypes[0].recall_information,
      recall_date: filteredTypes[0].recall_date,
      component_type:type
    });
  };

  const renderFooter = () => {
    return (
      <div>
        <div className="grid">
          <div className="col-3"></div>
          <div
            className={`${
              isDesktopOrLaptop ? "col-7" : "col-12 text-align-center"
            }`}
          >
            <Button
              label="Cancel"
              className="p-button-raised p-button-lg"
              onClick={() => onHide()}
            />
            <Button
              type="submit"
              label="Save"
              className={`p-button-raised p-button-warning p-button-lg  ${
                isDesktopOrLaptop ? "" : "ml-4"
              }`}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <Dialog
      header={title}
      visible={selectComponentModal}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "35vw" }}
      footer={renderFooter()}
      className="AnnualExpenseModal"
      position="top"
      closeOnEscape={false}
      draggable={false}
    >
      {isLoading && <Loading />}
      <Toast ref={toast} className="ToastMessage" />
      <div className="center-align">
        <div className="AddPropertyForm">
          <div className={`mt-4 ${isDesktopOrLaptop ? "text-right" : ""}`}>
            <div className="grid">
              {/* Address 1 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Component Category</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <Dropdown
                        className="vw-dropdown zipcode-dropdown"
                        value={selectedComponentCategory}
                        onChange={handleChange}
                        options={categories}
                        name="component"
                        required={true}
                        optionLabel="component"
                        optionValue="component"
                        style={{
                          border: "1px solid #ced4da", // Customize the border color and width
                          borderRadius: "3px", // Optional: round the corners
                          padding: "5px", // Optional: add padding for spacing
                        }}
                        // placeholder="Select a Country"
                      />
                    </span>
                  </div>
                </div>
              </div>
              {/* Address 2 */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Component Name</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <Dropdown
                        className="vw-dropdown zipcode-dropdown"
                        value={selectedComponentName}
                        options={componentNames}
                        required={true}
                        name="component_name"
                        onChange={handleComponentNameChange}
                        optionLabel="component_name"
                        optionValue="component_name"
                        style={{
                          border: "1px solid #ced4da", // Customize the border color and width
                          borderRadius: "3px", // Optional: round the corners
                          padding: "5px", // Optional: add padding for spacing
                        }}
                        // placeholder="Select a Country"
                      />
                    </span>
                  </div>
                </div>
              </div>
              {/* City */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Type</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <Dropdown
                        className="vw-dropdown zipcode-dropdown"
                        value={selectedComponentType}
                        options={componentTypes}
                        required={true}
                        name="component_type"
                        onChange={handleTypeChange}
                        optionLabel="component_type"
                        optionValue="component_type"
                        style={{
                          border: "1px solid #ced4da", // Customize the border color and width
                          borderRadius: "3px", // Optional: round the corners
                          padding: "5px", // Optional: add padding for spacing
                        }}
                        // placeholder="Select a Country"
                      />
                    </span>
                  </div>
                </div>
              </div>

              {/* State */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Life Expectancy</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      disabled={true}
                      name="life_expectancy"
                      value={values?.life_expectancy}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="text-align-left">
                    {formSubmitted && !values?.life_expectancy && (
                      <small className="p-error">
                        Please enter life expectancy
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* Country */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Maintenance Interval</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <InputText
                        className="vw-inputtext-with-border"
                        autoComplete="off"
                        disabled={true}
                        required={true}
                        name="maintenance_interval"
                        value={values?.maintenance_interval}
                        onChange={handleInputChange}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Date Created </label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <Calendar
                      id="buttondisplay"
                      onChange={(e) => handleDateChange(e,"date_created")
                      }
                      value={values?.date_created?new Date(formatDate(values?.date_created)):""}
                      showIcon
                       placeholder="mm/dd/yyyy"
                      dateFormat="mm/dd/yy"
                    />
                  </div>

                  <div className="text-align-left">
                    {formSubmitted && !values?.date_created && (
                      <small className="p-error">
                        Please enter date created
                      </small>
                    )}
                  </div>
                </div>
              </div>
              {/* ZIP Code */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Material description</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="material_description"
                      value={values?.material_description}
                      onChange={handleInputChange}
                    />
                  </div>
                  
                </div>
              </div>

              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Warranty Valid Until</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <Calendar
                      id="buttondisplay"
                      onChange={(e) => handleDateChange(e,"warranty")}
                      value={values.warranty?new Date(formatDate(values?.warranty)):""}
                      showIcon
                      dateFormat="mm/dd/yy"
                      placeholder="mm/dd/yyyy"
                    />
                  </div>
                </div>
              </div>

              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Recall Information</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <InputText
                      className="vw-inputtext-with-border"
                      autoComplete="off"
                      required={true}
                      name="recall_information"
                      value={values?.recall_information}
                      onChange={handleInputChange}
                    />
                  </div>
                 
                </div>
              </div>

              {/* ZIP Code */}
              <div className={`${isDesktopOrLaptop ? "col-3" : "col-11"}`}>
                <label className="mt-3">Recall Date</label>
              </div>
              <div className={`${isDesktopOrLaptop ? "col-7" : "col-11"}`}>
                <div className="field">
                  <div className="p-inputgroup">
                    <Calendar
                      id="buttondisplay"
                      onChange={(e) => handleDateChange(e,"recall_date")}
                      value={values.recall_date?new Date(formatDate(values?.recall_date)):""}
                      showIcon
                      disabled={true}
                      dateFormat="mm/dd/yy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default SelectComponent;
